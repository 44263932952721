import { GoogleAnalytics } from './ga';

class SearchAnalytics {
    static scope = 'search';

    static search(sourcePage, type, success = true) {
        GoogleAnalytics.engagementPing(
            SearchAnalytics.scope,
            `${type}_${sourcePage}${success === true ? '' : '_error'}`
        );
    }
}

export { SearchAnalytics };
