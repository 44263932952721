export default {
    created() {
        document.addEventListener('keydown', this.escapeHandler);
    },
    unmounted() {
        document.removeEventListener('keydown', this.escapeHandler);
    },
    methods: {
        escapeHandler(e) {
            if (e.key === 'Escape' && this.escapableVisible && this.escapeable) {
                this.close();
            }
        },
    },
};
