<script>
import { NotificationToolTip } from '@/components/shared';
import { copyToClipboard } from '@/utils/agent';
const RESOURCE_PATH = 'how-it-works.';

export default {
    name: 'HowItWorks',
    components: {
        NotificationToolTip,
    },
    data() {
        return {
            rp: RESOURCE_PATH,
            sampleMagnet:
                'magnet:?xt=urn:btih:08ada5a7a6183aae1e09d831df6748d566095a10&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com&ws=https%3A%2F%2Fwebtorrent.io%2Ftorrents%2F&xs=https%3A%2F%2Fwebtorrent.io%2Ftorrents%2Fsintel.torrent',
            showCopyLinkNotification: false,
            showCopyMessageAndLinkNotification: false,
        };
    },
    computed: {},
    methods: {
        async copyLink(e) {
            e.preventDefault();
            await copyToClipboard(this.sampleMagnet);
            this.showCopyMessageAndLinkNotification = false;
            if (!this.showCopyLinkNotification) {
                this.showCopyLinkNotification = true;
                setTimeout(() => (this.showCopyLinkNotification = false), 2000);
            }
        },
    },
};
</script>

<template>
    <section class="how-it-works-container">
        <div class="section-header">{{ $t(rp + 'header') }}</div>
        <div class="how-it-works-cards-section">
            <div class="card-container small-card">
                <div class="content">
                    <div class="number">1</div>
                    <div class="content-text">
                        <div class="header">{{ $t(rp + 'search') }}</div>
                        <div class="subheader">
                            <i18n-t keypath="how-it-works.search-content" tag="div" scope="global">
                                <span>{{ $t('how-it-works.magnet') }}</span>
                                <span>{{ $t('how-it-works.torrent') }}</span>
                            </i18n-t>
                        </div>
                    </div>
                    <div class="side-content">
                        <img class="side-content-icon-search" src="@/assets/how-it-works-search.svg" />
                    </div>
                </div>
            </div>
            <div class="card-container small-card">
                <div class="content">
                    <div class="number">2</div>
                    <div class="content-text">
                        <div class="header">{{ $t(rp + 'add') }}</div>
                        <div class="subheader">
                            <i18n-t keypath="how-it-works.add-content" tag="div" scope="global">
                                <span>{{ $t('how-it-works.magnet') }}</span>
                                <span class="text-nowrap">(magnet:?xt=urn:...)</span>
                                <span>.torrent</span>
                            </i18n-t>
                        </div>
                    </div>
                    <div class="side-content">
                        <img class="side-content-icon" src="@/assets/how-it-works-add.svg" />
                    </div>
                </div>
            </div>
            <div class="card-container large-card">
                <div class="content">
                    <div class="main-content">
                        <div class="number">3</div>
                        <div class="content-text">
                            <div class="header">{{ $t(rp + 'enjoy') }}</div>
                            <div class="subheader">{{ $t(rp + 'stream') }}</div>
                        </div>
                    </div>
                    <div class="side-content">
                        <div class="copy-magnet">
                            <div class="copy-magnet-header">{{ $t(rp + 'give-it-a-try') }}</div>
                            <div class="copy-magnet-subheader">
                                {{ $t(rp + 'copy-magnet') }}
                            </div>
                            <div class="magnet-link-container">
                                <img class="magnet-icon" src="@/assets/magnet.png" alt="magnet-link-icon" />
                                <input class="magnet-link-input" :value="sampleMagnet" readonly />
                                <div class="notification-wrapper" @click="copyLink">
                                    <SvgIcon icon="copy" class="icon copy-icon" :has-fill="true" />
                                    <NotificationToolTip
                                        v-show="showCopyLinkNotification"
                                        :text="$t(rp + 'magnet-copied')"
                                        width="135px"
                                        :adjust-position="[
                                            { position: 'top', amount: '-45px' },
                                            { position: 'right', amount: '0' },
                                        ]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.how-it-works-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 1.5rem;
    margin: 120px auto;
    @media (max-width: 607px) {
        margin: 60px auto;
    }
    .section-header {
        font-weight: 700;
        font-size: 3rem;
        line-height: 101.52%;
        padding-bottom: 31px;

        @media (max-width: 607px) {
            font-size: 2.25rem;
        }
    }
    .how-it-works-cards-section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 24px;
        .card-container {
            display: flex;
            flex-grow: 1;
            align-items: center;
            border-radius: 20px;
            min-height: 178px;
            padding: 25px 30px;
            background-color: $neutral-dark;
            margin-bottom: 24px;
            @media (max-width: 607px) {
                min-height: 148px;
                padding: 15px;
            }
            .content {
                display: flex;
                flex: 1;
                column-gap: 30px;
                align-items: center;
                @media (max-width: 607px) {
                    align-items: flex-start;
                    column-gap: 15px;
                }
                .main-content {
                    display: flex;
                    flex: 1;
                    column-gap: 30px;
                    @media (max-width: 607px) {
                        column-gap: 15px;
                    }
                }
                .content-text {
                    flex: 1;
                    .header {
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 26px;
                        margin-bottom: 15px;
                        word-break: break-word;
                        @media (max-width: 607px) {
                            font-size: 18px;
                            margin-bottom: 8px;
                        }
                    }
                    .subheader {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        span {
                            color: $primary-green-main;
                        }
                        @media (max-width: 607px) {
                            font-size: 14px;
                        }
                        .text-nowrap {
                            white-space: nowrap;
                        }
                    }
                }
                .number {
                    color: $primary-green-main;
                    font-weight: 900;
                    font-size: 80px;
                    line-height: 60px;
                    @media (max-width: 607px) {
                        font-size: 48px;
                        line-height: 44px;
                    }
                }
                .side-content {
                    width: 50%;
                    display: flex;
                    flex-wrap: wrap;
                    margin: auto 0;
                    @media (max-width: 607px) {
                        width: 100%;
                    }
                    .side-content-icon-search {
                        width: 120%;
                    }
                    .side-content-icon {
                        width: 80%;
                    }
                }
            }
            &.small-card {
                .content {
                    min-height: 63px;
                    .side-content {
                        width: 117px;
                        display: flex;
                        justify-content: center;
                        @media (max-width: 607px) {
                            width: 97px;
                        }
                    }
                }
                @media (min-width: 1230px) {
                    flex: 1;
                }
            }
            &.large-card {
                .content {
                    @media (max-width: 607px) {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                    }
                }
            }
        }
        .copy-magnet {
            border: 1px solid $primary-green-main;
            border-radius: 13px;
            padding: 13px;
            width: 100%;
            &-header {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 6px;
            }
            &-subheader {
                font-weight: 400;
                font-size: 0.9rem;
                line-height: 20px;
                margin-bottom: 17px;
            }
            .magnet-link-container {
                background: $neutral-medium-dark;
                display: flex;
                column-gap: 6px;
                align-items: center;
                width: 100%;
                border-radius: 4px;
                padding: 6px;
                .magnet-link-input {
                    flex-grow: 1;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    background: $neutral-medium-dark;
                    color: $neutral-white;
                    border: none;
                    text-overflow: ellipsis;
                }
                .magnet-icon {
                    width: 10.5px;
                }
                .copy-icon {
                    fill: $neutral-white;
                    cursor: pointer;
                }
                .notification-wrapper {
                    position: relative;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
