<template>
    <transition name="slide-fade">
        <div v-if="visible" :class="['content', { [config.type]: config.type }]">
            <span>{{ config.content }}</span>
        </div>
    </transition>
</template>

<script>
import { reactive, toRefs } from 'vue';

export default {
    props: {
        config: {
            type: Object,
            default: () => ({}),
        },
        remove: {
            type: Function,
            default: () => {},
        },
    },
    setup(props) {
        const state = reactive({
            visible: false,
        });

        // open toast
        const onOpen = (config) => {
            setTimeout(() => {
                state.visible = true;
            }, config.delay);
            // remove toast after duration
            if (config.duration !== 0) {
                setTimeout(() => {
                    onClose();
                }, config.duration * 1000);
            }
        };
        onOpen(props.config);

        const onClose = () => {
            state.visible = false;
            setTimeout(() => {
                props.remove();
            }, 200);
        };

        return {
            ...toRefs(state),
        };
    },
};
</script>

<style lang="scss">
.lite-toast {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: zindex(notification);
    transition: all 1s;

    .slide-fade-enter-active {
        transition: all 0.2s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.2s ease;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateY(-20px);
        opacity: 0;
    }

    .content {
        max-width: 1200px;
        padding: 12px 20px;
        border-radius: 4px;
        font-weight: 400;
    }

    .error {
        background-color: $accents-red-light;
        color: $accents-red-main;
    }
    .warn {
        background-color: $accents-yellow-light;
        color: $accents-yellow-main;
    }
}
</style>
