<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import { PlayerAnalytics } from '@/services/analytics';
import { FS_EVENTS, isIEOrEdge, isSafari, isIE, isMobile, isInput } from '@/utils/agent';
import { formatHHMMSS } from '@/utils/encoding';
import {
    getFileExtension,
    getInitialChunkIndexes,
    MEDIASOURCE_AUDIO_EXTS,
    AUDIO_EXTS,
    isStreamable,
} from '@/utils/torrent';
import { MTimeRanges } from '@/utils/shims';
import constants from '@/utils/constants';
import { Popover } from '@/components/shared';
import SliderControl from './SliderControl';
import LoadingSpinner from './LoadingSpinner';
import StatusDashboard from './StatusDashboard.vue';
import SubtitleMenu from './SubtitleMenu.vue';

export default {
    name: 'MediaPlayer',
    components: {
        SliderControl,
        Popover,
        LoadingSpinner,
        StatusDashboard,
        SubtitleMenu,
    },
    props: {},
    data() {
        return {
            showStatusDashboard: false,
            showSubtitleMenu: false,
            pageXOffset: 0,
            pageYOffset: 0,
            heightToWidthRatio: 9 / 16, //16:9 ratio

            isIEOrEdge,
            isIE,
            isSafari,
            isMobile,

            player: null,

            userInteractionCleaner: null,
            userInteraction: false,
            debounceUserInteraction: 10, // per second
            debounceWait: 0,

            didFallbackToBlob: false,
            hasPlayed: false,
            initialLoaded: false,
        };
    },
    computed: {
        ...mapGetters('player', [
            'fileIndex',
            'currentFile',
            'currentTorrent',
            'currentSubtitle',
            'subtitles',
            'volume',
            'duration',
            'currentTime',
            'playing',
            'fullscreen',
            'loading',
            'bufferCounter',
            'isAudio',
        ]),
        ...mapGetters('library', ['torrents']),
        renderElementId() {
            if (this.isAudio !== null) {
                return this.isAudio ? 'audio-player' : 'video-player';
            }
            return null;
        },
        displayTime() {
            return formatHHMMSS(this.currentTime);
        },
        displayDuration() {
            return this.duration === null ? '--:--' : formatHHMMSS(this.duration);
        },
        buffered() {
            // this variable is just a means to keep recomputing the buffered cache
            // this.bufferCounter gets incremented on torrent.on('download')
            // therefore the buffered cache will be recomputed with every download event
            this.bufferCounter;
            if (this.player === null) {
                return new MTimeRanges([]);
            }
            return this.player.buffered;
        },
        showToolbars() {
            return !this.playing || this.userInteraction || this.showStatusDashboard || this.showSubtitleMenu;
        },
        filename() {
            let n = 'Loading ...';
            if (this.currentTorrent.name) {
                n = this.currentTorrent.name;
            }
            if (this.currentFile && this.currentFile.name) {
                n = n === 'Loading ...' ? this.currentFile.name : `${n}' - '${this.currentFile.name}`;
            }
            return n;
        },
    },
    watch: {
        fileIndex(newValue) {
            if (newValue !== null) {
                // analytics handlers
                if (this.analytics !== undefined) {
                    this.analytics.submit();
                }
                this.analytics = new PlayerAnalytics();
                this.analytics.setFileType(getFileExtension(this.currentFile.name));
                // player handlers
                if (this.player !== null) {
                    this.removePlayer();
                }
                this.currentTorrent.onReady.then(this.initializePlayer).catch((err) => console.error(err));
            }
        },
        currentSubtitle(newValue) {
            if (newValue !== null) {
                let createTrack = true;
                for (let t of this.player.textTracks) {
                    if (parseInt(t.id) === newValue) {
                        t.mode = 'showing';
                        createTrack = false;
                    } else {
                        t.mode = 'disabled';
                    }
                }
                if (createTrack) {
                    const track = document.createElement('track');
                    track.id = newValue;
                    track.kind = 'subtitles';
                    track.src = this.subtitles.get(newValue);
                    const setShowing = () => {
                        for (let t of this.player.textTracks) {
                            if (parseInt(t.id) === newValue) {
                                t.mode = 'showing';
                            }
                        }
                        this.player.textTracks.removeEventListener('addtrack', setShowing);
                    };
                    this.player.textTracks.addEventListener('addtrack', setShowing);
                    this.player.appendChild(track);
                }
            } else {
                if (this.player !== null) {
                    for (let t of this.player.textTracks) {
                        t.mode = 'disabled';
                    }
                }
            }
        },
    },
    mounted() {
        this.$Logger.debug('lifecycle', { event: 'mounted', component: 'MediaPlayer' });
        // fullscreen handlers
        FS_EVENTS.forEach((e) => document.addEventListener(e, this.fullscreenRequestHandler));
        // keyboard handlers
        this.initializeKeyboardHandlers();

        this.recalculateHeight();
    },
    beforeUnmount() {
        this.$Logger.debug('lifecycle', { event: 'beforeUnmount', component: 'MediaPlayer' });
        // analytics handlers
        if (this.analytics !== undefined) {
            this.analytics.submit();
        }
        // player handlers
        if (this.player !== undefined) {
            this.removePlayer();
        }
        // fullscreen handlers
        FS_EVENTS.forEach((e) => document.removeEventListener(e, this.fullscreenRequestHandler));
        // keyboard handlers
        this.clearKeyboardHandlers();
    },
    methods: {
        ...mapMutations('player', [
            'setDuration',
            'setPlaying',
            'setLoading',
            'setLoadingMetadata',
            'setCurrentTime',
            'setFullscreen',
            'setIsAudio',
        ]),
        ...mapMutations('player', { toggleMutedState: 'toggleMuted', setVolumeState: 'setVolume' }),
        toggleStatusDashboard(state = null) {
            if (state === true || this.showStatusDashboard === false) {
                this.closeAllDashboards();
            }
            this.showStatusDashboard = state === null ? !this.showStatusDashboard : state;
        },
        toggleSubtitleMenu(state = null) {
            if (state === true || this.showSubtitleMenu === false) {
                this.closeAllDashboards();
            }
            this.showSubtitleMenu = state === null ? !this.showSubtitleMenu : state;
        },
        closeAllDashboards() {
            this.showStatusDashboard = false;
            this.showSubtitleMenu = false;
        },
        initializePlayer() {
            this.$Logger.debug('player', { event: 'initializePlayer' });
            if (!isStreamable(getFileExtension(this.currentFile.name))) {
                this.$toast?.error({
                    content: `Selected file "${this.currentFile.name}" is not supported yet! Try selecting a supported file from the dashboard below.`,
                    duration: 5,
                });
                return;
            }

            const [startPiece, endPiece] = getInitialChunkIndexes(this.currentFile);
            this.currentFile._torrent.critical(startPiece, endPiece);
            let ext = getFileExtension(this.currentFile.name);
            this.setIsAudio(MEDIASOURCE_AUDIO_EXTS.includes(ext) || AUDIO_EXTS.includes(ext));
            this.player = document.createElement(this.isAudio ? 'audio' : 'video');
            this.player.id = this.renderElementId;
            this.player.crossorigin = 'use-credentials';
            this.player.preload = 'auto';
            this.player.style.display = 'block';
            this.player.style.width = '100%';
            this.player.style.height = '100%';
            this.player.controls = false;
            this.player.autoplay = false;
            this.player.addEventListener('durationchange', this.onPlayerDurationChange);
            this.player.addEventListener('loadeddata', this.onPlayerLoadedData);
            this.player.addEventListener('loadedmetadata', this.onPlayerLoadedMetadata);
            this.player.addEventListener('error', this.onPlayerError);
            this.player.addEventListener('play', this.onPlayerPlay);
            this.player.addEventListener('playing', this.onPlayerPlaying);
            this.player.addEventListener('timeupdate', this.onPlayerTimeupdate);
            this.player.addEventListener('pause', this.onPlayerPause);
            this.player.addEventListener('ended', this.onPlayerEnded);
            this.player.addEventListener('waiting', this.onPlayerWaiting);
            this.player.addEventListener('seeking', this.onPlayerSeeking);
            this.player.addEventListener('seeked', this.onPlayerSeeked);
            this.$refs.player.appendChild(this.player);
            this.currentFile.renderTo(`#${this.renderElementId}`, {
                maxBlobLength: 200 * 1000 * 1000 * 10, // 2 GB (default is 200 MB)
                controls: false,
                autoplay: false,
            });
        },
        removePlayer() {
            this.$Logger.debug('player', { event: 'removePlayer' });
            if (this.player) {
                this.clearPlayerHandlers();
                this.player.remove();
                this.player.removeAttribute('src');
                this.player.load();
                this.player = null;
            }
        },
        clearPlayerHandlers() {
            this.player.removeEventListener('durationchange', this.onPlayerDurationChange);
            this.player.removeEventListener('loadeddata', this.onPlayerLoadedData);
            this.player.removeEventListener('loadedmetadata', this.onPlayerLoadedMetadata);
            this.player.removeEventListener('error', this.onPlayerError);
            this.player.removeEventListener('play', this.onPlayerPlay);
            this.player.removeEventListener('playing', this.onPlayerPlaying);
            this.player.removeEventListener('timeupdate', this.onPlayerTimeupdate);
            this.player.removeEventListener('pause', this.onPlayerPause);
            this.player.removeEventListener('ended', this.onPlayerEnded);
            this.player.removeEventListener('waiting', this.onPlayerWaiting);
            this.player.removeEventListener('seeking', this.onPlayerSeeking);
            this.player.removeEventListener('seeked', this.onPlayerSeeked);
        },
        playerNotInitlized() {
            this.$toast?.warn({
                content: 'Player not initialized yet!',
                duration: 2,
            });
        },
        togglePlay(source) {
            if (this.player === null) {
                return this.playerNotInitlized();
            }
            const isPlaying = this.playing;
            if (isPlaying) {
                this.player.pause();
            } else {
                this.player.play().catch((err) => console.error(err));
            }
            this.setUserInteraction();
            this.analytics.interactionPing(source, 'toggle_play', isPlaying ? 'pause' : 'play');
        },
        toggleMuted() {
            if (this.player === null) {
                return this.playerNotInitlized();
            }
            this.toggleMutedState();
            this.volume.muted ? (this.player.muted = true) : (this.player.muted = false);
        },
        setVolume(volume) {
            if (this.player === null) {
                return this.playerNotInitlized();
            }
            this.setVolumeState(volume);
            this.player.volume = volume;
            volume === 0 ? (this.player.muted = true) : (this.player.muted = false);
        },
        isFullscreen() {
            if (
                this.player !== null &&
                (document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement ||
                    document.msFullscreenElement ||
                    this.player.webkitDisplayingFullscreen) //IOS
            ) {
                return true;
            }
            return false;
        },
        fullscreenRequestHandler(event) {
            this.setFullscreen(this.isFullscreen());
            if (event.type === 'mozfullscreenchange') {
                this.fullscreen ? window.scrollTo(0, 0) : window.scrollTo(this.pageXOffset, this.pageYOffset);
            }
        },
        requestFullscreen() {
            this.pageYOffset = window.pageYOffset;
            this.pageXOffset = window.pageXOffset;
            if (this.player.requestFullscreen) {
                this.$refs.playerContainer.requestFullscreen({ navigationUI: 'hide' });
            } else if (this.player.mozRequestFullScreen) {
                this.player.mozRequestFullScreen();
            } else if (this.player.webkitRequestFullscreen) {
                this.player.webkitRequestFullscreen();
            } else if (this.player.webkitEnterFullScreen) {
                // for IOS
                this.player.webkitEnterFullScreen();
            } else if (this.player.msRequestFullscreen) {
                this.player.msRequestFullscreen();
            }
        },
        exitFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        },
        toggleFullscreen(source) {
            const isFS = this.isFullscreen();
            if (!isFS) {
                this.requestFullscreen();
            } else {
                this.exitFullscreen();
            }
            this.setUserInteraction();
            this.analytics.interactionPing(source, 'toggle_fullscreen', isFS ? 'exit' : 'enter');
        },
        seekStarted() {
            this.preSeekState = this.playing;
            if (this.playing) {
                this.togglePlay('seek');
            }
        },
        seekFinished() {
            if (this.preSeekState !== this.playing) {
                this.togglePlay('seek');
            }
        },
        clearUserInteraction() {
            this.userInteraction = false;
        },
        setUserInteraction() {
            if (Date.now() >= this.debounceWait) {
                this.userInteraction = true;
                clearTimeout(this.userInteractionCleaner);
                this.userInteractionCleaner = setTimeout(this.clearUserInteraction, 800);
                this.debounceWait = Date.now() + 1000 / this.debounceUserInteraction;
            }
        },
        currentTimeChanged(time) {
            if (time === Infinity) {
                console.error(`Cannot set time to ${time}`);
                return;
            }
            if (this.player === null) {
                return this.playerNotInitlized();
            }
            let newTime = time < 0 ? 0 : this.duration === null ? time : time > this.duration ? this.duration : time;
            if (this.currentTime === newTime) {
                return;
            }
            // after player has accounted for this new currentTime
            // it will fire 'timeupdate' event
            // in which we update the state.player.currentTime
            if (typeof newTime === 'number' && !isNaN(newTime)) {
                if (!this.playing) {
                    this.player.play().finally((_) => {
                        this.player.currentTime = newTime;
                        this.player.pause();
                    });
                } else {
                    this.player.currentTime = newTime;
                }
                this.setUserInteraction();
            }
        },
        currentVolumeChanged(volume) {
            let newVolume = volume < 0 ? 0 : volume > 1 ? 1 : volume;
            this.setVolume(newVolume);
            this.setUserInteraction();
        },
        recalculateHeight() {
            if (!this.isFullscreen() && this.$refs.mediaPlayer) {
                this.$refs.mediaPlayer.style.height = `${
                    this.heightToWidthRatio * this.$refs.mediaPlayer.offsetWidth
                }px`;
            }
        },
        tooltip(text) {
            return {
                content: text,
                container: '#media-player',
            };
        },
        onPlayerPause() {
            this.$Logger.trace('player', { event: 'pause' });
            this.setPlaying(false);
            this.analytics.pause();
        },
        onPlayerEnded() {
            this.$Logger.trace('player', { event: 'ended' });
            this.setPlaying(false);
        },
        onPlayerWaiting() {
            this.$Logger.trace('player', { event: 'waiting' });
            this.setLoading(true);
            this.analytics.waiting();
        },
        onPlayerTimeupdate() {
            this.$Logger.trace('player', { event: 'timeupdate' });
            this.setCurrentTime(this.player.currentTime);
            this.analytics.timeUpdate(this.player.currentTime);
        },
        onPlayerPlaying() {
            this.$Logger.trace('player', { event: 'playing' });
            this.setLoading(false);
            this.analytics.playing();
        },
        onPlayerPlay() {
            this.$Logger.trace('player', { event: 'play' });
            this.setPlaying(true);
            this.hasPlayed = true;
            this.analytics.play();
        },
        onPlayerError() {
            this.$Logger.trace('player', { event: 'error', ...this.player.error });
            this.$toast?.error({
                content: 'Player error while decoding the media!',
                duration: 3,
            });
            this.analytics.error(this.player.error || { code: 'unknown' });
            if (this.player.error) {
                this.analytics.error(this.player.error);
                // https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code
                if (this.player.error.code === 3 || this.player.error.code === 4) {
                    this.didFallbackToBlob = true;
                    console.error(`MediaError code ${this.player.error.code}: ${this.player.error.message}`);
                }
            }
        },
        onPlayerSeeking() {
            this.$Logger.trace('player', { event: 'seeking' });
            this.setLoading(true);
        },
        onPlayerSeeked() {
            this.$Logger.trace('player', { event: 'seeked' });
            this.setLoading(false);
        },
        onPlayerLoadedMetadata() {
            this.$Logger.trace('player', { event: 'loadedmetadata' });
            this.setLoadingMetadata(false);
        },
        onPlayerLoadedData() {
            this.$Logger.trace('player', { event: 'loadeddate' });
            if (this.initialLoaded === false) {
                this.initialLoaded = true;
                this.toggleStatusDashboard(false);
            }
            this.setLoading(false);
            this.analytics.loadedData();
        },
        onPlayerDurationChange(event) {
            this.$Logger.trace('player', { event: 'durationchange', duration: event.target.duration });
            const duration = event.target.duration;
            if (duration !== Infinity) {
                this.setDuration(duration);
                this.analytics.durationChange(duration);
            }
        },
        initializeKeyboardHandlers() {
            this.spaceKeyupHandle = this.$store.subscribe({
                action: 'product/keyup',
                predicate: (e) => e.keyCode === 32 && !isInput(e.target),
                handler: () => this.togglePlay('keyboard'),
            });
            // preventDefault on space click to avoid unexpected scroll
            this.spaceKeydownHandle = this.$store.subscribe({
                action: 'product/keydown',
                predicate: (e) => e.keyCode === 32 && !isInput(e.target),
                handler: (e) => e.preventDefault(),
            });
            this.fKeyHandle = this.$store.subscribe({
                action: 'product/keyup',
                predicate: (e) => e.keyCode === 70 && !isInput(e.target),
                handler: () => this.toggleFullscreen('keyboard'),
            });

            this.rightArrowKeyHandle = this.$store.subscribe({
                action: 'product/keydown',
                predicate: (e) => e.keyCode === 39 && !isInput(e.target),
                handler: _.debounce(
                    () => this.currentTimeChanged(this.player.currentTime + constants.PLAYER_FEED_STEP_SECS),
                    500,
                    { maxWait: 500 }
                ),
            });

            this.leftArrowKeyHandle = this.$store.subscribe({
                action: 'product/keydown',
                predicate: (e) => e.keyCode === 37 && !isInput(e.target),
                handler: _.debounce(
                    () => this.currentTimeChanged(this.player.currentTime - constants.PLAYER_FEED_STEP_SECS),
                    500,
                    { maxWait: 500 }
                ),
            });

            this.resizeHandle = this.$store.subscribe({
                action: 'product/resize',
                handler: () => this.recalculateHeight(),
            });

            this.upArrowKeyHandle = this.$store.subscribe({
                action: 'product/keydown',
                predicate: (e) =>
                    e.keyCode === 38 &&
                    // document.activeElement === this.$refs.mediaPlayer means the player is focused.
                    (this.isFullscreen() || document.activeElement === this.$refs.mediaPlayer) &&
                    !isInput(e.target),
                handler: (e) => {
                    e.preventDefault();
                    this.currentVolumeChanged(this.volume.value + constants.PLAYER_VOLUME_STEP_PERCENTAGE);
                },
            });

            this.downArrowKeyHandle = this.$store.subscribe({
                action: 'product/keydown',
                predicate: (e) =>
                    e.keyCode === 40 &&
                    // document.activeElement === this.$refs.mediaPlayer means the player is focused.
                    (this.isFullscreen() || document.activeElement === this.$refs.mediaPlayer) &&
                    !isInput(e.target),
                handler: (e) => {
                    e.preventDefault();
                    this.currentVolumeChanged(this.volume.value - constants.PLAYER_VOLUME_STEP_PERCENTAGE);
                },
            });
        },
        clearKeyboardHandlers() {
            this.$store.unsubscribe(this.spaceKeyupHandle);
            this.$store.unsubscribe(this.spaceKeydownHandle);
            this.$store.unsubscribe(this.fKeyHandle);
            this.$store.unsubscribe(this.leftArrowKeyHandle);
            this.$store.unsubscribe(this.rightArrowKeyHandle);
            this.$store.unsubscribe(this.resizeHandle);
            this.$store.unsubscribe(this.downArrowKeyHandle);
            this.$store.unsubscribe(this.upArrowKeyHandle);
        },
    },
};
</script>

<template>
    <div
        id="media-player"
        ref="mediaPlayer"
        :class="{ active: showToolbars, fullscreen: fullscreen }"
        tabindex="-1"
        @mousemove="setUserInteraction"
        @click="mediaPlayerClick"
    >
        <div id="media-player-inner-container" ref="playerContainer">
            <StatusDashboard
                v-if="!initialLoaded || showStatusDashboard"
                id="status-dashboard"
                @toggleDashboard="toggleStatusDashboard"
            />
            <div id="video-player-container" ref="player" @mousedown.stop="togglePlay(`controls_screen`)">
                <h4 v-show="fullscreen && showToolbars" class="fullscreen-title">{{ filename }}</h4>
                <div class="play-alert-container">
                    <LoadingSpinner v-show="initialLoaded && loading" />
                    <div
                        v-show="!loading && !playing"
                        class="play-alert"
                        @mousedown.stop="togglePlay(`controls_center`)"
                    >
                        <SvgIcon icon="play-arrow-filled" class="play-alert-icon" :has-fill="true" />
                    </div>
                </div>
            </div>
            <div class="media-play-controls show-media-controls-center">
                <div
                    v-show="showToolbars"
                    id="media-control-bottom-play"
                    v-tooltip="playing ? tooltip($t('Pause')) : tooltip($t('Play'))"
                    class="control mobile-play-pause-container"
                    @click="togglePlay(`controls_center`)"
                >
                    <SvgIcon v-if="!playing" icon="play-arrow" class="mobile-play" :has-fill="true" />
                    <SvgIcon v-else icon="pause" class="mobile-pause" :has-fill="true" />
                </div>
            </div>
            <transition name="media-control-fade">
                <div v-show="showToolbars" id="bottom-media-controls">
                    <div class="media-control-top">
                        <div v-if="duration !== null" id="media-control-seek-bar">
                            <SliderControl
                                ref="sliderControl"
                                :containing-element-id="'bottom-media-controls'"
                                :duration="duration"
                                :current-time="currentTime"
                                :buffered="buffered"
                                :class-options="{
                                    track: 'media-control-track',
                                    progress: 'media-control-progress',
                                    buffered: `media-control-buffer ${isIE && 'isIE'}`,
                                    slider: 'media-control-thumb',
                                    mouseMarker: 'media-mouse-marker',
                                    lastPlayPoint: 'media-last-play-point',
                                }"
                                @currentTimeChanged="currentTimeChanged"
                                @seekStarted="seekStarted"
                                @seekEnded="seekFinished"
                            >
                                <template #tool-tip="sliderControl">
                                    <Popover
                                        class="time-tool-tip-wrapper"
                                        :parent-position="sliderControl.parentPosition"
                                        :offsets="{ top: 22, right: 25 }"
                                        :show="sliderControl.show"
                                        :opacity="sliderControl.opacity"
                                    >
                                        <div class="time-tool-tip">
                                            {{ sliderControl.mouseMarkerDisplayTime }}
                                        </div>
                                    </Popover>
                                </template>
                            </SliderControl>
                        </div>
                    </div>
                    <div class="media-control-bottom">
                        <div class="media-control-left">
                            <div class="media-play-controls show-media-controls-bottom">
                                <div
                                    id="media-control-bottom-play"
                                    v-tooltip="playing ? tooltip($t('Pause')) : tooltip($t('Play'))"
                                    class="control"
                                    :class="{ disabled: false }"
                                    @click="togglePlay(`controls_bottom`)"
                                >
                                    <SvgIcon v-if="!playing" icon="play-arrow" :has-fill="true" />
                                    <SvgIcon v-else icon="pause" :has-fill="true" />
                                </div>
                            </div>
                            <div v-if="!isMobile" class="volume-control-container">
                                <div id="volume-icon" class="control" @click="toggleMuted">
                                    <SvgIcon
                                        v-if="!volume.muted"
                                        v-tooltip="tooltip($t('Mute'))"
                                        icon="volume-up"
                                        :has-fill="true"
                                    />
                                    <SvgIcon v-else icon="volume-off" :has-fill="true" />
                                </div>
                                <div class="volume-control" :class="{ 'shift-up': isIEOrEdge }">
                                    <input
                                        class="vol-slider"
                                        step="0.01"
                                        min="0"
                                        max="1"
                                        type="range"
                                        :value="volume.value"
                                        @input="(e) => setVolume(e.currentTarget.valueAsNumber)"
                                        @change="(e) => setVolume(e.currentTarget.valueAsNumber)"
                                    />
                                    <span
                                        class="volume-progress-track"
                                        :class="{ 'shift-up': isIEOrEdge }"
                                        :style="{ width: `${volume.value * 70}px` }"
                                    />
                                </div>
                            </div>
                            <div class="current-time">{{ displayTime }} | {{ displayDuration }}</div>
                        </div>
                        <div class="media-control-right">
                            <SubtitleMenu :show-menu="showSubtitleMenu" @toggleMenu="toggleSubtitleMenu" />
                            <div
                                id="toggle-status-dashboard"
                                v-tooltip="
                                    showStatusDashboard
                                        ? tooltip(`${$t('player.closeDashboard')}`)
                                        : tooltip(`${$t('player.openDashboard')}`)
                                "
                                class="control"
                                @click="toggleStatusDashboard()"
                            >
                                <SvgIcon icon="info-outline" :has-fill="true" />
                            </div>
                            <div
                                id="toggle-fullscreen"
                                v-tooltip="
                                    fullscreen
                                        ? tooltip(`${$t('Exit fullscreen')} (f)`)
                                        : tooltip(`${$t('Fullscreen')} (f)`)
                                "
                                class="control"
                                @click="toggleFullscreen(`controls_bottom`)"
                            >
                                <SvgIcon v-if="!fullscreen" icon="fullscreen" :has-fill="true" />
                                <SvgIcon v-else icon="fullscreen-exit" :has-fill="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/stylesheets/animations.scss';
@import '@/stylesheets/mixins/_tool-tip-arrows.scss';
#media-player {
    position: relative;
    aspect-ratio: 16 / 9;
    background: black;
    width: 100%;
    &:not(.active) {
        cursor: none;
    }
    &:-webkit-full-screen,
    &:-moz-full-screen,
    &:-ms-fullscreen,
    &:fullscreen {
        width: 100% !important;
        height: 100% !important;
        max-height: 100vh !important;
    }
    &.fullscreen {
        width: 100% !important;
        height: 100% !important;
        max-height: 100vh !important;
    }
}
#toggle-status-dashboard {
    cursor: pointer;
    font-size: 24px;
    fill: white;
    z-index: zindex(toggle-status-dashboard);
}
#media-player-inner-container {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
}
#video-player-container {
    position: relative;
    width: 100%;
    height: 100%;
    .fullscreen-title {
        z-index: zindex(media-player);
        position: absolute;
        left: 48px;
        top: 20px;
        width: 550px;
        line-height: 24px;
        font-weight: 400;
    }
    &.hidden {
        position: relative;
        z-index: 400;
    }
    .media-cover-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: zindex(audio_player_cover_image);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}
#video-player {
    background: #171818;
    width: 100%;
    height: 100%;
    .full-width & {
        min-width: 1100px;
    }
    :-webkit-full-screen & {
        max-height: 100vh;
        height: 100%;
    }
    :-moz-full-screen & {
        max-height: 100vh;
        height: 100%;
    }
    :-ms-fullscreen & {
        max-height: 100vh;
        height: 100%;
    }
    :fullscreen & {
        max-height: 100vh;
        height: 100%;
    }
}
#bottom-media-controls {
    @extend .no-select;

    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    color: $neutral-white;
    fill: $neutral-white;
    background-image: linear-gradient(0deg, rgba(26, 26, 26, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    font-size: 16px;
    z-index: zindex(media-player-bottom-controls);
    .media-control-top {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 100%;
        #media-control-seek-bar {
            display: flex;
            flex: 1;
            width: 100%;
            :deep(.media-control-track) {
                width: 100%;
                background: rgba(255, 255, 255, 0.4);
                position: relative;
                border-radius: 2.5px;
                .media-control-buffer {
                    background: #a1a7ac;
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    border-radius: 2.5px;
                }
                .media-control-buffer.isIE {
                    display: none;
                }
                .media-control-progress {
                    background: $brand-primary-main;
                    width: 100%;
                    height: 4px;
                    border-radius: 2.5px;
                }
                .media-control-thumb {
                    background: $neutral-white;
                    background: -webkit-radial-gradient(white 50%, $neutral-extra-dark);
                    background: -moz-radial-gradient(white 50%, $neutral-extra-dark);
                    background: -o-radial-gradient(white 50%, $neutral-extra-dark);
                    background: radial-gradient(white 50%, $neutral-extra-dark);
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                    //ie fix
                    position: absolute;
                    left: 0;
                    &:active {
                        border: 2px solid $neutral-extra-dark;
                    }
                }
                .media-mouse-marker {
                    height: 0.4rem;
                    width: 2px;
                    background: $neutral-black;
                    display: flex;
                    transition: opacity 0.2s linear;
                    //ie fix
                    position: absolute;
                    left: 0;
                }
                .media-last-play-point {
                    height: 0.4rem;
                    width: 2px;
                    background: $neutral-white;
                    box-shadow: 0 0 0 1px $neutral-black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //ie fix
                    position: absolute;
                    left: 0;
                    &::before {
                        top: calc(-0.5em - 1px);
                        display: block;
                        position: absolute;

                        @include tool-tip-arrow($brand-primary-main, 4px, 180deg);
                    }
                }
                .time-tool-tip-wrapper {
                    position: absolute;
                    //ie fix
                    left: 0;
                    display: flex;
                    pointer-events: none;
                    .time-tool-tip {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        pointer-events: none;
                        background-color: rgba(0, 0, 0, 0.6);
                        width: 50px;
                        padding: 0.4em 1em;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
    .media-control-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 6px;
        width: 100%;
        :deep(.svg-container) {
            font-size: 24px;
        }

        .media-control-left,
        .media-control-right {
            display: flex;
            align-items: center;
            height: 100%;
            :deep(.control) {
                display: flex;
                width: 40px;
                height: 40px;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &.disabled {
                    color: $neutral-dark;
                }
            }
        }

        .media-control-right {
            .previous,
            .next {
                @media (min-width: 607px) {
                    display: none;
                }
            }
        }

        .media-play-controls.show-media-controls-bottom {
            @media (max-width: 607px) {
                display: none;
            }
        }
    }
    .vol-slider {
        width: 70px;
        cursor: pointer;
    }
    .current-time {
        min-width: 110px;
        text-align: start;
        font-size: 14px;
    }
    .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .full-width & {
        min-width: 1100px;
    }
}
.skip-alert {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    opacity: 0;
    height: 80px;
    width: 80px;
    top: 50%;
    margin: -45px 0 0 -45px;
    &.skip-forward-alert {
        left: calc(50% + 160px);
    }
    &.skip-backward-alert {
        left: calc(50% - 160px);
    }
    .skip-alert-icon {
        font-size: 35px;
        color: $neutral-white;
        fill: $neutral-white;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
    }
    .skip-alert-text {
        font-size: 20px;
        color: $neutral-white;
    }
    &.animate {
        animation: fadeOut 0.7s 1;
    }
}

.play-alert-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    width: 110px;
    top: 50%;
    left: 50%;
    margin: -55px 0 0 -55px;
    z-index: zindex(media-player);
    .play-alert {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        border: 3px solid $neutral-white;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 1;
        .play-alert-icon {
            display: flex;
            font-size: 65px;
            color: $neutral-white;
            fill: $neutral-white;
            position: relative;
            margin-left: 4px;
        }
        &.hidden {
            transform: scale(1.2);
            transition: all 0.3s;
            opacity: 0;
        }
        &:not(.hidden) {
            animation: 3s play-inner-pulse infinite ease-out;
        }
        &:not(.hidden)::after {
            animation: 3s play-outer-pulse infinite linear;
            border: 2px solid $neutral-white;
            content: '';
            width: 110px;
            display: block;
            position: absolute;
            height: 110px;
            border-radius: 100%;
            opacity: 0;
            box-sizing: border-box;
            top: 0;
            left: 0;
        }
    }
    @media (max-width: 607px) {
        display: none;
    }
}

.media-play-controls.show-media-controls-center {
    .control {
        width: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        .skip-forward,
        .skip-backward {
            img {
                width: 32px;
            }
        }
        .mobile-play-pause-container {
            margin: 0 14px;
        }
        .mobile-play,
        .mobile-pause {
            :deep(svg) {
                width: 65px;
                font-size: 64px;
            }
        }
    }
    @media (max-width: 607px) {
        position: absolute;
        top: 45%;
        right: 50%;
        transform: translate(50%, -50%);
    }
    @media (min-width: 607px) {
        display: none;
    }
}

.media-play-controls {
    display: flex;
    text-align: center;
    fill: $neutral-white;
}

//TEMP - replace with slider control
.volume-control {
    display: flex;
    width: 70px;
    align-items: center;
    border-radius: 2.5px;
    height: 3px;
    background-color: $neutral-main;
    &:active,
    &:hover {
        .volume-progress-track {
            background-color: $brand-primary-main;
        }
    }
    .volume-progress-track {
        border-radius: 2.5px;
        height: 3px;
        position: absolute;
        background-color: $neutral-light-medium;
    }
    .volume-progress-track.shift-up {
        margin-top: -2px;
    }
    input[type='range'] {
        z-index: zindex(min);
        padding: 5px 0;
        margin: 0;
        background: transparent;
        -webkit-appearance: none;
        &:focus {
            outline: none;
        }
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 4px;
            user-select: none;
            cursor: pointer;
            background: transparent;
        }
        &::-webkit-slider-thumb {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background: transparent;
            &:active,
            &:hover {
                background-color: $neutral-white;
            }

            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -3px;
        }
        &::-moz-range-track {
            width: 100%;
            height: 4px;
            cursor: pointer;
            background: transparent;
        }
        &::-moz-range-thumb {
            border: none;
            background: transparent;
            &:active,
            &:hover {
                background-color: $neutral-white;
            }

            border-radius: 50%;
            width: 10px;
            height: 10px;
            cursor: pointer;
            margin-top: -3px;
        }
        &::-ms-track {
            width: 100%;
            height: 4px;
            cursor: pointer;
            background: transparent;
            border-color: transparent;
            color: transparent;
            border-width: 6px 0;
        }
        &::-ms-fill-lower {
            background: transparent;
        }
        &::-ms-fill-upper {
            background: transparent;
        }
        &::-ms-thumb {
            background: transparent;
            &:active,
            &:hover {
                background-color: $neutral-white;
            }

            border: none;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            cursor: pointer;
        }
        &::-ms-tooltip {
            display: none;
        }
    }
}

.volume-control-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.volume-control.shift-up {
    border-top: 1px solid $neutral-main;
}

@keyframes play-outer-pulse {
    0% {
        transform: scale(1.1);
        opacity: 0.5;
    }
    10% {
        transform: scale(1.7);
        opacity: 0;
    }
    100% {
        transform: scale(1.7);
        opacity: 0;
    }
}

@keyframes play-inner-pulse {
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }
    10% {
        transform: scale(1.1, 1.1);
        opacity: 1;
    }
    20% {
        transform: scale(1, 1);
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}
// transitions
.media-control-fade-enter-active,
.media-control-fade-leave-active {
    transition: opacity 0.5s;
}
.media-control-fade-enter,
.media-control-fade-leave-to {
    opacity: 0;
}
</style>
