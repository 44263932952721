<script>
export default {
    name: 'BtCheckbox',
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: null,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            inputVal: this.modelValue,
        };
    },
    computed: {
        hasRightSlot() {
            return !!this.$slots['right'];
        },
        hasLeftSlot() {
            return !!this.$slots['left'];
        },
    },
    watch: {
        modelValue(newVal) {
            this.inputVal = newVal;
        },
        inputVal(newVal) {
            this.$emit('update:modelValue', newVal);
        },
    },
};
</script>

<template>
    <div class="checkbox-container">
        <div v-if="hasLeftSlot" class="left-section">
            <slot name="left" />
        </div>
        <label class="checkbox-control" :for="id">
            <input :id="id" v-model="inputVal" type="checkbox" :disabled="isDisabled" />
            <div class="checkbox-indicator" :class="{ error: isError, disabled: isDisabled }" />
        </label>
        <div v-if="hasRightSlot" class="right-section">
            <slot name="right" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '~@/stylesheets/colors';
@import '~@/stylesheets/variables';

/// Themable variables
$primary-main: $primary-purple-main !default;

.checkbox-container {
    display: flex;
    align-items: center;
    .checkbox-control {
        display: flex;
        position: relative;
        input[type='checkbox'] {
            position: absolute;
            box-sizing: border-box;
            z-index: -1;
            opacity: 0;
            margin: 0;
            padding: 0;
        }
        input[type='checkbox']:checked {
            ~ .checkbox-indicator {
                background-color: $primary-main;
                border: 2px solid $primary-main;
            }
            ~ .checkbox-indicator:after {
                display: block;
                box-sizing: border-box;
            }
        }
        .checkbox-indicator {
            height: 18px;
            width: 18px;
            background-color: transparent;
            border: 2px solid $neutral-light-medium;
            border-radius: 2px;
            box-sizing: border-box;
            cursor: pointer;
            &:after {
                content: '';
                position: absolute;
                display: none;
                left: 6px;
                top: 2px;
                width: 7px;
                height: 11px;
                border: solid $neutral-white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
            &.error {
                border: 2px solid $accents-red-main;
            }
            &.disabled {
                border: 2px solid $neutral-main;
            }
        }
    }
    .right-section {
        margin-left: 10px;
    }
    .left-section {
        margin-left: 10px;
    }
}
</style>
