<script>
import _ from 'lodash';
import { isIE } from '@/utils/agent';

export default {
    name: 'Popover',
    props: {
        /**
         *
         * @type {Object}
         */
        parentPosition: {
            type: Object,
            default: null,
        },
        /**
         * Whether to show the popover
         */
        show: {
            type: Boolean,
        },
        /**
         * Whether the popover is hideable
         */
        hideable: {
            type: Boolean,
        },
        /**
         * Opacity of the popover
         */
        opacity: {
            type: Number,
            default: null,
        },
        /**
         * Orientation
         */
        orientation: {
            type: String,
            default: null,
        },
        /**
         * Location to anchor dropdown menu i.e. 'top-left'
         * @type {String}
         */
        position: {
            type: String,
            default: null,
        },
        /**
         * Pixels to offset i.e { top: 5, left: 5 }
         * @type {Object}
         */
        offsets: {
            type: Object,
            default() {
                return {};
            },
        },
        /** Use fixed positioning
         * @type {String}
         */
        useFixed: {
            type: Boolean,
            default: false,
        },
        /**
         * Pass custom style as props
         * @type {Object}
         */
        zIndex: {
            type: Number,
            default: 940,
        },
    },
    data() {
        return {
            visible: false,
            popoverWidth: 0,
            popoverHeight: 0,
            ieTranslateStr: isIE ? 'translateY(-50%)' : '',
            style: {},
        };
    },
    computed: {
        computedStyle() {
            var rect;
            var offset = 0;
            var translateX = 0;
            var translateY = this.orientationOffset();

            // If absolute position to place popover
            if (this.position) {
                const verticalOffset = this.offsets.top || -this.offsets.bottom || 0;
                const horizontalOffset = this.offsets.left || -this.offsets.right || 0;

                //eslint-disable-next-line
                this.style.position = 'absolute';
                //eslint-disable-next-line
                this.style.zIndex = this.zIndex;
                //eslint-disable-next-line
                this.style.top = 'unset';
                //eslint-disable-next-line
                this.style.bottom = 'unset';
                //eslint-disable-next-line
                this.style.left = 'unset';
                //eslint-disable-next-line
                this.style.right = 'unset';

                if (this.position === 'left-top') {
                    //eslint-disable-next-line
                    this.style.bottom = `${0 + verticalOffset}px`;
                    //eslint-disable-next-line
                    this.style.right = `calc(100% + ${horizontalOffset}px)`;
                } else if (this.position === 'right-top') {
                    //eslint-disable-next-line
                    this.style.bottom = `${0 + verticalOffset}px`;
                    //eslint-disable-next-line
                    this.style.left = `calc(100% - ${horizontalOffset}px)`;
                } else if (this.position === 'left-bottom') {
                    //eslint-disable-next-line
                    this.style.top = `${0 - verticalOffset}px`;
                    //eslint-disable-next-line
                    this.style.right = `calc(100% + ${horizontalOffset}px)`;
                } else if (this.position === 'right-bottom') {
                    //eslint-disable-next-line
                    this.style.top = `${0 - verticalOffset}px`;
                    //eslint-disable-next-line
                    this.style.left = `calc(100% - ${horizontalOffset}px)`;
                } else if (this.position === 'top-left') {
                    //eslint-disable-next-line
                    this.style.bottom = `calc(100% + ${verticalOffset}px)`;
                    //eslint-disable-next-line
                    this.style.right = `${0 + horizontalOffset}px`;
                } else if (this.position === 'top-right') {
                    //eslint-disable-next-line
                    this.style.bottom = `calc(100% + ${verticalOffset}px)`;
                    //eslint-disable-next-line
                    this.style.left = `${0 - horizontalOffset}px`;
                } else if (this.position === 'bottom-left') {
                    //eslint-disable-next-line
                    this.style.top = `calc(100% - ${verticalOffset}px)`;
                    //eslint-disable-next-line
                    this.style.right = `${0 + horizontalOffset}px`;
                } else if (this.position === 'bottom-right') {
                    //eslint-disable-next-line
                    this.style.top = `calc(100% - ${verticalOffset}px)`;
                    //eslint-disable-next-line
                    this.style.left = `${0 - horizontalOffset}px`;
                } else if (this.position === 'top-middle') {
                    //eslint-disable-next-line
                    this.style.bottom = `calc(100% + ${verticalOffset}px)`;
                    //eslint-disable-next-line
                    this.style.left = `calc(50% - ${this.popoverWidth / 2}px)`;
                } else if (this.position === 'bottom-middle') {
                    //eslint-disable-next-line
                    this.style.top = `calc(100% - ${verticalOffset}px)`;
                    //eslint-disable-next-line
                    this.style.left = `calc(50% - ${this.popoverWidth / 2}px)`;
                }
                if (!this.useFixed) {
                    return this.style;
                }
            }
            if (!this.parentPosition && !this.$parent.$el) {
                return this.style;
            }

            if (this.parentPosition) {
                rect = this.parentPosition;
                if (this.useFixed) {
                    //eslint-disable-next-line
                    this.style.position = 'fixed';
                }
            } else {
                rect = this.$parent.$el.getBoundingClientRect();
            }

            if (rect.top !== undefined || rect.bottom !== undefined) {
                if (rect.top !== undefined) {
                    //eslint-disable-next-line
                    this.style.top = `${rect.top}px`;
                    offset = this.offsets.top || -this.offsets.bottom || 0;
                    translateY += -offset;
                } else {
                    offset = -this.offsets.bottom || this.offsets.top || 0;
                    translateY += -(rect.bottom + offset);
                }
            }
            if (rect.left !== undefined || rect.right !== undefined) {
                if (rect.left !== undefined) {
                    offset = this.offsets.left || -this.offsets.right || 0;
                    translateX = rect.left + offset;
                } else {
                    offset = this.offsets.right || -this.offsets.left || 0;
                    translateX = -(rect.right + offset);
                }
            }

            //eslint-disable-next-line
            this.style.transform = `translate3d(${translateX}px, ${translateY}px, 0px) ${this.ieTranslateStr}`;
            //eslint-disable-next-line
            this.style.opacity = this.opacity;
            return _.extend(this.style);
        },
    },
    watch: {
        show: {
            handler(showVal) {
                this.visible = showVal;
            },
            immediate: true,
        },
    },
    mounted() {
        this.watchVisible = this.$watch(
            'visible',
            function (visibleVal) {
                if (visibleVal) {
                    this.popoverWidth = this.$el.offsetWidth;
                    this.popoverHeight = this.$el.offsetHeight;
                    this.watchVisible = null;
                }
            },
            {
                immediate: true,
            }
        );
    },
    methods: {
        showPopover() {
            this.visible = true;
        },
        hide() {
            if (this.hideable) {
                this.visible = false;
            }
        },
        up() {
            return -this.popoverHeight;
        },
        down() {
            return 0; // default behavior for box layout;
        },
        center() {
            return -this.popoverHeight * 0.5;
        },
        orientationOffset() {
            return this[this.orientation || 'down']();
        },
    },
};
</script>

<template>
    <span v-show="visible" class="popover" :style="computedStyle" @click="hide">
        <slot />
    </span>
</template>
