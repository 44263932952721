<script>
import constants from '@/utils/constants';
export default {
    name: 'LanguageSelector',
    props: {},
    data() {
        return {
            selectedLanguage: this.$i18n.locale,
            languages: constants['SUPPORTED_LANGUAGES'],
        };
    },
    methods: {
        updateLanguage(language) {
            this.$i18n.locale = language;
            localStorage.setItem(constants['SELECTED_LANGUAGE'], language);
        },
    },
};
</script>

<template>
    <div class="language-selector-container">
        <BtSelect
            :options="languages"
            :value-prop="$i18n.locale"
            :data-gtm-event="'engagement_session_language_change'"
            @option-updated="updateLanguage"
        >
            <template #option-selected="optionSelected">
                <div class="option-selected">
                    <img class="language-icon" src="@/assets/language.svg" alt="language" />
                    <div class="text">
                        {{ optionSelected.nativeName }}
                    </div>
                </div>
            </template>
            <template #option-item="option">
                <div class="option" :class="{ default: option.value === $i18n.locale }">
                    <div class="text">
                        {{ option.nativeName }}
                    </div>
                </div>
            </template>
        </BtSelect>
    </div>
</template>

<style lang="scss" scoped>
.language-selector-container {
    width: 210px;
    position: relative;
    .title {
        font-size: 16px;
        line-height: 1.5;
        color: $neutral-light;
    }
    .option-selected {
        display: flex;
        .text {
            color: $neutral-light;
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
        }
        .language-icon {
            height: 20px;
            margin-right: 10px;
        }
    }
    .option {
        display: flex;
        align-items: center;
        height: 40px;
        width: 100%;
        padding: 0px 16px;
        background-color: $neutral-extra-dark;
        color: $neutral-light;
        &:hover {
            background-color: #343448;
        }
        .text {
            text-align: left;
            margin-left: 10px;
        }
    }
    // Override some BtSelect css
    :deep(.dropdown-options-container) {
        background-color: $neutral-extra-dark;
        bottom: 50px;
        padding: 10px 0;
        overflow-y: hidden !important;
    }
    :deep(.dropdown-display) {
        fill: $neutral-white;
        height: 40px;
    }
    :deep(.dropdown-container) {
        .dropdown-display {
            &:hover {
                fill: $neutral-white;
                border: 1px solid $neutral-white;
            }
            &.open {
                fill: $neutral-white;
                border: 1px solid $neutral-white;
            }
        }
    }
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        // background: $neutral-light-medium;
        // border-radius: 20px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: $neutral-medium-dark;
        border-radius: 20px;
    }
}
</style>
