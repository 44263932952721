<script>
import faqJson from '@/../faq.json';

export default {
    name: 'Faq',
    components: {},
    props: {},
    computed: {
        faq() {
            return faqJson[this.$i18n.locale];
        },
    },
    methods: {},
};
</script>

<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class="faq">
        <h1>FAQs</h1>
        <div v-for="(item, i) in faq" :key="`faq-item-${i}`" class="qa">
            <h2 v-html="item.question"></h2>
            <div class="answer-wrap">
                <div class="answer" v-html="item.answer"></div>
            </div>
        </div>
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.faq {
    max-width: 974px;
    width: 100%;
    padding: 0 24px;
    margin: auto;
    min-height: 70vh;

    h1 {
        margin: 0 0 24px 0;
    }

    .answer {
        display: inline-block;

        :deep(a) {
            color: $brand-primary-main;
        }

        &-wrap {
            display: flex;
            align-items: center;

            &:before {
                display: inline-block;
                content: '';
                height: 4px;
                width: 4px;
                border-radius: 50%;
                background-color: $brand-primary-main;
                margin-right: 10px;
                flex-shrink: 0;
                align-self: flex-start;
                top: 22px;
                position: relative;
            }
        }
    }

    .qa {
        margin: 20px 0;
    }
}
</style>
