<script>
const componentName = 'BtButton';
export default {
    name: 'BtButton',
    props: {
        btnType: {
            type: String,
            default: 'primary',
            validator(btnType) {
                const allowedBtnTypes = new Set(['primary', 'secondary', 'special']);
                const errorMessage = `[${componentName}]: Invalid prop: btnType=${btnType}, allowed values: ${JSON.stringify(
                    allowedBtnTypes
                )}`;
                !allowedBtnTypes.has(btnType) && console.error(errorMessage);
                return allowedBtnTypes.has(btnType);
            },
        },
        size: {
            type: String,
            default: 'big',
            validator(size) {
                const allowedSizes = new Set(['big', 'small']);
                const errorMessage = `[${componentName}]: Invalid prop: size=${size}, allowed values: ${JSON.stringify(
                    allowedSizes
                )}`;
                !allowedSizes.has(size) && console.error(errorMessage);
                return allowedSizes.has(size);
            },
        },
    },
};
</script>

<template>
    <button :class="['button', btnType, size]" v-bind="$attrs">
        <slot />
    </button>
</template>

<style lang="scss" scoped>
$primary-main-extra-light: $primary-purple-extra-light !default;
$primary-main: $primary-purple-main !default;
$primary-main-dark: $primary-purple-dark !default;

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: inherit;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid transparent;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 700;

    &:disabled {
        opacity: 0.3;
        cursor: default;
    }
}
.primary {
    background-color: $primary-main;
    color: $neutral-white;
    border-radius: 100px;
    &:hover:not([disabled]) {
        background-color: $primary-main-dark;
    }
}
.secondary {
    background-color: $neutral-white;
    color: $primary-main;
    border: 1px solid $primary-main;
    border-radius: 100px;
    &:hover:not([disabled]) {
        background-color: $primary-main-extra-light;
    }
}
.special {
    background-color: $neutral-white;
    color: $neutral-black;
    border: 1px solid $neutral-black;
    border-radius: 100px;
    &:hover:not([disabled]) {
        color: $neutral-white;
        background-color: $neutral-black;
    }
}
.big {
    padding: 12px 24px;
    min-width: 96px;
    height: 40px;
}
.small {
    padding: 8px 16px;
    min-width: 64px;
    height: 32px;
}
</style>
