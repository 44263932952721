export const LEVELS_STRING = {
    4: 'trace',
    3: 'debug',
    2: 'info',
    1: 'error',
    0: 'critical',
};

export const LOG_LEVELS = {
    TRACE: 4,
    DEBUG: 3,
    INFO: 2,
    ERROR: 1,
    CRITICAL: 0,
};
export class Log {
    constructor({ sessionId, originId, event, time, level, data }) {
        this.sid = sessionId;
        this.oid = originId;
        this.event = event;
        this.time = time;
        this.level = level;
        this.data = data;
    }
}
