import { LEVELS_STRING } from '../types';
import { v4 as uuid } from 'uuid';

export class ConsoleSink {
    constructor(level, id = null) {
        this.id = id || uuid();
        this.level = level;
    }
    delete() {
        return;
    }
    write(log) {
        if (log.level <= this.level) {
            log.level = LEVELS_STRING[log.level];
            console.log(log);
        }
    }
}
