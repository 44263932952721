<script>
const componentName = 'BtSelect';
export default {
    name: componentName,
    props: {
        defaultOption: {
            type: String,
            default: 'Default Option',
        },
        options: {
            type: Object,
            default: function () {
                return {
                    first: {
                        nativeName: 'first value',
                        value: 'first',
                    },
                    second: {
                        nativeName: 'second value',
                        value: 'second',
                    },
                    third: {
                        nativeName: 'third value',
                        value: 'third',
                    },
                };
            },
            validator(options) {
                const requiredKeys = ['nativeName', 'value'];
                let invalidEntry;
                const hasKeys = requiredKeys.every((key) =>
                    Object.values(options).every((option) => {
                        const hasKey = key in option;
                        if (!hasKey) {
                            invalidEntry = option;
                        }
                        return hasKey;
                    })
                );
                const errorMessage = `[${componentName}]: Invalid key/value pair in prop options: ${JSON.stringify(
                    invalidEntry
                )}. Required Keys: ${requiredKeys.join(', ')}`;

                !hasKeys && console.error(errorMessage);
                return hasKeys;
            },
        },
        valueProp: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {
        dropdownNativeName() {
            if (this.options.length === 0) {
                return;
            }
            const selection = this.options[this.valueProp];
            return selection ? selection.nativeName : this.defaultOption;
        },
    },
    mounted() {
        // since dropdownOptionsContainer position is absolute, does not respect width set on dropdownContainer.
        // sets the width of the options container to the width of the entire bt-dropdown, so everything aligns
        this.$refs.dropdownOptionsContainer.style.width = `${this.$refs.dropdownContainer.offsetWidth}px`;
    },
    methods: {
        handleMenuToggleClick() {
            if (this.disabled) {
                return;
            }
            this.open = !this.open;
            this.$emit('dropdown-toggle', this.open);
        },
        close() {
            this.open = false;
            this.$refs.dropdownOptionsContainer.scrollTop = 0;
        },
        updateOption(option) {
            if (option.disabled) {
                return;
            }
            this.handleMenuToggleClick();
            this.$emit('option-updated', option.value);
        },
    },
};
</script>

<template>
    <div ref="dropdownContainer" class="dropdown-container" tabindex="1" :class="{ disabled }" @blur="close">
        <div class="dropdown-display" :class="{ open, disabled }" @click.stop="handleMenuToggleClick">
            <div :id="id">
                <slot name="option-selected" v-bind="options[valueProp]">
                    <div class="default-show-selected">
                        {{ dropdownNativeName }}
                    </div>
                </slot>
            </div>
            <SvgIcon v-if="open" icon="expand-less" class="chevron" :has-fill="true" />
            <SvgIcon v-else icon="expand-more" class="chevron" :has-fill="true" />
        </div>
        <div v-show="open" ref="dropdownOptionsContainer" class="dropdown-options-container">
            <div
                v-for="option in options"
                :key="option.value"
                class="dropdown-option"
                @click.stop="updateOption(option)"
            >
                <slot name="option-item" v-bind="option">
                    <div class="default-option">
                        {{ option.nativeName }}
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '~@/stylesheets/colors';
@import '~@/stylesheets/variables';

.dropdown-container {
    align-items: center;
    cursor: pointer;
    border: none;
    overflow: hidden;
    text-align: center;
    list-style: none;
    outline: none;
    width: 100%;

    &.disabled {
        pointer-events: none;
    }

    .chevron {
        font-size: 20px;
    }

    &.open {
        color: $neutral-white;
    }

    .dropdown-display {
        border-radius: 4px;
        padding: 7px 16px;
        line-height: 14px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $neutral-main;
        color: $neutral-dark;

        .default-show-selected {
            width: 80px;
            text-align: left;
        }

        &.disabled {
            border: 1px solid $neutral-medium;
            color: $neutral-medium;
            fill: $neutral-medium;
        }

        &:hover {
            border: 1px solid $primary-main;
            fill: $primary-main;
        }

        &.open {
            border: 1px solid $primary-main;
            fill: $primary-main;
        }
    }

    .dropdown-options-container {
        margin-top: 10px;
        position: absolute;
        border-radius: 4px;
        z-index: 2;
        box-shadow: 0 0 8px 0 rgba($neutral-black, 0.2);
        max-height: 240px;
        overflow-y: scroll;
        width: 100%;

        .dropdown-option {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: $neutral-white;
            position: relative;
            color: $neutral-dark;
            border-bottom: none;
            font-size: 14px;

            &:hover {
                background-color: $neutral-extra-light;
            }

            .default-option {
                padding: 11px 16px;
                text-align: left;
            }
        }

        .dropdown-option:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .dropdown-option:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}
</style>
