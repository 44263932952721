<script>
import SettingsModal from './SettingsModal';
import SearchControlNav from './SearchControlNav';
import HeaderDropdown from './HeaderDropdown';
import packageInfo from '@/../package.json';
import UAParser from 'ua-parser-js';
import { IDBSink } from '@/services';

const version = packageInfo.version;

export default {
    name: 'Header',
    components: {
        SearchControlNav,
        SettingsModal,
        HeaderDropdown,
    },
    props: {},
    data() {
        return {
            showSettings: false,
            msg: 'as',
        };
    },
    computed: {
        isPlayerPage() {
            return this.$route.name === 'player' || this.$route.name === 'playerV2';
        },
        isHomePage() {
            return this.$route.name === 'home';
        },
        showRightContainer() {
            if (this.isPlayerPage) {
                return 'right-container';
            } else {
                return '';
            }
        },
    },
    created() {
        let parser = new UAParser();
        this.UAMetadata = parser.getResult();
        this.supportPage = 'https://bittorrent.com/help/helpCenterSubmitTicket';
    },
    methods: {
        scrollToView(className) {
            let element = this.$el.parentElement.getElementsByClassName(className)[0];
            if (element === undefined) return;
            element.scrollIntoView({ behavior: 'smooth' });
        },
        async reportAnIssue() {
            window.open(
                new URL(
                    `${this.supportPage}?${new URLSearchParams({
                        product: 'uTorrent Lite',
                        version,
                        browser: this.UAMetadata.browser.name,
                        os: this.UAMetadata.os.name,
                    })}`
                ),
                'blank'
            );
            for (let s of this.$Logger.sinks) {
                if (s instanceof IDBSink) {
                    const b = await s.toBlob();
                    let el = document.createElement('a');
                    el.setAttribute('href', URL.createObjectURL(b));
                    el.setAttribute('target', '_blank');
                    el.setAttribute('download', 'lite-log.txt');
                    el.style.display = 'none';
                    document.body.appendChild(el);
                    el.click();
                    document.body.removeChild(el);
                }
            }
        },
    },
};
</script>

<template>
    <div :class="`header ${isPlayerPage ? 'bg-fill' : ''}`">
        <div class="logo-container side-container">
            <router-link to="/">
                <img
                    alt="logo"
                    class="logo"
                    :class="isPlayerPage ? 'logo-responsive' : ''"
                    src="@/assets/ut-lite-logo.svg"
                />
                <img v-if="isPlayerPage" alt="logo-small" class="logo-sm" src="@/assets/ut-logo-sm.svg" />
            </router-link>
        </div>
        <div v-if="isPlayerPage" class="nav-container">
            <SearchControlNav />
        </div>
        <div class="side-container" :class="showRightContainer">
            <div class="settings-icon header-link">
                <HeaderDropdown :distance="12">
                    <div><SvgIcon icon="help-outline" :has-fill="true" /></div>
                    <template #popper>
                        <ul>
                            <li @click="$router.push('/faq')">
                                {{ $t('Faq') }}
                            </li>
                            <li class="separator"></li>
                            <li @click="reportAnIssue">
                                {{ $t('report-an-issue') }}
                            </li>
                        </ul>
                    </template>
                </HeaderDropdown>
            </div>
            <div class="settings-icon header-link" @click.stop="showSettings = true">
                <SvgIcon icon="settings" :has-fill="true" />
            </div>
        </div>

        <SettingsModal v-if="showSettings" @close="showSettings = false"></SettingsModal>
    </div>
</template>

<style lang="scss" scoped>
.settings-icon {
    font-size: 25px;
    line-height: 0.7;
    fill: #a1a7ac;
    &:hover {
        fill: #fff;
    }
}
.header {
    min-height: 55px;
    display: flex;
    column-gap: 40px;
    padding: 5px 40px;
    justify-content: space-between;
    align-items: center;
    z-index: zindex(header);
    position: relative;
    &.bg-fill {
        background-color: $neutral-dark;
    }
    @media (max-width: 607px) {
        padding: 25px;
        column-gap: 12px;
    }

    .logo-container {
        .logo {
            display: block;
            pointer-events: none;
            width: 175px;
        }
        .logo-responsive {
            @media (max-width: 607px) {
                display: none;
            }
        }
        .logo-sm {
            display: none;
            pointer-events: none;
            @media (max-width: 607px) {
                display: block;
            }
        }
    }

    .nav-container {
        width: 100%;
        min-width: 283px;
        max-width: 625px;
    }

    .side-container {
        position: relative;
        display: inline-flex;
        &.right-container {
            width: 175px;
            justify-content: end;
            @media (max-width: 880px) {
                display: none;
            }
        }

        .header-link {
            text-align: right;
            cursor: pointer;
            color: #a1a7ac;
            margin: auto 0 auto 10px;
            white-space: nowrap;
            @media (max-width: 607px) {
                font-size: 13px;
                margin: auto 0;
            }
        }
        .header-link:hover {
            color: #fff;
        }

        .report-issue-link {
            @media (max-width: 670px) {
                display: none;
            }
        }
    }
}
</style>
