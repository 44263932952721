import { speedtest } from '@/utils';
import { GoogleAnalytics } from './ga';

const NORMALIZED_TIMES = [
    [0, '0s'],
    [1, '1_2s'],
    [2, '2_5s'],
    [5, '5_10s'],
    [10, '10_20s'],
    [20, '20_30s'],
    [30, '31_60s'],
    [60, '1_3min'],
    [180, '3_5min'],
    [300, '5_10min'],
    [600, '10_30min'],
    [1800, '30_60min'],
    [3600, '1h_2h'],
    [7200, '2h_5h'],
    [18000, '5h_plus'],
];
const NORMALIZED_DL_SPEED_ARRAY = [1, 5, 25];
const NORMALIZED_DL_SPEED_STRING_ARRAY = ['0_to_1Mbps', '1_to_5Mbps', '5_to_25Mbps', '25_plus_Mbps'];

const mbpsToNormalizedDlSpeed = (mbps) => {
    let i = 0;
    for (; i < NORMALIZED_DL_SPEED_ARRAY.length; i++) {
        if (mbps > NORMALIZED_DL_SPEED_ARRAY[i]) {
            continue;
        } else {
            break;
        }
    }
    return NORMALIZED_DL_SPEED_STRING_ARRAY[i];
};

const getNormalizedTime = (timeMillis) => {
    const timeSecs = timeMillis / 1000;
    let i = 0;
    for (; i < NORMALIZED_TIMES.length - 1; i++) {
        if (timeSecs < NORMALIZED_TIMES[i + 1][0]) {
            return NORMALIZED_TIMES[i][1];
        }
    }
    return NORMALIZED_TIMES[i][1];
};

class SessionAnalytics {
    static scope = 'session';
    static scope_1 = 's';
    static _totalPlaytime = null;
    static _playerCount = null;
    static _torrentCount = null;
    static isSubmitted = false;

    static ping(eventName, parameters = {}) {
        GoogleAnalytics.sendCustomEvent(`${SessionAnalytics.scope_1}_${eventName}`, parameters);
    }
    // passive analytic instances submit
    // their results at the end of their lifetime.
    // if window gets closed abruptly, session analytics
    // can submit their results `onbeforeunload` as their instance lifecycle
    // will be abruptly terminated.
    static passiveAnalytics = new Map();

    static initialize() {
        GoogleAnalytics.initializeAppParams();
        speedtest()
            .then((mbps) =>
                GoogleAnalytics.healthPing(SessionAnalytics.scope, `download_speed_${mbpsToNormalizedDlSpeed(mbps)}`)
            )
            .catch((err) => console.error(err));
    }
    // register a passive analytics instance
    static register(instance) {
        SessionAnalytics.passiveAnalytics.set(instance, true);
    }

    static unregister(instance) {
        SessionAnalytics.passiveAnalytics.delete(instance);
    }

    static submit() {
        if (this.isSubmitted) {
            return;
        }
        this.isSubmitted = true;

        // submit passive instance scoped analytics before the submititing session analytics
        // as they might be contributing to session analytics aggregates
        for (let i of SessionAnalytics.passiveAnalytics.keys()) {
            i.submit();
        }

        if (this._totalPlaytime !== null) {
            GoogleAnalytics.engagementPing(SessionAnalytics.scope, `watched_${getNormalizedTime(this._totalPlaytime)}`);
        }

        SessionAnalytics.ping('submit', {
            playbackTime: this._totalPlaytime === null ? null : getNormalizedTime(this._totalPlaytime),
            playerCount: this._playerCount === null ? null : this._playerCount,
            torrentCount: this._torrentCount === null ? null : this._torrentCount,
        });
    }

    static get playerCount() {
        return this._playerCount === null ? 0 : this._playerCount;
    }

    static set playerCount(count) {
        this._playerCount = count;
    }

    static get torrentCount() {
        return this._torrentCount === null ? 0 : this._torrentCount;
    }

    static set torrentCount(count) {
        this._torrentCount = count;
    }

    static get totalPlaytime() {
        return this._totalPlaytime === null ? 0 : this._totalPlaytime;
    }

    static set totalPlaytime(playtime) {
        this._totalPlaytime = playtime;
    }

    static engagementPing(source, action, state = null) {
        GoogleAnalytics.engagementPing(
            SessionAnalytics.scope,
            `${source}_${action}${state === null ? '' : `_${state}`}`
        );
    }

    static dragNDrop(success = true) {
        GoogleAnalytics.engagementPing(SessionAnalytics.scope, `drag_n_drop${success ? '' : '_error'}`);
    }

    static browse(success = true) {
        GoogleAnalytics.engagementPing(SessionAnalytics.scope, `file_browse${success ? '' : '_error'}`);
    }
}

export { SessionAnalytics, getNormalizedTime };
