const STORAGE_KEY = 'search-history';

const state = {
    history: JSON.parse(window.localStorage.getItem(STORAGE_KEY) || '[]'),
    showErrorMessage: false,
};

const mutations = {
    showErrorMessage(state, value) {
        state.showErrorMessage = !!value;
    },
    addSearchHistoryEntry(state, value) {
        // Store entries in lower case
        const valueLowerCase = value.toLowerCase();
        let index = state.history.findIndex((entry) => entry === valueLowerCase);
        if (index === -1) {
            state.history.push(valueLowerCase);
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.history));
        }
    },
    // Currently not used, we removed the option to remove a single search history
    // entry in the UI
    removeSearchHistoryEntry(state, value) {
        let index = state.history.findIndex((entry) => entry === value.toLowerCase());
        if (index !== -1) {
            state.history.splice(index, 1);
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.history));
        }
    },
    clearSearchHistory(state) {
        state.history.splice(0, state.history.length);
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.history));
    },
    // Called when history is updated from another tab
    // This is called when local storage value is changed from another location,
    // so we only need to update state here and not local storage
    setHistory(state, history) {
        state.history = history;
    },
};

export default {
    state,
    mutations,
};
