import createInstance from './instance';

/**
 * read config and render toast
 * @param {Object} typeConfig for different toast type
 * @param {Object|String} cfg for user api call
 */
const renderToast = (typeConfig = {}, cfg = '') => {
    const isContent = typeof cfg === 'string';
    const userConfig = isContent ? { content: cfg } : cfg;
    const config = {
        delay: 10, // default, open after {delay}ms
        duration: 3, // default, remove after {duration * 1000}ms
        ...typeConfig,
        ...userConfig,
    };

    return createInstance(config);
};

export default {
    error(config = '') {
        const errorConfig = {
            type: 'error',
        };
        renderToast(errorConfig, config);
    },
    warn(config = '') {
        const warnConfig = {
            type: 'warn',
            duration: 2,
        };
        renderToast(warnConfig, config);
    },
};
