<script>
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
import { SessionAnalytics } from '@/services/analytics';
import { isMobile } from '@/utils/agent';
import { parsedTorrentFailedMessage, parseTorrents } from '@/utils/torrent';

const RESOURCE_PATH = 'drag-drop-field.';

export default {
    name: 'DragAndDropField',
    props: {
        border: {
            type: Boolean,
            default: false,
        },
        showDragDropText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rp: RESOURCE_PATH,
            isMobile,
        };
    },
    methods: {
        addTorrentCallback() {},
        onBrowse(e) {
            e.preventDefault();
            if (this.isProccessing) {
                return;
            }
            const files = e.target.files || e.dataTransfer.files;

            if (files.length !== 1) {
                this.$toast?.error(`Please upload only a single '.torrent' file, ${files.length} were uploaded!`);
                return;
            }
            this.isProccessing = true;
            parseTorrents(files[0])
                .then((magnetLink) => {
                    this.$router
                        .push({
                            path: `/player`,
                            query: {
                                m: btoa(magnetLink),
                            },
                        })
                        .then((failure) => {
                            if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
                                this.$toast.warn('Torrent already loaded!');
                                return;
                            }
                            SessionAnalytics.browse();
                        });
                })
                .catch((err) => {
                    this.$toast?.error(parsedTorrentFailedMessage(err.message));
                    console.error(err);
                    SessionAnalytics.browse(false);
                })
                .finally((_) => {
                    this.isProccessing = false;
                });
        },
    },
};
</script>

<template>
    <div class="add-torrent-tip" :class="{ border: border && !isMobile }">
        <div class="add-torrent-tip-inner" :class="{ border: border && !isMobile }">
            <span>
                <span v-if="!isMobile && showDragDropText" class="drag-n-drop">
                    {{ $t(rp + 'drag-drop') }}
                </span>
                <span>
                    <label for="add-torrent-file-input" class="choose-a-torrent-file">
                        {{ $t(rp + 'upload-file') }}</label
                    >
                    <input
                        id="add-torrent-file-input"
                        type="file"
                        name="file[]"
                        accept=".torrent"
                        class="file-input"
                        multiple=""
                        @change="onBrowse"
                    />
                </span>
            </span>
        </div>
    </div>
</template>

<style scoped lang="scss">
.add-torrent-tip {
    align-items: center;
    padding: 7px 9px;
    text-align: center;

    &.border {
        border-radius: 10px;
        background-color: #343448;
        @media (max-width: 607px) {
            background-color: transparent;
        }
    }
}
.add-torrent-tip-inner {
    align-items: center;
    font-size: 18px;
    text-align: center;

    &.border {
        padding: 36px 0;
        border: 2px dashed $neutral-medium;
        border-radius: 10px;
        @media (max-width: 607px) {
            padding: 0;
            border: none;
        }
    }
}
.choose-a-torrent-file {
    border: 1px solid $brand-primary-main;
    border-radius: 100px;
    text-decoration: none;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.drag-n-drop {
    margin: 0 5px 0 0;
    @media (max-width: 607px) {
        display: none;
    }
}
</style>
