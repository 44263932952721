<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Modal from './Modal';
import { version } from '@/utils/version';
import LanguageSelector from './LanguageSelector';
import { $Logger, IDBSink } from '@/services';
export default {
    name: 'SettingsModal',
    components: {
        Modal,
        LanguageSelector,
    },
    data() {
        return {
            isDownloading: false,
        };
    },
    computed: {
        ...mapGetters('preferences', ['localLoggingEnabled', 'sinks']),
        version() {
            return version;
        },
        idbSinks() {
            return this.sinks.filter((s) => s instanceof IDBSink);
        },
    },
    methods: {
        ...mapMutations('preferences', ['toggleLocalLoggingEnabled']),
        ...mapActions('preferences', ['initializeIDBSink', 'deleteIDBSink', 'removeIDBSinks']),
        async toggleLogging() {
            this.toggleLocalLoggingEnabled();
            if (this.localLoggingEnabled) {
                await this.initializeIDBSink();
            } else {
                await this.removeIDBSinks();
            }
        },
        async downloadRecord(sink) {
            if (this.isDownloading) {
                return;
            }
            this.isDownloading = true;
            const b = await sink.toBlob();
            const el = document.createElement('a');
            el.setAttribute('href', URL.createObjectURL(b));
            el.setAttribute('target', '_blank');
            el.setAttribute('download', 'lite-log.txt');
            el.style.display = 'none';
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            this.isDownloading = false;
        },
        async deleteRecord(sink) {
            await this.deleteIDBSink(sink.id).then(
                (_) => $Logger.sinks.filter((s) => s instanceof IDBSink).length === 0 && this.toggleLogging()
            );
        },
    },
};
</script>

<template>
    <Modal :show="true" close-button-color="white">
        <div id="settings-modal">
            <div class="header">
                <span>{{ $t('Settings') }}</span>
            </div>
            <div class="modal-body">
                <div class="group">
                    <h2 class="group-title">{{ $t('Language') }}</h2>
                    <div class="group-body">
                        <div class="setting-item">
                            <label>{{ $t('Language') }}</label>
                            <LanguageSelector class="language-selector" />
                        </div>
                    </div>
                </div>
                <div class="group">
                    <h2 class="group-title">{{ $t('Diagnostics') }}</h2>
                    <div class="group-body">
                        <div class="setting-item">
                            <label>{{
                                localLoggingEnabled ? $t('Disable Local Logging') : $t('Enable Local Logging')
                            }}</label>
                            <div class="toggle-icon" @click.stop="toggleLogging()">
                                <SvgIcon :icon="localLoggingEnabled ? 'toggle-on' : 'toggle-off'" />
                            </div>
                        </div>
                        <div v-if="localLoggingEnabled">
                            <div v-for="(sink, index) in idbSinks" :key="index" class="setting-item">
                                <label class="setting-label">{{ sink.id }}</label>
                                <div class="setting-action">
                                    <BtButton :disabled="isDownloading" @click="downloadRecord(sink)">{{
                                        $t('Download Record')
                                    }}</BtButton>
                                    <BtButton @click="deleteRecord(sink)">{{ $t('Delete Record') }}</BtButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group">
                    <div class="footer">
                        ©{{ new Date().getFullYear() + ' BitTorrent Limited. ' + $t('Version') + ': ' + version }}
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<style lang="scss" scoped>
#settings-modal {
    min-width: 80vw;
    background-color: #191924;
    padding: 24px;
    border-radius: 4px;
    border: rgba(199, 199, 199, 0.15) 1px solid;
}
.header {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: 600;
    color: $brand-primary-main;
}
.toggle-icon {
    font-size: 30px;
    cursor: pointer;
}
.modal-body {
    .group {
        position: relative;
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid $neutral-main;
        width: 100%;
        display: flex;
        flex-direction: column;
        .group-title {
            font-size: 18px;
            margin-bottom: 14px;
            letter-spacing: 1px;
            color: $neutral-medium;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            color: $brand-primary-dark;
            &.center {
                padding-top: 40px;
                justify-content: center;
            }
            .button.button-icon--primary {
                font-size: 12px;
                margin-left: 10px;
                .bt-icons {
                    color: $neutral-light-medium;
                    &:hover {
                        color: $neutral-black;
                    }
                }
            }
        }
        .group-title-helptext {
            font-size: 16px;
        }
        .group-body {
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            width: 100%;
            .setting-item {
                display: flex;
                width: 100%;
                min-height: 40px;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .setting-label {
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                }
                .setting-action {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    & > * {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .footer {
        margin: 0 auto;
    }
}
</style>
