<script>
import { mapGetters, mapMutations } from 'vuex';
import { MediaPlayer, MediaElement } from '@/components/player';
import { DragAndDropField, FeedbackForm } from '@/components/shared';
import { getBestFile } from '@/utils/torrent';
import { parseRoute } from '@/utils';

export default {
    name: 'Player',
    components: {
        FeedbackForm,
        DragAndDropField,
        MediaPlayer,
        MediaElement,
        // DebugPanel,
    },
    computed: {
        ...mapGetters('player', ['currentTorrent', 'currentFiles', 'fileIndex']),
        ...mapGetters('library', ['torrents']),
    },
    watch: {
        $route(to, _) {
            const { magnetLink, urlFileIndex } = parseRoute(to);
            this.$Logger.debug('lifecycle', { event: 'routeChange', component: 'Player', magnetLink, urlFileIndex });
            return this.$store
                .dispatch('library/addTorrent', magnetLink)
                .then((torrent) => {
                    if (
                        torrent.infoHash === this.currentTorrent.infoHash &&
                        (urlFileIndex === null || this.fileIndex === urlFileIndex)
                    ) {
                        this.$toast?.warn('File already loaded!');
                        // TODO: infoHash could be the same but the route could have new information to be added to the existing torrent
                        // i.e. new trackers, metadata, ...
                        return;
                    }
                    // no torrent
                    if (this.currentTorrent === null) {
                        return Promise.resolve()
                            .then(() => this.resetState())
                            .then(() => this.setCurrentTorrent(torrent))
                            .then(() => torrent.onReady)
                            .then(() => this.setFileIndex(this.loadFile(urlFileIndex)));
                    } else {
                        // replace file
                        if (this.currentTorrent.infoHash === torrent.infoHash) {
                            return Promise.resolve()
                                .then(() => torrent.onReady)
                                .then(() => this.setFileIndex(this.loadFile(urlFileIndex)));
                        }
                        // replace torrent
                        let currentHash = this.currentTorrent.infoHash;
                        this.$store
                            .dispatch('library/removeTorrent', currentHash)
                            .then(() => console.info(`torrent ${currentHash} destroyed!`))
                            .then(() => this.resetState())
                            .then(() => this.setCurrentTorrent(torrent))
                            .then(() => torrent.onReady)
                            .then(() => this.setFileIndex(this.loadFile(urlFileIndex)));
                    }
                })
                .catch((err) => {
                    // TODO: show error redirect back to Home
                    console.error(err);
                });
        },
    },
    beforeUnmount() {
        this.$Logger.debug('lifecycle', { event: 'beforeUnmount', component: 'Player' });
        if (this.currentTorrent !== null) {
            // TODO: when multiple torrents implemented, we can avoid destroying and keeping the torrent in library
            let currentHash = this.currentTorrent.infoHash;
            this.$store
                .dispatch('library/removeTorrent', currentHash)
                .then(() => console.info(`torrent ${currentHash} destroyed!`));
        }
    },
    unmounted() {
        this.$Logger.debug('lifecycle', { event: 'unmounted', component: 'Player' });
        // reset state
        this.resetState();
    },
    created() {
        const { magnetLink, urlFileIndex } = parseRoute(this.$route);
        this.$Logger.debug('lifecycle', { event: 'created', component: 'Player', magnetLink, urlFileIndex });
        return this.$store
            .dispatch('library/addTorrent', magnetLink)
            .then((torrent) => {
                this.setCurrentTorrent(torrent);
                return torrent.onReady;
            })
            .then(() => this.setFileIndex(this.loadFile(urlFileIndex)))
            .catch((err) => {
                // TODO: show error redirect back to Home
                console.error(err);
            });
    },

    methods: {
        ...mapMutations('player', ['setCurrentTorrent', 'setFileIndex', 'resetState']),
        loadFile(fileIndex) {
            if (fileIndex === null) {
                return getBestFile(this.currentFiles);
            }
            if (fileIndex < 0 || fileIndex >= this.currentFiles.length) {
                return getBestFile(this.currentFiles);
            }

            return fileIndex;
        },
    },
};
</script>

<template>
    <div class="player">
        <FeedbackForm />
        <MediaPlayer
            v-if="currentTorrent !== null"
            :key="`${currentTorrent.infoHash}`"
            class="media-player-container"
        />
        <MediaElement v-if="currentTorrent !== null" :key="currentTorrent.infoHash" class="media-element-container" />
        <!-- <DebugPanel /> -->
        <div>
            <DragAndDropField border show-drag-drop-text />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.player {
    position: relative;
    max-width: 974px;
    margin: 30px auto 0 auto;
    margin-bottom: 24px;

    .media-player-container,
    .media-element-container {
        margin-bottom: 50px;
    }

    @media (min-width: 607px) {
        padding: 0 40px;
    }
}

.drag-drop-container {
    margin-top: 40px;
    @media (max-width: 607px) {
        padding: 0 16px;
    }
}
</style>
