var constants = {
    PLAYER_FEED_STEP_SECS: 10,
    PLAYER_VOLUME_STEP_PERCENTAGE: 0.1,
    //----------------------------------------
    // TORRENT DATA CONSTANTS
    //----------------------------------------
    TORRENT_HASH: 0,
    TORRENT_STATUS: 1,
    TORRENT_NAME: 2,
    TORRENT_SIZE: 3,
    TORRENT_PROGRESS: 4,
    TORRENT_DOWNLOADED: 5,
    TORRENT_UPLOADED: 6,
    TORRENT_RATIO: 7,
    TORRENT_UPSPEED: 8,
    TORRENT_DOWNSPEED: 9,
    TORRENT_ETA: 10,
    TORRENT_LABEL: 11,
    TORRENT_PEERS_CONNECTED: 12,
    TORRENT_PEERS_SWARM: 13,
    TORRENT_SEEDS_CONNECTED: 14,
    TORRENT_SEEDS_SWARM: 15,
    TORRENT_AVAILABILITY: 16,
    TORRENT_QUEUE_POSITION: 17,
    TORRENT_REMAINING: 18,
    TORRENT_DOWNLOAD_URL: 19,
    TORRENT_RSS_FEED_URL: 20,
    TORRENT_STATUS_MESSAGE: 21,
    TORRENT_STREAM_ID: 22,
    TORRENT_DATE_ADDED: 23,
    TORRENT_DATE_COMPLETED: 24,
    TORRENT_APP_UPDATE_URL: 25,
    TORRENT_SAVE_PATH: 26,

    //----------------------------------------
    // TORRENT PENDING DATA CONSTANTS
    //----------------------------------------
    TORRENT_PENDING_HASH: 0,
    TORRENT_PENDING_NAME: 1,
    TORRENT_PENDING_PEERS_CONNECTED: 2,
    TORRENT_PENDING_PEERS_KNOWN: 3,
    TORRENT_PENDING_SEEDS_CONNECTED: 4,
    TORRENT_PENDING_SEEDS_KNOWN: 5,

    //----------------------------------------
    // TORRENT STATUS CONSTANTS
    //----------------------------------------
    STATE_STARTED: 1,
    STATE_CHECKING: 2,
    STATE_START_AFTER_CHECK: 4,
    STATE_CHECKED: 8,
    STATE_ERROR: 16,
    STATE_PAUSED: 32,
    STATE_AUTO: 64,
    STATE_LOADED: 128,

    //----------------------------------------
    // FILE DATA CONSTANTS
    //----------------------------------------
    FILE_NAME: 0,
    FILE_SIZE: 1,
    FILE_DOWNLOADED: 2,
    FILE_PRIORITY: 3,
    FILE_FIRST_PIECE: 4,
    FILE_NUM_PIECES: 5,
    FILE_STREAMABLE: 6,
    FILE_ENCODED_RATE: 7,
    FILE_DURATION: 8,
    FILE_WIDTH: 9,
    FILE_HEIGHT: 10,
    FILE_STREAM_ETA: 11,
    FILE_STREAMABILITY: 12,

    //----------------------------------------
    // FILE PRIORITY CONSTANTS,
    //----------------------------------------
    FILEPRIORITY_SKIP: 0,
    FILEPRIORITY_LOW: 1,
    FILEPRIORITY_NORMAL: 2,
    FILEPRIORITY_HIGH: 3,

    //----------------------------------------
    // PEER DATA CONSTANTS,
    //----------------------------------------
    PEER_COUNTRY: 0,
    PEER_IP: 1,
    PEER_REVDNS: 2,
    PEER_UTP: 3,
    PEER_PORT: 4,
    PEER_CLIENT: 5,
    PEER_FLAGS: 6,
    PEER_PROGRESS: 7,
    PEER_DOWNSPEED: 8,
    PEER_UPSPEED: 9,
    PEER_REQS_OUT: 10,
    PEER_REQS_IN: 11,
    PEER_WAITED: 12,
    PEER_UPLOADED: 13,
    PEER_DOWNLOADED: 14,
    PEER_HASHERR: 15,
    PEER_PEERDL: 16,
    PEER_MAXUP: 17,
    PEER_MAXDOWN: 18,
    PEER_QUEUED: 19,
    PEER_INACTIVE: 20,
    PEER_RELEVANCE: 21,

    //----------------------------------------
    // RSS FEED CONSTANTS,
    //----------------------------------------
    RSSFEED_ID: 0,
    RSSFEED_ENABLED: 1,
    RSSFEED_USE_FEED_TITLE: 2,
    RSSFEED_USER_SELECTED: 3,
    RSSFEED_PROGRAMMED: 4,
    RSSFEED_DOWNLOAD_STATE: 5,
    RSSFEED_URL: 6,
    RSSFEED_NEXT_UPDATE: 7,
    RSSFEED_ITEMS: 8,

    //----------------------------------------
    // RSS ITEM CONSTANTS,
    //----------------------------------------
    RSSITEM_NAME: 0,
    RSSITEM_NAME_FULL: 1,
    RSSITEM_URL: 2,
    RSSITEM_QUALITY: 3,
    RSSITEM_CODEC: 4,
    RSSITEM_TIMESTAMP: 5,
    RSSITEM_SEASON: 6,
    RSSITEM_EPISODE: 7,
    RSSITEM_EPISODE_TO: 8,
    RSSITEM_FEED_ID: 9,
    RSSITEM_REPACK: 10,
    RSSITEM_IN_HISTORY: 11,

    //----------------------------------------
    // RSS ITEM CODEC CONSTANTS,
    //----------------------------------------
    RSSITEMCODEC_NONE: 0,
    RSSITEMCODEC_MPEG: 1,
    RSSITEMCODEC_MPEG2: 2,
    RSSITEMCODEC_MPEG4: 3,
    RSSITEMCODEC_REAL: 4,
    RSSITEMCODEC_WMV: 5,
    RSSITEMCODEC_XVID: 6,
    RSSITEMCODEC_DIVX: 7,
    RSSITEMCODEC_X264: 8,
    RSSITEMCODEC_H264: 9,
    RSSITEMCODEC_WMVHD: 10,
    RSSITEMCODEC_VC1: 11,

    RSSITEMCODECMAP: ['?', 'MPEG', 'MPEG-2', 'MPEG-4', 'Real', 'WMV', 'Xvid', 'DivX', 'X264', 'H264', 'WMV-HD', 'VC1'],

    //----------------------------------------
    // RSS ITEM QUALITY CONSTANTS,
    //----------------------------------------
    RSSITEMQUALITY_ALL: -1,
    RSSITEMQUALITY_NONE: 0,
    RSSITEMQUALITY_HDTV: 1,
    RSSITEMQUALITY_TVRIP: 2,
    RSSITEMQUALITY_DVDRIP: 4,
    RSSITEMQUALITY_SVCD: 8,
    RSSITEMQUALITY_DSRIP: 16,
    RSSITEMQUALITY_DVBRIP: 32,
    RSSITEMQUALITY_PDTV: 64,
    RSSITEMQUALITY_HRHDTV: 128,
    RSSITEMQUALITY_HRPDTV: 256,
    RSSITEMQUALITY_DVDR: 512,
    RSSITEMQUALITY_DVDSCR: 1024,
    RSSITEMQUALITY_720P: 2048,
    RSSITEMQUALITY_1080I: 4096,
    RSSITEMQUALITY_1080P: 8192,
    RSSITEMQUALITY_WEBRIP: 16384,
    RSSITEMQUALITY_SATRIP: 32768,

    RSSITEMQUALITYMAP: [
        '?',
        'HDTV',
        'TVRip',
        'DVDRip',
        'SVCD',
        'DSRip',
        'DVBRip',
        'PDTV',
        'HR.HDTV',
        'HR.PDTV',
        'DVDR',
        'DVDScr',
        '720p',
        '1080i',
        '1080p',
        'WebRip',
        'SatRip',
    ],

    //----------------------------------------
    // RSS FILTER CONSTANTS,
    //----------------------------------------
    RSSFILTER_ID: 0,
    RSSFILTER_FLAGS: 1,
    RSSFILTER_NAME: 2,
    RSSFILTER_FILTER: 3,
    RSSFILTER_NOT_FILTER: 4,
    RSSFILTER_DIRECTORY: 5,
    RSSFILTER_FEED: 6,
    RSSFILTER_QUALITY: 7,
    RSSFILTER_LABEL: 8,
    RSSFILTER_POSTPONE_MODE: 9,
    RSSFILTER_LAST_MATCH: 10,
    RSSFILTER_SMART_EP_FILTER: 11,
    RSSFILTER_REPACK_EP_FILTER: 12,
    RSSFILTER_EPISODE_FILTER_STR: 13,
    RSSFILTER_EPISODE_FILTER: 14,
    RSSFILTER_RESOLVING_CANDIDATE: 15,

    //----------------------------------------
    // RSS FILTER FLAG CONSTANTS,
    //----------------------------------------
    RSSFILTERFLAG_ENABLE: 1,
    RSSFILTERFLAG_ORIG_NAME: 2,
    RSSFILTERFLAG_HIGH_PRIORITY: 4,
    RSSFILTERFLAG_SMART_EP_FILTER: 8,
    RSSFILTERFLAG_ADD_STOPPED: 16,

    //----------------------------------------
    // SETTING DATA CONSTANTS,
    //----------------------------------------
    SETTING_NAME: 0,
    SETTING_TYPE: 1,
    SETTING_VALUE: 2,
    SETTING_PARAMS: 3,

    //----------------------------------------
    // SETTING TYPE CONSTANTS,
    //----------------------------------------
    SETTINGTYPE_INTEGER: 0,
    SETTINGTYPE_BOOLEAN: 1,
    SETTINGTYPE_STRING: 2,

    //----------------------------------------
    // SETTING PARAM CONSTANTS,
    //----------------------------------------
    SETTINGPARAM_ACCESS_RO: 'R',
    SETTINGPARAM_ACCESS_RW: 'Y',
    SETTINGPARAM_ACCESS_WO: 'W',

    //----------------------------------------
    // TORRENT DOUBLE-CLICK ACTION CONSTANTS,
    //----------------------------------------
    TOR_DBLCLK_SHOW_PROPS: 0,
    TOR_DBLCLK_START_STOP: 1,
    TOR_DBLCLK_OPEN_FOLDER: 2,
    TOR_DBLCLK_SHOW_DL_BAR: 3,

    //----------------------------------------
    // TORRENT REMOVAL ACTION CONSTANTS,
    //----------------------------------------
    TOR_REMOVE: 0,
    TOR_REMOVE_TORRENT: 1,
    TOR_REMOVE_DATA: 2,
    TOR_REMOVE_DATATORRENT: 3,

    //----------------------------------------
    // BT.TRANSP_DISPOSITION CONSTANTS,
    //----------------------------------------
    TRANSDISP_UTP: 2 | 8,
    TRANSDISP_OUT_TCP: 1,
    TRANSDISP_OUT_UTP: 2,
    TRANSDISP_IN_TCP: 4,
    TRANSDISP_IN_UTP: 8,
    TRANSDISP_UTP_NEW_HEADER: 16,

    //----------------------------------------
    // WEBVUI CONSTANTS,
    //----------------------------------------
    UPDATE_INTERVAL: 1000,
    SETTINGS_UPDATE_INTERVAL: 2000,
    SMA_INTERVAL: 5,
    TWINDOW_LENGTH: 24,
    UPLOAD_HISTORY: 29,
    DOWNLOAD_HISTORY: 30,
    PEERS_HISTORY: 31,
    BAR_GRAPH_MAX_PEERS: 7,
    SESSION_LENGTH: 30,

    //----------------------------------------
    // PEERS GEOLOCATION ,
    //----------------------------------------
    PEER_FLAG_CLASS: 22,
    PEER_LIST: 1,

    //----------------------------------------
    // TORRENT STATUS RANK,
    //----------------------------------------
    STATE_ERROR_RANK: 1,
    STATE_RSS_ITEM_D_RANK: 2, //downloaded previously and in history
    STATE_RSS_ITEM_RANK: 3,
    STATE_PAUSED_RANK: 4,
    STATE_FINISHED_RANK: 5,
    STATE_STOPPED_RANK: 6,
    STATE_QUEUED_SEED_RANK: 7,
    STATE_QUEUED_RANK: 8,
    STATE_SEED_RANK: 9,
    STATE_FORCED_SEED_RANK: 10,
    STATE_SUPER_SEED_RANK: 11,
    STATE_FORCED_SUPER_SEED_RANK: 12,
    STATE_DOWNLOAD_RANK: 13,
    STATE_FORCED_DOWNLOAD_RANK: 14,
    STATE_CHECKING_RANK: 15,
    STATE_ALLOCATING_RANK: 16,
    STATE_QUARANTINED_RANK: 17,

    //----------------------------------------
    // MEDIA TYPE ICONS
    //----------------------------------------
    MEDIA_TYPE_AUDIO: 'volume-up',
    MEDIA_TYPE_VIDEO: 'videocam',
    MEDIA_TYPE_DEFAULT: 'download',

    //----------------------------------------
    // FILE TYPE ICONS
    //----------------------------------------
    FILE_TYPE_AUDIO: 'volume-up',
    FILE_TYPE_VIDEO: 'videocam',
    FILE_TYPE_DEFAULT: 'insert-drive-file',

    //----------------------------------------
    // TORRENT LOCAL STORAGE KEY
    //----------------------------------------
    TORRENT_LOCAL_STORAGE_PREFIX: 't-',

    //----------------------------------------
    // AD CONFIG
    //----------------------------------------
    AD_CONFIG_REFRESH_RATE: 120, //minutes
    AD_CONFIG_REFRESH_RATE_DEBUG: 2,
    AD_CONFIG_CHECK_RATE: 10, //minutes
    AD_CONFIG_CHECK_RATE_DEBUG: 1,

    AD_REQUEST_BATCH_TIME_WINDOW: 5, //minutes

    DISPLAY_AD_REFRESH_RATE: 1.5, //minutes

    VIDEO_IDLE_AD_USER_INACTIVITY_CHECK: 1, //minutes
    VIDEO_IDLE_AD_BLACKOUT_TIME_START: 0, //seconds
    VIDEO_IDLE_AD_BLACKOUT_TIME_END: 0, //seconds
    VIDEO_IDLE_AD_COOLDOWN: 3, //minutes
    VIDEO_PREROLL_AD_COOLDOWN: 5, //minutes

    VIDEO_IDLE_MAX_AD_WAIT_TIME: 30000, //ms
    VIDEO_PREROLL_MAX_AD_WAIT_TIME: 20000, //ms
    VIDEO_MAX_CONTENT_WAIT_TIME: 10000, //ms
    VIDEO_MAX_AD_PLAY_TIME: 50000, //ms
    VIDEO_MAX_CONTENT_PLAY_TIME: 60000, //ms

    //----------------------------------------
    // NOTIFICATION TYPE
    //----------------------------------------
    NOTIFICATION_TYPE_UNKNOWN: 0,
    NOTIFICATION_TYPE_TORRENT_ADDED: 1,
    NOTIFICATION_TYPE_TORRENT_COMPLETED: 2,
    NOTIFICATION_TYPE_UNUSED_7_DAYS: 3,
    NOTIFICATION_TYPE_UNUSED_28_DAYS: 4,
    NOTIFICATION_TYPE_INACTIVE_AFTER_LAUNCH_1_DAY: 5,
    NOTIFICATION_TYPE_NEW_VERSION_AVAILABLE: 6,
    NOTIFICATION_TYPE_FAILURE_INVOKE_BROWSER_FOR_UI: 7,
    NOTIFICATION_TYPE_FAILURE_INVOKE_BROWSER_FOR_URL: 8,
    NOTIFICATION_TYPE_FAILURE_ADDING_TORRENT: 9,
    NOTIFICATION_TYPE_TORRENT_REMOVED: 10,
    NOTIFICATION_TYPE_TORRENT_FILES_DELETED: 11,
    NOTIFICATION_TYPE_FAILURE_DELETING_TORRENT_FILES: 12,
    NOTIFICATION_TYPE_PLAYING_TORRENT_RESUMING_DOWNLOAD: 13,
    NOTIFICATION_TYPE_FAILURE_RESUMING_DOWNLOAD_PLAYING_TORRENT: 14,

    //----------------------------------------
    // SUPPORTED LANGUAGES FOR LOCALIZATION
    //----------------------------------------
    SELECTED_LANGUAGE: 'selectedLanguage',

    'SUPPORTED_LANG_ISO_639-1-MAP_TO-ALPHA-2-CODES': {
        de: 'de',
        'es-la': 'es',
        en: 'gb',
        fr: 'fr',
        ja: 'jp',
        it: 'it',
        ko: 'kr',
        nl: 'nl',
        pl: 'pl',
        pt: 'pt',
        'pt-br': 'br',
        ru: 'ru',
        tr: 'tr',
        'zh-cn': 'cn',
        'zh-tw': 'cn',
    },

    SUPPORTED_LANGUAGES: {
        // de: {
        //     name: 'German',
        //     nativeName: 'Deutsch',
        //     value: 'de',
        //     flagCode: 'de',
        // },
        en: {
            name: 'English',
            nativeName: 'English (US)',
            value: 'en',
            flagCode: 'us',
        },
        // 'es-la': {
        //     name: 'Spanish',
        //     nativeName: 'Español',
        //     value: 'es-la',
        //     flagCode: 'es',
        // },
        // fr: {
        //     name: 'French',
        //     nativeName: 'Français',
        //     value: 'fr',
        //     flagCode: 'fr',
        // },
        // it: {
        //     name: 'Italian',
        //     nativeName: 'Italiano',
        //     value: 'it',
        //     flagCode: 'it',
        // },
        // ja: {
        //     name: 'Japanese',
        //     nativeName: '日本語',
        //     value: 'ja',
        //     flagCode: 'jp',
        // },
        // ko: {
        //     name: 'Korean',
        //     nativeName: '한국어',
        //     value: 'ko',
        //     flagCode: 'kr',
        // },
        // nl: {
        //     name: 'Dutch',
        //     nativeName: 'Nederlands',
        //     value: 'nl',
        //     flagCode: 'nl',
        // },
        // pl: {
        //     name: 'Polish',
        //     nativeName: 'język polski',
        //     value: 'pl',
        //     flagCode: 'pl',
        // },
        // pt: {
        //     name: 'Portuguese',
        //     nativeName: 'Português',
        //     value: 'pt',
        //     flagCode: 'pt',
        // },
        // 'pt-br': {
        //     name: 'Portuguese - Brazilian',
        //     nativeName: 'Português do Brasil',
        //     value: 'pt-br',
        //     flagCode: 'br',
        // },
        ru: {
            name: 'Russian',
            nativeName: 'Русский',
            value: 'ru',
            flagCode: 'ru',
        },
        // tr: {
        //     name: 'Turkish',
        //     nativeName: 'Türkçe',
        //     value: 'tr',
        //     flagCode: 'tr',
        // },
        // 'zh-cn': {
        //     name: 'Chinese - Simplified',
        //     nativeName: '汉语',
        //     value: 'zh-cn',
        //     flagCode: 'cn',
        // },
        // 'zh-tw': {
        //     name: 'Chinese - Traditional',
        //     nativeName: '漢語',
        //     value: 'zh-tw',
        //     flagCode: 'cn',
        // },
    },

    //----------------------------------------
    // ADS PARNTER KEYWORDS
    //----------------------------------------
    ADS_PARTNER_KEYWORDS: [
        'cyberghost',
        'sovrn',
        'pubmatic',
        'taboola',
        'telaria',
        'tremor',
        'improvedigital',
        'freewheel',
        'ggs affiliates',
        'ggs',
        'affiliates',
    ],
    //----------------------------------------
    // EASTER EGG VALUES
    //----------------------------------------
    EASTER_EGG: {
        UTWEB: "    _____                         _     _    _      _     \n    |_   _|                       | |   | |  | |    | |    \n _   _| | ___  _ __ _ __ ___ _ __ | |_  | |  | | ___| |__  \n| | | | |/ _ \\| '__| '__/ _ \\ '_ \\| __| | |/\\| |/ _ \\ '_ \\ \n| |_| | | (_) | |  | | |  __/ | | | |_  \\  /\\  /  __/ |_) |\n \\__,_\\_/\\___/|_|  |_|  \\___|_| |_|\\__|  \\/  \\/ \\___|_.__/ \n                                                           \n                                                           ",
        BTWEB: "______ _ _ _____                         _     _    _      _     \n| ___ (_) |_   _|                       | |   | |  | |    | |    \n| |_/ /_| |_| | ___  _ __ _ __ ___ _ __ | |_  | |  | | ___| |__  \n| ___ \\ | __| |/ _ \\| '__| '__/ _ \\ '_ \\| __| | |/\\| |/ _ \\ '_ \\ \n| |_/ / | |_| | (_) | |  | | |  __/ | | | |_  \\  /\\  /  __/ |_) |\n\\____/|_|\\__\\_/\\___/|_|  |_|  \\___|_| |_|\\__|  \\/  \\/ \\___|_.__/ \n                                                                 \n                                                                 ",
    },
    //----------------------------------------
    //  JWPLAYER VALUES
    //----------------------------------------
    JWPLAYER: {
        PLAYER: 'lT742wxf',
        PLAYER_ADFREE: 'JNR1ro8v',
        PLAYLIST: {
            US: {
                DYNAMIC: 'xyn2B4SS',
                RECOMMENDATIONS: 'PeNWJtFW',
            },
            ROW: {
                DYNAMIC: 'f51wvxNH',
                RECOMMENDATIONS: '1QYnVnHg',
            },
        },
        MAX_NUMBER: 30,
    },
    //----------------------------------------
    //  MSE PLAYER VALUES
    //----------------------------------------
    //         Chrome  Firefox Safari  Edge
    // Video   150MB   100MB   290MB   ?
    // Audio   12MB    15MB    14MB    ?
    MSE_BUFFER_LIMIT_VIDEO: {
        CHROME: 150,
        FIREFOX: 100,
        SAFARI: 290,
        EDGE: 290,
    },
    MSE_BUFFER_LIMIT_AUDIO: {
        CHROME: 12,
        FIREFOX: 15,
        SAFARI: 14,
        EDGE: 14,
    },
    //----------------------------------------
    //  GOOGLE FORMS VALUES
    //----------------------------------------
    GOOGLE_FORMS: {
        CONTACT_US: {
            BASE_URL:
                'https://docs.google.com/forms/d/e/1FAIpQLSeDJQyaJVTgYu2NNKSScgzxtuhX1XlNVkk5qo2cWu8S4eY-9w/viewform?',
            ENTRY: {
                PRODUCT_NAME: 'entry.105210445',
                PRODUCT_VERSION: 'entry.1213122343',
                PRODUCT_BROSWER: 'entry.651418052',
                PRODUCT_OS: 'entry.763901024',
            },
            RESULT: {
                PRODUCT_VERSION_BITTORRENT: 'BitTorrent Web',
                PRODUCT_VERSION_UTORRENT: 'uTorrent Web',
                PRODUCT_OS_WINDOWS: 'Windows',
                PRODUCT_OS_MAC: 'Mac OS',
            },
        },
    },

    //----------------------------------------
    // CREATE TORRENT ERROR MAP
    //----------------------------------------
    CREATE_TORRENT_ERROR: {
        'invalid length of torrent': {
            DISPLAY: 'Cannot create a torrent with no data since the files have no size',
            GA: 'no_size',
        },
        'No files specified': {
            DISPLAY: 'Cannot create a torrent with no files',
            GA: 'no_files',
        },
    },

    //----------------------------------------
    // LICENSE
    //----------------------------------------
    PLUS_ACTIVATED: 'Plus',
    AD_FREE_ACTIVATED: 'Ad-Free',
    GRACE_PERIOD: 'License key expired',
    KEY_EXPIRED: 'License key expired',
    INVALID_KEY: 'Invalid key',
    ACTIVATION_GRACE_EXPIRED: 'Activation grace expired',
    ACTIVATION_INVALID: 'Activation invalid',
    NO_LICENSE_KEY: 'No license key',
    KEY_PRODUCT_MISMATCH: 'Key product mismatch',
    KEY_PLATFORM_MISMATCH: 'Key platform mismatch',

    // License key error product/platform mapping
    utweb: 'μTorrent Web',
    btweb: 'BitTorrent Web',
    win: 'Windows',
    mac: 'Mac',

    //----------------------------------------
    // MEDIA BROWSER SUPPORT
    //----------------------------------------
    // TODO: Update with unsupported types by browser
    MEDIA_TYPE_NOT_SUPPORTED: {
        chrome: ['.mpeg', '.avi', '.wmv', '.aif', '.aac', '.alac'],
        opera: ['.mpeg', '.avi', '.wmv', '.aif'],
        firefox: ['.mpeg', '.avi', '.wmv', '.aif'],
        safari: ['.mpeg', '.avi', '.mkv', '.ogg', '.ogv', '.wmv', '.webm', '.aif', '.aac', '.flac', '.alac'],
        microsoft_edge: ['.mpeg', '.avi', '.wmv', '.aif'],
    },
    NOT_STREAMABLE_MEDIA_TYPES: ['mov', 'ogv', 'aac', 'oga', 'ogg', 'wav', 'flac'],

    //----------------------------------------
    // UTWEB SHARE SOURCE VALUES
    //----------------------------------------
    UTWEB_SHARE_LINK_SOURCES: ['uta', 'bta', 'utw', 'btw', 'ut', 'bt', 'l'],
};

export default constants;
