<script>
export default {
    name: 'Footer',
    props: {},
    data() {
        return {
            gdprComplianceRequired: false,
        };
    },
    computed: {
        showPrefLink() {
            return this.gdprComplianceRequired && window.cmpScriptLoaded;
        },
    },
    created() {
        var self = this;
        window.didomiOnReady = window.didomiOnReady || [];
        window.didomiOnReady.push(function (Didomi) {
            self.gdprComplianceRequired = Didomi.isRegulationApplied('gdpr') || Didomi.isRegulationApplied('ccpa');
        });
        this.executeDidomiCMPStub();
        window.cmpScriptLoaded = true;
    },
    methods: {
        privacyPreferencesClick() {
            Didomi.preferences.show();
        },
        executeDidomiCMPStub() {
            if (window.cmpScriptLoaded) {
                return;
            }
            window.gdprAppliesGlobally = false;
            const tcfScript =
                '(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("23f4a63d-9222-4f37-b358-33214ecb6672")})();';
            const ccpaScript =
                '(function(){function i(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){i(e)},5)}}}function e(n,a,r,e,o){function t(e,t,n){if(typeof n!=="function"){return}if(!window[a]){window[a]=[]}var i=false;if(o){i=o(e,t,n)}if(!i){window[a].push({command:e,parameter:t,callback:n})}}t.stub=true;function i(i){if(!window[n]||window[n].stub!==true){return}if(!i.data){return}var a=typeof i.data==="string";var e;try{e=a?JSON.parse(i.data):i.data}catch(t){return}if(e[r]){var o=e[r];window[n](o.command,o.parameter,function(e,t){var n={};n.postMessageReturn={returnValue:e,success:t,callId:o.callId};i.source.postMessage(a?JSON.stringify(n):n,"*")})}}if(typeof window[n]!=="function"){window[n]=t;if(window.addEventListener){window.addEventListener("message",i,false)}else{window.attachEvent("onmessage",i)}}}e("__uspapi","__uspapiBuffer","__uspapiCall","__uspapiReturn");i("__uspapiLocator")})();';
            const scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.setAttribute('id', 'didomi-cmp-stub');
            scriptTag.appendChild(document.createTextNode(tcfScript));
            scriptTag.appendChild(document.createTextNode(ccpaScript));
            document.head.appendChild(scriptTag);
            window.didomiConfig = {
                regulations: {
                    ccpa: {
                        enabled: true,
                        lspa: false, // Whether your company has signed the LSPA or not (defaults to false)
                    },
                },
                notice: {
                    denyOptions: {
                        cross: true,
                    },
                },
            };
        },
    },
};
</script>

<template>
    <div class="footer noselect">
        <div class="wrapper">
            <div class="links">
                <a href="https://www.bittorrent.com/legal/terms-of-use/" target="_blank" rel="noopenner noreferrer">
                    {{ $t('Terms of Use') }}
                </a>
                <a
                    href="https://www.bittorrent.com/legal/eula/"
                    target="_blank"
                    rel="noopenner noreferrer"
                    class="separator"
                >
                    EULA
                </a>
                <a
                    href="https://www.bittorrent.com/legal/privacy-policy/"
                    target="_blank"
                    rel="noopenner noreferrer"
                    class="separator"
                >
                    {{ $t('Privacy Policy') }}
                </a>
                <span v-if="showPrefLink" class="separator" @click="privacyPreferencesClick">{{
                    $t('Privacy Preferences')
                }}</span>
                <router-link to="/faq" class="separator"> {{ $t('Faq') }} </router-link>
                <a :href="$t('improve-banner.link')" target="_blank" rel="noopenner noreferrer" class="separator">{{
                    $t('improve-banner.footer-text')
                }}</a>
            </div>
        </div>
        <div class="copyright">©{{ new Date().getFullYear() }} BitTorrent Limited</div>
    </div>
</template>

<style lang="scss" scoped>
.footer {
    text-align: center;
    width: 100%;
    color: $neutral-light-medium;
    font-size: 14px;
    margin: 40px 0 0 0;
    padding: 0 40px;
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .links {
        margin-right: 20px;
    }
    .copyright {
        margin: 16px 0;
    }
    @media (min-width: 608px) {
        .separator::before {
            content: '| ';
        }
    }

    @media (max-width: 607px) {
        .separator {
            padding-top: 0.75rem;
        }
        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .links {
            order: 2;
            display: flex;
            flex-direction: column;
            text-align: left;
        }
        .copyright {
            text-align: left;
            margin: 24px 0;
        }
    }
}
</style>
