import { userAgentInfo } from '@/utils/version';

class GoogleAnalytics {
    static customEventPrefix = 'gtm';

    static initializeAppParams() {
        dataLayer.push(userAgentInfo());
    }

    static sendPageView(properties) {
        dataLayer.push(properties);
        GoogleAnalytics.sendEvent('pageview');
    }

    static sendEvent(name, properties = {}) {
        dataLayer.push({
            event: name,
            customEventParams: { ...properties },
        });
    }

    static sendCustomEvent(name, properties = {}) {
        GoogleAnalytics.sendEvent(`${GoogleAnalytics.customEventPrefix}_${name}`, properties);
    }

    static sendCustomEventError(name, properties = {}) {
        GoogleAnalytics.sendEvent(`${GoogleAnalytics.customEventPrefix}_${name}_error`, properties);
    }
    // scopes: player, searchbar, torrent, session
    static engagementPing(scope, name, properties = {}) {
        GoogleAnalytics.sendCustomEvent(`engagement_${scope}_${name}`, properties);
    }

    static healthPing(scope, name, properties = {}) {
        GoogleAnalytics.sendCustomEvent(`health_${scope}_${name}`, properties);
    }
}

export { GoogleAnalytics };
