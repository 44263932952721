import semver from 'semver';
import UAParser from 'ua-parser-js';
import packageInfo from '@/../../package.json';

export const version = packageInfo.version;
export const liteIdentifier = 'LI';
export const semanticVersion = semver.parse(version);

const parser = new UAParser();
export const userAgentInfo = () => {
    let metadata = parser.getResult();
    return {
        appVersion: version,
        browser: `${metadata.browser.name} ${metadata.browser.version}`,
        os: `${metadata.os.name} ${metadata.os.version}`,
        ua: metadata.ua,
    };
};
