<template>
    <div class="notification-tool-tip row" :style="getWidth + getHeight + getAdjustments">
        <div class="flex align-center">
            <SvgIcon icon="check-circle-outline" class="check-circle-outline" :has-fill="true" />
            <span>{{ text }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotificationToolTip',
    props: {
        text: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '40px',
        },
        adjustPosition: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        getWidth() {
            return 'width: ' + this.width + '; ';
        },
        getHeight() {
            return 'height: ' + this.height + '; ';
        },
        getAdjustments() {
            let result = '';
            for (let i = 0; i < this.adjustPosition.length; i++) {
                result += this.adjustPosition[i].position + ': ' + this.adjustPosition[i].amount + '; ';
            }
            return result;
        },
    },
};
</script>

<style lang="scss" scoped>
$color: #52a911;
.notification-tool-tip {
    position: absolute;
    background: #f4fcee;
    color: $neutral-black;
    border: solid 1px $color;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 0 4px;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        column-gap: 4px;
    }
    span {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $neutral-dark;
        letter-spacing: 0.1px;
    }
    .check-circle-outline {
        color: $color;
        fill: $color;
    }
}
</style>
