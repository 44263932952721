<script>
import BannerWave from '@/assets/banner-wave.svg';
const LAST_HELP_BANNER_TIME = 'LAST_HELP_BANNER_TIME';

export default {
    name: 'HelpImproveBanner',
    data() {
        return {
            BannerWave: BannerWave,
            showBanner: false,
        };
    },

    watch: {
        $route() {
            this.setupBanner();
        },
    },
    created() {
        this.setupBanner();
    },
    methods: {
        setupBanner() {
            let isHome = this.$router.currentRoute.name === 'home';

            let pastCutoff = true;

            let dateNumberString = localStorage.getItem(LAST_HELP_BANNER_TIME);
            if (dateNumberString !== null) {
                let lastNum = Number.parseInt(dateNumberString);
                let leftOver = Date.now() - lastNum;
                let cutoff = 1000 * 60 * 60 * 24;

                if (leftOver < cutoff) {
                    pastCutoff = false;
                }
            }

            this.showBanner = isHome && pastCutoff;
        },

        closeBanner() {
            this.showBanner = false;
            localStorage.setItem(LAST_HELP_BANNER_TIME, Date.now().toString());
        },
    },
};
</script>

<template>
    <div v-if="showBanner" class="banner-notification">
        <div class="content">
            <img :src="BannerWave" />
            <span>{{ $t('improve-banner.text') + $t('improve-banner.product') }}</span>
            <a class="text" :href="$t('improve-banner.link')" target="_blank">{{ $t('improve-banner.link-text') }}</a>
        </div>
        <div class="banner-close-btn" @click="closeBanner">
            <SvgIcon icon="close" :has-fill="true" class="close" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.banner-notification {
    display: flex;
    background-color: $primary-green-extra-light;
    width: 100%;
    color: $neutral-dark;
    position: relative;
    padding: 6px 16px;
    align-items: center;
    min-height: 48px;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        flex-wrap: wrap;
        column-gap: 16px;

        .text {
            color: $primary-blue-main;
            text-decoration: underline;
        }
    }

    .banner-close-btn {
        cursor: pointer;
        font-size: 15px;
        fill: $neutral-black;
    }
}
</style>
