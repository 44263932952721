<script>
import _ from 'lodash';
import { isIE } from '@/utils/agent';
import { formatHHMMSS } from '@/utils/encoding';
import SliderThumb from './SliderThumb';

export default {
    name: 'SliderControl',
    components: {
        SliderThumb,
    },
    props: {
        containingElementId: {
            type: String,
            default: '',
        },
        duration: {
            type: Number,
            default: 0,
        },
        currentTime: {
            type: Number,
            default: 0,
        },
        buffered: {
            type: Object,
            default: function () {
                return {};
            },
        },
        classOptions: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            bufferStyles: [],
            xStart: 0,
            yStart: 0,
            width: 0,
            xEnd: 0,
            yEnd: 0,
            height: 0,
            sliderXPosition: 0,
            sliderYPosition: 0,
            currentSliderDiameter: 0,
            defaultSliderDiameter: 10,
            expansionFactor: 1.2,
            controlTrackDomSelector: 'control-track',
            progressBarDomSelector: 'progress-bar-selector',
            bufferedBarDomSelector: 'buffered-bar-selector',
            mouseMarkerSelector: 'mouse-marker',
            lastPlayPointSelector: 'last-play-point-selector',
            sliderDomSelector: 'slider-selector',
            currentSliderTransition: 'all 0.2s linear, opacity 0.1s linear',
            noTransition: 'all 0s linear, opacity 0.1s linear',
            smoothTransition: 'all 0.2s linear, opacity 0.1s linear',
            currentOpacity: 0,
            boundingClientRect: {},
            progressRatio: 0,
            bufferedRatio: 0,
            userIsSeeking: false,
            wrapperStyle: {
                display: 'flex',
                'padding-top': '14px',
                'padding-bottom': '8px',
                width: '100%',
                cursor: 'pointer',
            },
            mouseXPosition: 0,
            lastPlayPoint: 0,
            showLastPlayPoint: false,
            isIE,
            ieTranslate: isIE ? '-50%' : '0px',
        };
    },
    computed: {
        sliderValue() {
            return this.duration * this.progressRatio;
        },
        mouseMarkerValue() {
            return this.duration * (this.mouseMarkerPosition.left / this.width);
        },
        translateX() {
            return -this.currentSliderDiameter * 0.5 || 0;
        },
        sliderStyle() {
            var styles = {
                transform: `translate3d(${this.progressEnd + this.translateX}px, ${this.ieTranslate}, 0px)`,
                'border-radius': '50%',
                position: 'absolute',
                'z-index': 60,
                transition: this.currentSliderTransition,
                opacity: this.currentOpacity,
            };

            if (this.currentSliderDiameter) {
                styles.height = `${this.currentSliderDiameter}px`;
                styles.width = `${this.currentSliderDiameter}px`;
            }

            return styles;
        },
        progressEnd() {
            if (this.duration === 0) {
                return 0;
            }
            return (this.currentTime * this.width) / this.duration;
        },
        progressStyle() {
            return {
                'z-index': 30,
                width: `${this.progressEnd}px`,
            };
        },
        mouseMarkerPosition() {
            return {
                left: this.keepInRange(this.mouseXPosition, 0, this.width),
                bottom: this.sliderYPosition,
            };
        },
        mouseMarkerStyle() {
            var { left } = this.mouseMarkerPosition;
            return {
                transform: `translate3d(${left}px, ${this.ieTranslate}, 0px)`,
                position: 'absolute',
                'z-index': 50,
                opacity: this.currentOpacity,
            };
        },
        lastPlayPointStyle() {
            return {
                transform: `translate3d(${this.lastPlayPoint}px, ${this.ieTranslate}, 0px)`,
                position: 'absolute',
                transition: this.currentSliderTransition,
                'z-index': 40,
            };
        },
        mouseMarkerDisplayTime() {
            return formatHHMMSS(this.mouseMarkerValue);
        },
        usedClassOptions() {
            return this.classOptions || {};
        },
    },
    watch: {
        buffered(newTimeRanges) {
            this.bufferStyles = [];
            for (let i = 0; i < newTimeRanges.length; i++) {
                let s = newTimeRanges.start(i);
                let e = newTimeRanges.end(i);
                this.bufferStyles.push({
                    left: `${(s / this.duration) * this.width}px`,
                    'z-index': 20,
                    width: `${((e - s) / this.duration) * this.width}px`,
                });
            }
        },
    },
    mounted() {
        this.setDimensionsFromDomElement();
        this.resizeHandle = this.$store.subscribe({
            action: 'product/resize',
            handler: () => {
                this.setDimensionsFromDomElement();
                this.setPositionFromSliderValue();
            },
        });
    },
    unmounted() {
        this.$Logger.debug('lifecycle', { event: 'unmounted', component: 'SliderControl' });
        // ensure all event listeners are removed
        window.removeEventListener('mousemove', this.slideHorizontally);
        window.removeEventListener('mouseup', this.sliderMouseUp);
        window.removeEventListener('mousemove', this.trackMousePosition);
        this.$store.unsubscribe(this.resizeHandle);
    },
    methods: {
        keepInRange(value, lowerBound, upperBound) {
            var leftBound = value >= lowerBound;
            var rightBound = value <= upperBound;
            if (leftBound && rightBound) {
                return value;
            }
            if (!leftBound) {
                return lowerBound;
            }
            return upperBound;
        },
        slideHorizontally(e) {
            var newPos = e.clientX - this.xStart;
            this.sliderXPosition = this.keepInRange(newPos, 0, this.width);
            this.progressRatio = this.sliderXPosition / this.width;
        },
        sliderMouseDown() {
            this.userIsSeeking = true;
            this.$emit('seekStarted');
            document.body.style.cursor = 'pointer';
            this.currentSliderTransition = this.noTransition;
            this.showLastPlayPoint = true;
            this.lastPlayPoint = this.sliderXPosition;
            window.addEventListener('mousemove', this.slideHorizontally);
            window.addEventListener('mouseup', this.sliderMouseUp);
        },
        sliderMouseUp() {
            if (!this.userIsSeeking) {
                return;
            }
            this.$emit('currentTimeChanged', this.sliderValue);
            this.userIsSeeking = false;
            this.$emit('seekEnded');
            document.body.style.cursor = 'auto';
            if (!this.mouseInTrackArea) {
                window.removeEventListener('mousemove', this.trackMousePosition);
            }
            this.currentSliderTransition = this.smoothTransition;
            this.showLastPlayPoint = false;
            this.hideSlider();
            window.removeEventListener('mousemove', this.slideHorizontally);
            window.removeEventListener('mouseup', this.sliderMouseUp);
        },
        expandThumb() {
            this.currentSliderDiameter = this.defaultSliderDiameter * this.expansionFactor;
        },
        shrinkThumb() {
            this.currentSliderDiameter = this.defaultSliderDiameter;
        },
        setDimensionsFromDomElement() {
            const container = document.getElementById(this.containingElementId);
            let containerDisplayRef;
            if (container) {
                containerDisplayRef = container.style.display;
                container.style.display = 'flex';
            }
            this.boundingClientRect = this.$el.getBoundingClientRect();
            if (container) {
                container.style.display = containerDisplayRef;
            }
            this.width = this.boundingClientRect.width;
            this.xStart = this.boundingClientRect.left;
            this.xEnd = this.xStart + this.width;

            this.yStart = this.boundingClientRect.bottom;
            this.yEnd = this.boundingClientRect.top;
            this.height = this.yStart - this.yEnd;
        },
        setPositionFromSliderValue() {
            if (this.duration === 0) {
                this.sliderXPosition = 0;
                return;
            }
            const ratio = this.sliderValue / this.duration;
            this.sliderXPosition = ratio * this.width;
        },
        setSliderDefaultSize(width) {
            this.defaultSliderDiameter = this.defaultSliderDiameter || width;
            this.currentSliderDiameter = this.defaultSliderDiameter || width;
            this.sliderThumbRect = {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            };
        },
        jumpToPosition(e) {
            if (this.userIsSeeking) {
                return;
            }
            this.slideHorizontally(e);
            this.$emit('currentTimeChanged', this.sliderValue);
        },
        hideSlider() {
            if (this.userIsSeeking || this.mouseInTrackArea) {
                return;
            }
            this.currentOpacity = 0;
        },
        mouseLeaveTrackArea() {
            this.mouseInTrackArea = false;
            this.hideSlider();
            if (!this.userIsSeeking) {
                window.removeEventListener('mousemove', this.trackMousePosition);
            }
        },
        mouseEnterTrackArea() {
            this.mouseInTrackArea = true;
            this.currentOpacity = 1;
            window.addEventListener('mousemove', this.trackMousePosition);
        },
        trackMousePosition(e) {
            this.mouseXPosition = e.clientX - this.xStart;
        },
    },
};
</script>

<template>
    <div
        :style="wrapperStyle"
        @mouseenter="mouseEnterTrackArea"
        @mouseleave="mouseLeaveTrackArea"
        @mousedown="jumpToPosition"
    >
        <div :id="controlTrackDomSelector" :class="usedClassOptions.track" style="align-items: center; display: flex">
            <div
                :id="progressBarDomSelector"
                :class="usedClassOptions.progress"
                :style="progressStyle"
                class="no-select"
            />
            <SliderThumb
                :id="sliderDomSelector"
                :class="usedClassOptions.slider"
                class="no-select"
                :style="sliderStyle"
                @thumbLoaded="setSliderDefaultSize"
                @mousedown="sliderMouseDown"
                @mouseenter="expandThumb"
                @mouseleave="shrinkThumb"
            />
            <div
                v-for="(item, index) in bufferStyles"
                :id="'buffered-bar-selector-' + index"
                :key="index"
                :class="usedClassOptions.buffered"
                :style="item"
                class="no-select"
            />
            <span
                :id="mouseMarkerSelector"
                :style="mouseMarkerStyle"
                :class="usedClassOptions.mouseMarker"
                class="no-select"
            />
            <span
                v-show="showLastPlayPoint"
                :id="lastPlayPointSelector"
                :style="lastPlayPointStyle"
                :class="usedClassOptions.lastPlayPoint"
                class="no-select"
            />
            <slot
                name="tool-tip"
                :parent-position="mouseMarkerPosition"
                :opacity="currentOpacity"
                :mouse-marker-display-time="mouseMarkerDisplayTime"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
