import TorrentParser from 'parse-torrent';
import { defaultTrackers } from '../utils/torrent';
import { Torrent, WebtorrentClient } from './WebTorrent';
import { $Logger } from '@/services';

const state = {
    // infoHash -> Torrent
    torrents: new Map(),
    // infoHash -> Map(peerId -> wire)
    peersMap: new Map(),
    // infoHash -> Map(trackerUrl -> socket)
    trackersMap: new Map(),
};

const getters = {
    torrents: ({ torrents }) => torrents,
    peersMap:
        ({ peersMap }) =>
        (infoHash) =>
            peersMap.get(infoHash) || new Map(),
    trackersMap:
        ({ trackersMap }) =>
        (infoHash) =>
            trackersMap.get(infoHash) || new Map(),
};

const mutations = {
    setPeer(state, { infoHash, peerId, wire }) {
        let m = state.peersMap.get(infoHash);
        if (m === undefined) {
            m = new Map();
        }
        m.set(peerId, wire);
        state.peersMap.set(infoHash, m);
    },
    setTracker(state, { infoHash, tracker, socket }) {
        let m = state.trackersMap.get(infoHash);
        if (m === undefined) {
            m = new Map();
        }
        m.set(tracker, socket);
        state.trackersMap.set(infoHash, m);
    },
};

const actions = {
    addTorrent(context, magnetLink) {
        $Logger.trace('library', { event: 'addTorrent', magnetLink });
        let torrentObject = TorrentParser(magnetLink);
        torrentObject.announce = [...new Set([...torrentObject.announce, ...defaultTrackers])];
        const newMagnet = TorrentParser.toMagnetURI(torrentObject);
        let protocol = WebtorrentClient.get(newMagnet);
        if (protocol === null) {
            protocol = WebtorrentClient.add(newMagnet);
            const torrent = new Torrent(protocol, context);
            torrent.bindListeners();
            context.state.torrents.set(protocol.infoHash, torrent);
        }
        return context.state.torrents.get(protocol.infoHash);
    },
    removeTorrent(context, infoHash) {
        $Logger.trace('library', { event: 'removeTorrent', infoHash });
        return new Promise((res) => {
            const torrent = context.state.torrents.get(infoHash);
            if (torrent !== undefined) {
                torrent.destroy(() => res());
                context.state.torrents.delete(infoHash);
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
