<script>
import { formatBytes } from '@/utils/encoding';
import { isStreamable } from '@/utils/torrent';
import { mapGetters } from 'vuex';
import { supportedSubtitleExtension } from '@/utils/subtitle';
import { parseRoute } from '@/utils';

export default {
    name: 'MediaFile',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters('player', ['playing', 'currentFiles', 'currentTorrent', 'fileIndex']),
        currentFile() {
            return this.currentFiles[this.index];
        },
        rawName() {
            return this.currentFile.name;
        },
        name() {
            if (!this.rawName) {
                return '';
            }
            let endIndex = this.rawName.lastIndexOf('.');
            endIndex = endIndex !== -1 ? endIndex : this.rawName.length;
            return this.rawName.substr(0, endIndex);
        },
        nameExtension() {
            if (!this.rawName) {
                return '';
            }
            const startIndex = this.rawName.lastIndexOf('.');
            if (startIndex !== -1) {
                return this.rawName.substr(startIndex, this.rawName.length);
            }
            return '';
        },
        streamable() {
            return isStreamable(this.nameExtension);
        },
        progress() {
            return this.currentFile.progress * 100;
        },
        complete() {
            return this.currentFile.progress == 1;
        },
        isFileLoaded() {
            return this.index === this.fileIndex;
        },
        isFilePlaying() {
            return this.isFileLoaded && this.playing;
        },
        size() {
            return `${formatBytes(this.currentFile.length, 0)}`;
        },
    },
    methods: {
        selectFile() {
            if (supportedSubtitleExtension(this.currentFile.name)) {
                this.currentTorrent.loadSubtitle(this.index).catch((err) => console.error(err));
            } else {
                const { magnetLink } = parseRoute(this.$route);
                this.$router.push({
                    path: `/player`,
                    query: {
                        m: btoa(magnetLink),
                        i: this.index,
                    },
                });
            }
        },
    },
};
</script>

<template>
    <div :class="{ streamable: streamable }" class="file-list-item">
        <div class="media-file" :class="{ streamable: streamable, playing: isFileLoaded }" @click.stop="selectFile">
            <div class="file-stream">
                <div class="play-button">
                    <SvgIcon v-if="isFilePlaying" icon="pause" class="pause-button-icon" :has-fill="true" />
                    <SvgIcon v-else icon="play-arrow-filled" class="play-button-icon" :has-fill="true" />
                </div>
            </div>
            <div class="media-file-details">
                <div class="file-name-full-container" :class="{ disabled: false }">
                    <span class="file-name" :title="rawName">{{ name }}</span>
                    <span class="name-extension">
                        {{ nameExtension }}
                    </span>
                </div>
                <div class="media-file-right">
                    <div class="file-watched-container">
                        <progress
                            v-if="streamable"
                            max="100"
                            :value="progress"
                            :class="{ complete: complete }"
                            class="file-progress"
                            aria-labelledby="progress-id"
                        ></progress>
                    </div>
                    <div class="file-size">
                        {{ size }}
                    </div>
                </div>
            </div>
        </div>
        <hr v-if="true" class="media-file-separator" />
    </div>
</template>

<style lang="scss">
.tooltip {
    &.media-not-supported-tooltip {
        .tooltip-inner {
            padding: 12px;
            max-width: 180px;
            text-align: left;
            line-height: 1.6;
        }
    }
}
.media-file-separator {
    background-color: $neutral-medium-dark;
    color: $neutral-medium-dark;
    border: none;
    height: 1px;
    margin: 0;
}
</style>

<style lang="scss" scoped>
@import '@/stylesheets/mixins/_progress-bar.scss';
.file-list-item {
    order: 1;
    &.streamable {
        order: 0;
    }
}
.media-file {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 44px;
    padding: 0 24px 0 16px;
    font-size: 0.9rem;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
    .bt-icons {
        font-size: 20px;
    }
    &:hover {
        background-color: rgba(52, 52, 72, 0.4);
        .pause-button-icon,
        .play-button-icon {
            background-color: $neutral-black !important;
            border-color: $neutral-black !important;
            fill: $neutral-white !important;
            color: $neutral-white !important;
        }
    }
    &.playing {
        .pause-button-icon,
        .play-button-icon {
            background-color: $brand-primary-main !important;
            border-color: $brand-primary-main !important;
            fill: $neutral-white !important;
            color: $neutral-white !important;
        }
        background-color: $neutral-medium-dark;
    }
    &.streamable {
        .play-button {
            display: inline-block !important;
        }
    }
    .file-stream {
        display: flex;
        min-width: 24px;
        .play-button {
            display: none;
            text-align: center;
            -webkit-transition: background-color 0.3s ease;
            -moz-transition: background-color 0.3s ease;
            transition: background-color 0.3s ease;
            .pause-button-icon,
            .play-button-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border: 2px solid $neutral-black;
                border-radius: 50%;
                height: 24px;
                width: 24px;
                background-color: $neutral-black;
                fill: $neutral-white;
                color: $neutral-white;
                &:hover {
                    background-color: $brand-primary-main;
                    border-color: $brand-primary-main;
                    fill: $neutral-white;
                    color: $neutral-white;
                }
                @media (max-width: 607px) {
                    height: 44px;
                    width: 44px;
                }
            }
            .pause-button-icon {
                font-size: 16px;
                @media (max-width: 607px) {
                    font-size: 39px;
                }
            }
            .play-button-icon {
                font-size: 18px;
                @media (max-width: 607px) {
                    font-size: 37px;
                }
            }
        }
        @media (max-width: 607px) {
            min-width: 60px;
        }
    }
    .media-file-details {
        display: flex;
        column-gap: 32px;
        width: 100%;
        padding-left: 16px;
        .file-name-full-container {
            display: flex;
            flex: 1;
            overflow: hidden;
            font-weight: 500;
            &.not-supported {
                cursor: pointer;
                border-bottom: 1px solid $neutral-white;
            }
            &.disabled {
                color: $neutral-main;
            }
            .file-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @media (max-width: 607px) {
                    white-space: normal;
                    text-overflow: inherit;
                    overflow: visible;
                }
            }
            .name-extension {
                display: inline-block;
            }
            @media (max-width: 607px) {
                text-overflow: inherit;
                overflow: visible;
                display: inline;
                line-height: 17px;
                font-size: 14px;
            }
        }
        .media-file-right {
            display: flex;
            align-items: center;
            column-gap: 32px;
            .file-watched-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                min-width: 80px;
                width: 122px;
                color: $neutral-light-medium;
                .file-progress {
                    @include progress(4px, 2px, $neutral-white, $brand-primary-main, $brand-primary-main);
                    width: 80%;
                    .progress-bar {
                        width: 100%;
                    }
                    @media (max-width: 607px) {
                        @include progress(2px, 1px, $neutral-white, $brand-primary-main, $brand-primary-main);
                    }
                }
            }
            .file-size {
                display: flex;
                align-items: center;
                width: 72px;
                color: $neutral-light-medium;
                font-weight: 400;
                @media (max-width: 607px) {
                    font-size: 12px;
                    width: auto;
                }
            }
            @media (max-width: 607px) {
                justify-content: space-between;
                margin-top: 13px;
            }
        }
        @media (max-width: 607px) {
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 14px 0;
        }
    }
    @media (max-width: 607px) {
        min-height: 76px;
        padding: 0 16px;
    }
}
</style>
