import { SessionAnalytics, getNormalizedTime } from './session';
import { GoogleAnalytics } from './ga';
import { isStreamable } from '@/utils/torrent';
import _ from 'lodash';

const STALL_THRESHOLD_MS = 3 * 1000; // 3 seconds

class PlayerAnalytics {
    static scope = 'player';
    static scope_1 = 'pl';
    static ping(eventName, properties = {}) {
        GoogleAnalytics.sendCustomEvent(`${PlayerAnalytics.scope_1}_${eventName}`, properties);
    }

    constructor() {
        this.initialized = Date.now();
        this.firstPlayedAfter = null;
        this.firstPlayedHandle = _.once(() => {
            this.firstPlayedAfter = Date.now() - this.initialized;
        });
        this.mediaReadyAfter = null;
        this.mediaReadyHandle = _.once(() => {
            this.mediaReadyAfter = Date.now() - this.initialized;
        });
        this.duration = null;
        this.plays = new Array();
        this.isPlaying = false;
        this.secondsPlayedMap = new Map();
        this.stallCount = 0;
        this.totalStallTime = 0;
        this.stallStarted = null;
        this.isSubmitted = false;
        this.fileType = null;
        SessionAnalytics.register(this);
        SessionAnalytics.playerCount += 1;
    }
    interactionPing(source, action, state = null) {
        GoogleAnalytics.engagementPing(
            PlayerAnalytics.scope,
            `${source}_${action}${state === null ? '' : `_${state}`}`
        );
    }

    // duration of the media is updated
    durationChange(duration) {
        this.duration = duration;
    }
    // first frame of the media is loaded
    loadedData() {
        this.mediaReadyHandle();
    }
    timeUpdate(time) {
        // NOTE: at the moment we count a second as a discrete unit.
        // for instance, if player has played second 1.4323 of a media
        // we assume the entire second 1 of the media has been played.
        // This might not result in an accurate measure in some scenarios
        // for instance, if user keeps seeking video, we get timeUpdate events
        // and account for those seconds watched, eventhough user was seeking through (not actually watching)
        // This metric can be made more accurate and granular if we make our descrete unit smaller that a second
        // for instance, a 10th of a second, a millisecond, or ...
        // Note that the `time` given is a double-precision floating point,
        // which sets the limt to how granular we can make this metric
        this.secondsPlayedMap.set(Math.floor(time), true);
    }
    // playback started
    play() {
        if (!this.isPlaying) {
            this.isPlaying = true;
            this.plays.push([Date.now(), null]);
        }
    }
    // playback paused
    pause() {
        if (this.isPlaying) {
            this.isPlaying = false;
            if (this.plays.length !== 0) {
                this.plays[this.plays.length - 1][1] = Date.now();
            }
        }
    }
    // event fired due to stall/lack-of-data
    waiting() {
        if (this.isPlaying) {
            this.isPlaying = false;
            if (this.plays.length !== 0) {
                this.plays[this.plays.length - 1][1] = Date.now();
            }
        }
        this.stallStarted = Date.now();
    }
    // event fired when playback continues from a pause or a stall/wait
    playing() {
        if (!this.isPlaying) {
            this.isPlaying = true;
            this.plays.push([Date.now(), null]);
        }
        this.firstPlayedHandle();
        // a stall is defined as playback interruption more than STALL_THRESHOLD
        if (this.stallStarted !== null) {
            const stallTime = Date.now() - this.stallStarted;
            if (stallTime > STALL_THRESHOLD_MS) {
                this.stallCount++;
                this.totalStallTime += stallTime;
            }
            this.stallStarted = null;
        }
    }

    setFileType(type) {
        this.fileType = type;
    }

    error(error) {
        const { code } = error;
        GoogleAnalytics.healthPing(PlayerAnalytics.scope, `error_${code}`);
    }

    get totalPlayTime() {
        let ms = 0;
        for (let p of this.plays) {
            if (p[1] === null) {
                ms += Date.now() - p[0];
            } else {
                ms += p[1] - p[0];
            }
        }
        return ms;
    }

    get playbackPercentage() {
        if (this.duration === null || this.duration === 0) {
            return 0;
        }
        return this.secondsPlayedMap.size / Math.floor(this.duration);
    }
    get stats() {
        let stallCount = this.stallCount;
        let totalStallTime = this.totalStallTime;
        if (this.stallStarted !== null) {
            const currentStallTime = Date.now() - this.stallStarted;
            if (currentStallTime > STALL_THRESHOLD_MS) {
                stallCount += 1;
                totalStallTime += currentStallTime;
            }
        }
        return {
            totalPlayTime: this.totalPlayTime,
            playbackPercentage: this.playbackPercentage,
            stallCount,
            totalStallTime,
        };
    }
    submit() {
        if (this.isSubmitted) {
            return;
        }
        this.isSubmitted = true;
        SessionAnalytics.unregister(this);

        const stats = this.stats;
        let streamableContent = null;

        // report file type
        if (this.fileType !== null) {
            GoogleAnalytics.engagementPing(PlayerAnalytics.scope, `filetype_${this.fileType}`);
            streamableContent = isStreamable(this.fileType);
        }

        // accumulate playtimes of the entire session
        SessionAnalytics.totalPlaytime += stats.totalPlayTime;

        PlayerAnalytics.ping('submit', {
            streamableContent: streamableContent,
            fileType: this.fileType,
            playbackPercentage: Math.floor(stats.playbackPercentage * 100),
            playbackTime: getNormalizedTime(stats.totalPlayTime),
            stallTime: getNormalizedTime(stats.totalStallTime),
            firstPlayedAfter: this.firstPlayedAfter === null ? null : getNormalizedTime(this.firstPlayedAfter),
            mediaReadyAfter: this.mediaReadyAfter === null ? null : getNormalizedTime(this.mediaReadyAfter),
            lifetime: getNormalizedTime(Date.now() - this.initialized),
        });

        if (streamableContent || streamableContent === null) {
            // report playback percentage
            if (stats.playbackPercentage > 0.01) {
                GoogleAnalytics.engagementPing(
                    PlayerAnalytics.scope,
                    `watched_percent_${Math.floor(stats.playbackPercentage * 100)}`
                );
            }

            // report playback time
            GoogleAnalytics.engagementPing(
                PlayerAnalytics.scope,
                `watched_time_${getNormalizedTime(stats.totalPlayTime)}`
            );

            // report stall time
            GoogleAnalytics.healthPing(PlayerAnalytics.scope, `stalled_${getNormalizedTime(stats.totalStallTime)}`);

            // report time elapsed till initial
            if (this.firstPlayedAfter !== null) {
                GoogleAnalytics.engagementPing(
                    PlayerAnalytics.scope,
                    `played_after_${getNormalizedTime(this.firstPlayedAfter)}`
                );
            }

            // report time for the media to be ready
            if (this.mediaReadyAfter !== null) {
                GoogleAnalytics.healthPing(
                    PlayerAnalytics.scope,
                    `media_ready_${getNormalizedTime(this.mediaReadyAfter)}`
                );
            }
        }
    }
}

export { PlayerAnalytics };
