import { createApp } from 'vue';
import Toast from './Toast.vue';

/**
 * Toast instance operation
 */
const createInstance = (config = {}) => {
    // create a container and set its class
    let toastNode = document.createElement('div');
    let attr = document.createAttribute('class');
    attr.value = 'lite-toast';
    toastNode.setAttributeNode(attr);

    // when the next toast happens, it will have a distance from the previous one
    const gap = 12;
    const toastList = document.getElementsByClassName('lite-toast');

    const getToastTop = (previousToast) => {
        if (previousToast) {
            return previousToast.offsetTop + previousToast.offsetHeight + gap;
        }
        // distance of the first toast from top
        return 30;
    };

    toastNode.style.top = `${getToastTop(toastList[toastList.length - 1])}px`;

    // reset each toast's distance (Top value) to the top
    const resetToastTop = () => {
        for (let i = 0; i < toastList.length; i++) {
            toastList[i].style.top = `${getToastTop(toastList[i - 1])}px`;
        }
    };

    const handleRemove = () => {
        app.unmount(toastNode);
        document.body.removeChild(toastNode);
        resetToastTop();
    };

    // create a Vue instance and append to Body
    const app = createApp(Toast, {
        config,
        // remove the element, close toast and unmount and remove from DOM
        remove() {
            handleRemove();
        },
    });

    // mount the instance and append to end of Body
    app.mount(toastNode);
    document.body.appendChild(toastNode);

    return app;
};

export default createInstance;
