<script>
import CarouselControl from './CarouselControl';
import escapeableMixin from '@/mixins/escapeable';

export default {
    name: 'Modal',
    components: {
        CarouselControl,
    },
    mixins: [escapeableMixin],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        showCloseButton: {
            type: Boolean,
            default: true,
        },
        containerClass: {
            type: String,
            default: '',
        },
        numberOfModals: {
            type: Number,
            default: 0,
        },
        isNumericIndex: {
            type: Boolean,
            default: false,
        },
        fixedPadding: {
            type: Number,
            default: 0,
        },
        escapeable: {
            type: Boolean,
            default: true,
        },
        clickToClose: {
            type: Boolean,
            default: false,
        },
        closeButtonEvent: {
            type: String,
            default: '',
        },
        closeButtonColor: {
            type: String,
            default: '#000000',
        },
    },
    computed: {
        showCarouselControl() {
            return this.numberOfModals > 1;
        },
        fixedPaddingStyle() {
            return {
                'padding-top': `${this.fixedPadding}px`,
                'padding-bottom': `${this.fixedPadding}px`,
            };
        },
        escapableVisible() {
            return this.show;
        },
    },
    watch: {
        show(value) {
            if (value) {
                document.body.classList.add('no-scroll');
            } else {
                document.body.classList.remove('no-scroll');
            }
        },
    },
    beforeUnmount() {
        // Removing class when watch() doesn't get called in IE/Edge
        if (document.body.classList.contains('no-scroll')) {
            document.body.classList.remove('no-scroll');
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        clickOutside() {
            if (this.clickToClose) {
                this.close();
            }
        },
        updateActiveModalIndex(value) {
            this.$emit('updateActiveModalIndex', value);
        },
        carouselIndexDecrease() {
            this.$emit('carouselIndexDecrease');
        },
        carouselIndexIncrease() {
            this.$emit('carouselIndexIncrease');
        },
        carouselIndexFirst() {
            this.$emit('carouselIndexFirst');
        },
        carouselIndexLast() {
            this.$emit('carouselIndexLast');
        },
        resetCarouselControlIndex() {
            this.$refs.carouselControl.resetIndex();
        },
    },
};
</script>

<template>
    <transition name="modal" class="w-100">
        <div v-if="show" class="modal-mask w-100" @click.stop="clickOutside">
            <div class="padding-container" :style="fixedPaddingStyle">
                <div class="modal-container" :class="containerClass" @click.stop>
                    <slot />
                    <div
                        v-if="showCloseButton"
                        id="auto-modal-close-btn"
                        class="modal-close-btn button-icon--dark"
                        :style="{ fill: closeButtonColor }"
                        :data-gtm-event="closeButtonEvent"
                        @click="close"
                    >
                        <SvgIcon icon="close" :has-fill="true" class="close" />
                    </div>
                    <CarouselControl
                        v-if="showCarouselControl"
                        ref="carouselControl"
                        :number-of-modals="numberOfModals"
                        :is-numeric-index="isNumericIndex"
                        @updateActiveModalIndex="updateActiveModalIndex"
                        @carouselIndexDecrease="carouselIndexDecrease"
                        @carouselIndexIncrease="carouselIndexIncrease"
                        @carouselIndexFirst="carouselIndexFirst"
                        @carouselIndexLast="carouselIndexLast"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
@import '@/stylesheets/modal.scss';

.modal-mask {
    @include overlay-mask(rgba(0, 0, 0, 0.5));

    z-index: zindex(modal);
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
}
.padding-container {
    margin: auto 0;
    max-width: 100%;
}
.modal-container {
    position: relative;
    background-color: transparent;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    max-width: 100%;
}
.modal-close-btn {
    position: absolute;
    top: 20px;
    right: 28px;
    cursor: pointer;
    font-size: 20px;
    fill: $neutral-black;
}
:deep(.modal-header) {
    font-size: 18px;
    color: $brand-primary-main;
    &.danger {
        color: $accents-red-main;
    }
}
:deep(.modal-body) {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin: 20px 0;
}
:deep(.modal-two-button-footer) {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
:deep(.modal-default-button) {
    float: right;
}
:deep(.modal-footer-left-button)s {
    margin-right: 14px;
}
.close {
    pointer-events: none;
}
.w-100 {
    min-width: 100%;
}
</style>
