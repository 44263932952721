import { createRouter, createWebHashHistory } from 'vue-router';
import { ConsentGuard, PlaybackGuard } from './guards';
import { GoogleAnalytics } from '@/services';
import Home from '@/views/Home.vue';
import Player from '@/views/Player.vue';
import Faq from '@/views/Faq.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            ga: {
                pathUrl: '/',
                name: 'home',
            },
        },
    },
    {
        path: '/player/:magnetLink/:fileId?',
        name: 'player',
        component: Player,
        meta: {
            version: 1,
            ga: {
                pathUrl: '/player',
                name: 'player',
            },
            requiresConsent: true,
        },
    },
    {
        path: '/player',
        name: 'playerV2',
        component: Player,
        meta: {
            version: 2,
            ga: {
                pathUrl: '/player',
                name: 'player',
            },
            requiresConsent: true,
        },
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {
            ga: {
                pathUrl: '/faq',
                name: 'faq',
            },
        },
    },
    {
        path: '/:catchAll(.*)',
        redirect: { name: 'home' },
    },
];

const router = createRouter({
    routes,
    history: createWebHashHistory(process.env.BASE_URL),
    scrollBehavior() {
        return { top: 0, left: 0 };
    },
});

router.beforeEach(ConsentGuard);
router.beforeEach(PlaybackGuard);
router.beforeEach((to) =>
    GoogleAnalytics.sendPageView({
        path: to.meta.ga.pathUrl,
        url: window.location.protocol + '//' + window.location.host,
    })
);

export default router;
