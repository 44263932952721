import Store from '@/store';
import { parseRoute } from '@/utils';

const interruptsPlayback = (to, from) => {
    if (Store.getters['player/currentTorrent'] !== null) {
        // if we are going anywhere other than the player warn 'leaving'
        if (to.name !== 'player' && to.name !== 'playerV2') {
            return { interrupt: true, signal: 'leaving' };
        }
        // if we are going to a new player page, check the new content
        const { magnetLink: fromMagnetLink } = parseRoute(from);
        const { magnetLink: toMagnetLink } = parseRoute(to);
        if (fromMagnetLink === toMagnetLink) {
            return { interrupt: false };
        }
        return { interrupt: true, signal: 'new' };
    }
    return { interrupt: false };
};

export const PlaybackGuard = async (to, from) => {
    const { interrupt, signal } = interruptsPlayback(to, from);
    if ((from.name === 'player' || from.name === 'playerV2') && interrupt) {
        const result = await Store.dispatch('product/askForInterrupt', signal);
        if (result === 'replace') {
            return true;
        } else if (result === 'cancel') {
            return false;
        } else if (result === 'new') {
            window.open(to.href);
            return false;
        } else {
            throw new Error(`Unknown interrupt result ${result}`);
        }
    }
};
