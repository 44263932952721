export { rand } from './encoding';
export { speedtest } from './speedtest';

export const sleep = (millis) => {
    return new Promise((res) => {
        setTimeout(() => res(), millis);
    });
};

export const parseRoute = (route) => {
    let magnetLink, urlFileIndex;
    switch (route.meta.version) {
        case 2:
            const { m, i } = route.query;
            magnetLink = atob(m);
            urlFileIndex = parseInt(i);
            urlFileIndex = isNaN(urlFileIndex) ? null : urlFileIndex;
            return { magnetLink, urlFileIndex };
        default:
            magnetLink = decodeURIComponent(route.params.magnetLink);
            urlFileIndex = parseInt(decodeURIComponent(route.params.fileId));
            urlFileIndex = isNaN(urlFileIndex) ? null : urlFileIndex;
            return { magnetLink, urlFileIndex };
    }
};
