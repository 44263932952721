import WebTorrent from 'webtorrent';
import { TorrentAnalytics } from '@/services/analytics';
import { generatePeerId, parsePeerId } from '@/utils/torrent';
import { supportedSubtitleExtension, parse, build } from '@/utils/subtitle';
import { $Logger } from '@/services';

const shouldBlockPredicate = (peer) => {
    if (typeof peer === 'object' && typeof peer.id === 'string' && parsePeerId(peer.id).isLite) {
        return true;
    }
    return false;
};

const NUM_PEERS = 10;
export const WebtorrentClient = new WebTorrent({
    peerId: generatePeerId(),
    shouldBlock: localStorage.getItem('blockLitePeers') === 'true' ? shouldBlockPredicate : undefined,
    tracker: {
        getAnnounceOpts: () => ({
            numwant: NUM_PEERS,
        }),
        rtcConfig: {},
        announceStrategy: {
            predicate: (infoHash) => {
                let torrent = WebtorrentClient.torrents.filter((t) => t.infoHash === infoHash);
                if (torrent.length !== 1) {
                    return undefined;
                }
                torrent = torrent[0];
                if (torrent.done) {
                    return undefined;
                }
                if (torrent._numConns >= NUM_PEERS) {
                    return false;
                }
                return {
                    numwant: NUM_PEERS - torrent._numConns,
                };
            },
            frequency: 10000,
        },
        intervalMs: 15 * 60 * 1000, // explicit reannounce interval
    },
    dht: false, // explicit disable DHT (only tracker for discovery)
    lst: false, // explicit disable LST
});

export class Torrent {
    constructor(protocol, context) {
        $Logger.trace('webtorrent', { event: 'constructor', infoHash: protocol.infoHash });
        this.protocol = protocol;
        this.onReady = null;
        this.context = context;
        this.analytics = new TorrentAnalytics(protocol.infoHash);
        // Map<fileIndex, VTTBlob>
        this.subtitles = new Map();
    }

    get infoHash() {
        return this.protocol.infoHash;
    }

    get progress() {
        return this.protocol.progress;
    }

    bindListeners() {
        $Logger.trace('webtorrent', { event: 'bindListeners' });
        this.onTorrentNoPeers = (method) => {
            $Logger.trace('webtorrent', { event: 'noPeers' });
            this.analytics.noPeer(method);
            this.updateContext();
        };

        this.onTorrentMetadata = async () => {
            $Logger.trace('webtorrent', { event: 'metadata' });
            this.updateContext();
            // load all potential subtitles
            try {
                await this.loadSubtitles();
            } catch (err) {
                console.error(err);
            }
        };
        this.onTorrentReady = () => {
            $Logger.trace('webtorrent', { event: 'ready' });
            this.updateContext();
            this.analytics.ready();
            this._readyResolver(this);
        };

        this.onTorrentDownload = (_) => {
            this.analytics.download();
            this.updateContext();
        };

        this.onTorrentDone = () => {
            $Logger.trace('webtorrent', { event: 'done' });
            console.info('all files downloaded');
            this.analytics.done();
            this.updateContext();
        };

        this.onTorrentError = (err) => {
            $Logger.trace('webtorrent', { event: 'error', error: err });
            console.error(`fatal error ${err}`);
            this.analytics.error(err);
            this.updateContext();
        };

        this.onTorrentPeerCreate = (peerId) => {
            $Logger.trace('webtorrent', { event: 'peerCreate', peerId });
            this.context.commit('library/setPeer', { infoHash: this.infoHash, peerId, wire: null }, { root: true });
        };
        this.onTorrentWire = (wire) => {
            $Logger.trace('webtorrent', { event: 'wire', infoHash: this.infoHash, peerId: wire.peerId });
            this.context.commit(
                'library/setPeer',
                { infoHash: this.infoHash, peerId: wire.peerId, wire },
                { root: true }
            );
            this.analytics.wire();
            this.updateContext();
        };
        this.onTorrentPeerConnect = (peerId, wire) => {
            $Logger.trace('webtorrent', { event: 'peerConnect', infoHash: this.infoHash, peerId });
            this.analytics.peerConnect();
            this.context.commit('library/setPeer', { infoHash: this.infoHash, peerId, wire }, { root: true });
        };

        this.onTorrentPeerDestroy = (peerId, err) => {
            $Logger.trace('webtorrent', { event: 'peerDestroy', peerId, error: err });
            this.analytics.peerDestroy();
            this.context.commit(
                'library/setPeer',
                { infoHash: this.infoHash, peerId, wire: undefined },
                { root: true }
            );
        };
        // TODO: add removeListeners
        this.onTorrentDiscoveryStarted = () => {
            $Logger.trace('webtorrent', { event: 'discoveryStarted' });
            for (let tracker of this.protocol.discovery.tracker._trackers) {
                this.context.commit(
                    'library/setTracker',
                    { infoHash: this.infoHash, tracker: tracker.announceUrl, socket: null },
                    { root: true }
                );
                tracker.addListener('socketConnect', (socket) => {
                    $Logger.trace('webtorrent', {
                        event: 'socketConnect',
                        tracker: tracker.announceUrl,
                        infoHash: this.infoHash,
                        socketUrl: socket.url,
                    });
                    this.context.commit(
                        'library/setTracker',
                        { infoHash: this.infoHash, tracker: tracker.announceUrl, socket },
                        { root: true }
                    );
                });
                tracker.addListener('socketClose', () => {
                    $Logger.trace('webtorrent', {
                        event: 'socketClose',
                        tracker: tracker.announceUrl,
                        infoHash: this.infoHash,
                    });
                    this.context.commit(
                        'library/setTracker',
                        { infoHash: this.infoHash, tracker: tracker.announceUrl, socket: undefined },
                        { root: true }
                    );
                });
                tracker.addListener('socketError', () => {
                    $Logger.trace('webtorrent', {
                        event: 'socketError',
                        tracker: tracker.announceUrl,
                        infoHash: this.infoHash,
                    });
                    this.context.commit(
                        'library/setTracker',
                        { infoHash: this.infoHash, tracker: tracker.announceUrl, socket: undefined },
                        { root: true }
                    );
                });
            }
        };

        this.protocol.addListener('peerConnect', this.onTorrentPeerConnect);
        this.protocol.addListener('peerCreate', this.onTorrentPeerCreate);
        this.protocol.addListener('peerDestroy', this.onTorrentPeerDestroy);
        this.protocol.addListener('nopeers', this.onTorrentNoPeers);
        this.protocol.addListener('wire', this.onTorrentWire);
        this.protocol.addListener('metadata', this.onTorrentMetadata);
        this.protocol.addListener('download', this.onTorrentDownload);
        this.protocol.addListener('done', this.onTorrentDone);
        this.protocol.addListener('error', this.onTorrentError);
        this.protocol.addListener('discoveryStarted', this.onTorrentDiscoveryStarted);

        if (this.protocol.ready) {
            this.onReady = Promise.resolve(this);
        } else {
            this.onReady = new Promise((res, rej) => {
                this._readyResolver = res;
                this.protocol.addListener('ready', this.onTorrentReady);
            });
        }
    }
    loadSubtitle(fileIndex) {
        return new Promise((res, rej) => {
            this.protocol.files[fileIndex].createReadStream().on('data', (bytes) => {
                const content = new TextDecoder().decode(bytes);
                try {
                    const rawSubtitle = parse(content, {});
                    const vttSubtitle = build(rawSubtitle, { format: 'vtt' });
                    this.subtitles.set(fileIndex, URL.createObjectURL(new Blob([vttSubtitle])));
                    this.context.commit('player/setSubtitles', this.subtitles, { root: true });
                    res(vttSubtitle);
                } catch (err) {
                    rej(err);
                }
            });
        });
    }
    loadSubtitles() {
        let subtitlePromises = [];
        for (const [fileIndex, f] of this.protocol.files.entries()) {
            if (supportedSubtitleExtension(f.name)) {
                subtitlePromises.push(this.loadSubtitle(fileIndex).then(() => this.analytics.subtitle()));
            }
        }
        return Promise.all(subtitlePromises);
    }
    updateContext() {
        this.context.commit('player/setCurrentTorrent', this, { root: true });
        this.context.commit('player/setCurrentFiles', this.protocol.files, { root: true });
        this.context.commit('player/setNumPeers', this.protocol.numPeers, { root: true });
        this.context.commit('player/setName', this.protocol.name, { root: true });
        this.context.commit('player/setBufferCounter', this.context.rootState.player.bufferCounter + 1, { root: true });
    }
    destroy(callback) {
        this.removeListeners();
        this.context.commit('player/resetState', null, { root: true });
        this.protocol.destroy(callback);
        this.analytics.submit();
    }

    removeListeners() {
        this.protocol.removeListener('ready', this.onTorrentReady);
        this.protocol.removeListener('nopeers', this.onTorrentNoPeers);
        this.protocol.removeListener('wire', this.onTorrentWire);
        this.protocol.removeListener('metadata', this.onTorrentMetadata);
        this.protocol.removeListener('download', this.onTorrentDownload);
        this.protocol.removeListener('done', this.onTorrentDone);
        this.protocol.removeListener('error', this.onTorrentError);
        this.protocol.removeListener('peerConnect', this.onTorrentPeerConnect);
        this.protocol.removeListener('peerCreate', this.onTorrentPeerCreate);
        this.protocol.removeListener('peerDestroy', this.onTorrentPeerDestroy);
        this.protocol.removeListener('discoveryStarted', this.onTorrentDiscoveryStarted);
    }
}
