import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import { $Logger, LOG_LEVELS, ConsoleSink, IDBSink } from './services';

import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler.js';

import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

import { isMobile } from '@/utils/agent';
import constants from '@/utils/constants';
import { BtButton, BtCheckbox, BtSelect, SVGIcon, toast } from '@/components/shared';

import '@/stylesheets/styles.scss';
import '@/stylesheets/tooltip.scss';

const app = createApp(App);

app.use(FloatingVue, {
    themes: {
        'header-dropdown': {
            $extend: 'dropdown',
            $resetCss: true,
        },
    },
});

// Internationalization
const i18n = createI18n({
    locale: localStorage.getItem(constants['SELECTED_LANGUAGE']) || 'en',
    messages: {
        en: require(`@/localization/lang/en.json`),
        ru: require(`@/localization/lang/ru.json`),
    },
    fallbackLocale: 'en',
    legacy: false,
});

// Components
app.component('SvgIcon', SVGIcon);
app.component('BtSelect', BtSelect);
app.component('BtCheckbox', BtCheckbox);
app.component('BtButton', BtButton);

app.use(i18n);
app.use(router);
app.use(store);
app.use(toast);

// Logger service
app.config.globalProperties.$Logger = $Logger;
store
    .dispatch('preferences/initializeConsoleSink')
    .then((_) => {
        if (store.getters['preferences/localLoggingEnabled']) {
            return store.dispatch('preferences/initializeIDBSink');
        }
    })
    .finally(() => app.mount('#app'));
