<script>
import { mapGetters } from 'vuex';

export default {
    name: 'DebugPanel',
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters('product', ['debugMode']),
        ...mapGetters(['torrentChunkArray', 'torrentChunkInfo', 'peerChunks', 'peerData']),
        torrentChunkArrayWidth() {
            return `${100.0 / this.torrentChunkInfo.numOfChunks}%`;
        },
        torrentChunkProgressBar() {
            let tmpArr = new Array(this.torrentChunkInfo.numOfChunks);
            this.torrentChunkArray.forEach((x, i) => {
                tmpArr[i] = x;
            });
            return tmpArr;
        },
    },
    mounted() {
        setInterval(() => {
            this.$forceUpdate();
        }, 1000);
    },
    methods: {},
};
</script>

<template>
    <div class="debug-panel" :style="{ display: debugMode ? 'block' : 'none' }">
        <div>
            <b>Torrent Chunk Progress Bar</b>
        </div>
        <div class="torrent-chunk-array-wrap">
            <div class="torrent-chunk-array">
                <div
                    v-for="(chunkExists, i) in torrentChunkProgressBar"
                    :key="`chunk-${i}}`"
                    :class="[{ filled: chunkExists }, `chunk-${i}`]"
                    :style="{ width: torrentChunkArrayWidth }"
                >
                    <div class="chunk-tooltip">
                        {{ i }}
                    </div>
                </div>
            </div>
        </div>
        <table class="torrent-peer-array">
            <tr>
                <th>peerId</th>
                <th>remote Addr</th>
                <th>dl speed</th>
                <th>isSeeder</th>
            </tr>
            <template v-for="(peerData, peerId) in this.peerData" :key="`${peerId}`">
                <tr class="peer-meta">
                    <td>
                        {{ peerId }}
                    </td>
                    <td>
                        {{ peerData.remoteAddr }}
                    </td>
                    <td>
                        {{ peerData.downloadSpeed }}
                    </td>
                    <td>
                        {{ peerData.isSeeder }}
                    </td>
                </tr>
                <tr class="peer-meta-2">
                    <td class="peer-status" :class="peerData.status" colspan="4">
                        [{{ peerData.type }} - {{ peerData.status }}]
                        <span v-if="peerData.status === 'connected'">
                            - elapsed time since last piece: {{ Date.now() - peerData.lastPieceTime }} ms
                        </span>
                        <span v-else-if="peerData.status === 'connecting'">
                            - elapsed time since peer init: {{ Date.now() - peerData.initTime }} ms
                        </span>
                        <span v-else-if="peerData.status === 'destroyed'"> - {{ peerData.errMessage }} </span>
                    </td>
                </tr>
                <tr class="peer-chunks">
                    <td class="torrent-chunk-array-wrap" colspan="4">
                        <div class="torrent-chunk-array">
                            <div
                                v-for="(chunkExists, i) in peerChunks[peerId]"
                                :key="`chunk-${i}}`"
                                :class="[{ filled: chunkExists }, `chunk-${i}`]"
                                :style="{ width: torrentChunkArrayWidth }"
                            >
                                <div class="chunk-tooltip">
                                    {{ i }}
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </table>

        <table class="file-table">
            <tr>
                <th>file name</th>
                <th>start piece</th>
                <th>end piece</th>
            </tr>
            <tr v-for="(file, i) in torrentChunkInfo.files" :key="`file-chunk-info-${i}`">
                <td>
                    {{ file.name }}
                </td>
                <td>
                    {{ file.startPiece }}
                </td>
                <td>
                    {{ file.endPiece }}
                </td>
            </tr>
        </table>
        <br />
    </div>
</template>

<style lang="scss" scoped>
.debug-panel {
    margin: 0 0 24px 0;

    .torrent-chunk-array-wrap {
        width: 100%;
        padding: 16px;
    }
    .torrent-chunk-array {
        height: 10px;
        position: relative;
        display: flex;
        width: 100%;
        justify-content: center;

        div {
            height: 100%;
            background: $neutral-medium-dark;
        }
        .filled {
            background: $brand-primary-main;
        }
        .chunk-tooltip {
            position: absolute;
            display: none;
            top: -15px;
        }

        :hover {
            .chunk-tooltip {
                display: block;
            }
        }
    }
    .torrent-peer-array {
        .peer-status {
            &.connecting {
            }
            &.connected {
                background: rgba(82, 169, 17, 0.2);
            }
            &.destroyed {
                background: rgba(255, 160, 122, 0.2);
            }
        }
    }
    table {
        width: 100%;
        text-align: left;

        &.file-table {
            tr:nth-child(even) {
                background: $neutral-medium-dark;
            }
        }
    }
}
</style>
