<script>
// This component is to used to highlight a specific element by dimming the rest of the
// page around the element. We can't just add the css onto the element itself becuase the
// outline will often not cover the entire page depending on how z-indexes are setup.
// Usally used with a tooltip/popup slot element.
export default {
    name: 'OnboardOverlay',
    components: {
        // Lightbox,
    },
    props: {
        target: {
            type: String,
            required: true,
        },
        offset: {
            type: Number,
            required: true,
        },
        updateInterval: {
            type: Number, //milliseconds
            default: null,
        },
    },
    data() {
        return {
            element: null,
            boundingRect: {},
            updateTimer: null,
        };
    },
    computed: {
        styles() {
            return {
                height: `${this.boundingRect.height}px`,
                width: `${this.boundingRect.width}px`,
                left: `${this.boundingRect.left}px`,
                top: `${this.boundingRect.top}px`,
                'outline-offset': `${this.offset}px`,
            };
        },
    },
    mounted() {
        this.updatePosition();

        // TODO: This is not an ideal solution so update if possible
        // The updateInterval is for cases where the parent container may change or move around.
        // We can detect a resize in the parent container but it is difficult to detect other
        // changes so we update on an interval.
        // Example: MediaElement has a Speed onboarding popup. Its position can shift when the
        // banner ad comes in and also its position can change (without the element actually
        // resizing) when the page is resized.
        // If updateInterval is not specified, we will update on a timer until we can get the
        // position of the specified element that we need to draw a box around. We use an interval
        // because we are not sure when this element is available in the dom. Tried using
        // events such as window load event but it was inconsistent
        this.updateTimer = setInterval(() => {
            this.updatePosition();
        }, this.updateInterval || 100);
    },
    beforeUnmount() {
        clearInterval(this.updateTimer);
    },
    methods: {
        updatePosition() {
            this.element = document.querySelector(this.target);
            if (this.element) {
                this.boundingRect = this.element.getBoundingClientRect();
                // Found the position of the element, we can stop updating the position
                if (!this.updateInterval) {
                    clearInterval(this.updateTimer);
                }
            }
        },
    },
};
</script>

<template>
    <Teleport to="body">
        <div v-if="element" class="onboard-overlay" :style="styles"></div>
    </Teleport>
</template>

<style lang="scss">
.onboard-overlay {
    position: fixed;
    outline: 9999px solid rgba(0, 0, 0, 0.65);
    border-radius: 20px;
    pointer-events: none;
}
</style>
