import Bowser from 'bowser';

export const FS_EVENTS = ['webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange', 'fullscreenchange'];

export const browserName = Bowser.getParser(window.navigator.userAgent)
    .getBrowserName()
    .toLowerCase()
    .replace(/ /g, '_');
export const isChrome = browserName === 'chrome';
export const isSafari = browserName === 'safari';
export const isFirefox = browserName === 'firefox';
export const isIE = browserName === 'internet_explorer';
export const isEdge = browserName === 'microsoft_edge';
export const isIEOrEdge = browserName === 'microsoft_edge' || browserName === 'internet_explorer';

const ua = window.navigator.userAgent.toLowerCase();
export const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(ua) ||
    (/Mac/i.test(ua) && 'ontouchend' in document);

export const takeVideoScreenshot = (videoPlayer) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoPlayer, 0, 0);
    let img = canvas.toDataURL('image/jpeg', 1.0);
    canvas.remove();
    return img;
};

export const copyToClipboard = (value) => {
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(value);
    }
    //TODO: verify browser compatibility of execCommand
    const ta = document.createElement('textarea');
    ta.value = value;
    ta.setAttribute('readonly', '');
    ta.style.position = 'absolute';
    ta.style.left = '-9999px';
    document.body.appendChild(ta);
    ta.select();
    document.execCommand('copy');
    document.body.removeChild(ta);
};

export const isInput = (target) => {
    return target.tagName.toLowerCase() === 'input' || target.tagName.toLowerCase() === 'textarea';
};
