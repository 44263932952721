<script>
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
import { SearchAnalytics } from '@/services/analytics';
import SearchMixin from '@/mixins/search';
import { parsedTorrentFailedMessage, isMagnet, isInfoHash, isUrl, parseTorrents } from '@/utils/torrent';

const getFileFromUrl = async (url, fileType) => {
    const response = await fetch(url);
    if (!response.ok) throw response; // Short-circuit if response was not successful
    const blobFromUrl = await response.blob();
    return new File([blobFromUrl], 'file.torrent', { type: fileType }); // TODO: Use config value
};

export default {
    name: 'SearchControlNav',
    mixins: [SearchMixin],
    props: {
        inputmode: {
            type: String,
            default: 'text',
        },
    },
    data() {
        return {
            hover: false,
            isSearching: false,
        };
    },
    computed: {
        isActive() {
            return this.focused;
        },
        name() {
            return this.$route.meta.ga.name;
        },
        isPlayer() {
            return this.$route.name === 'player' || this.$route.name === 'playerV2';
        },
    },
    mounted() {
        if (!this.isPlayer) {
            this.$refs.searchInput.focus();
        }
    },
    methods: {
        setHover(value) {
            this.hover = value;
        },
        doSearch() {
            if (this.isSearching) {
                return;
            }
            let term = this.value;
            if (!term) {
                this.$toast?.error('Invalid search input!');
                return;
            }
            const type = isMagnet(term)
                ? 'magnet_link'
                : isInfoHash(term)
                ? 'infohash'
                : isUrl(term)
                ? 'url'
                : 'external';

            if (type === 'external') {
                const redirectUrl = `./search_redirect.html`;
                const searchString = term.match(new RegExp('\\btorrent\\b', 'i')) ? term : `${term} torrent`;
                window.open(`${redirectUrl}?query=${searchString}`);
                this.updateValue('');
                SearchAnalytics.search(this.name, type);
                return;
            }

            this.isSearching = true;
            let torrentItem = Promise.resolve(term);
            if (type === 'url') {
                torrentItem = getFileFromUrl(term, 'application/x-bittorrent');
            }
            torrentItem
                .then((t) => parseTorrents(t))
                .then((magnetLink) => {
                    this.$router
                        .push({
                            path: `/player`,
                            query: {
                                m: btoa(magnetLink),
                            },
                        })
                        .then((failure) => {
                            if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
                                this.$toast.warn('Torrent already loaded!');
                                return;
                            }
                            SearchAnalytics.search(this.name, type);
                            // GoogleAnalytics.addTorrent(type);
                        });
                })
                .catch((err) => {
                    this.$toast?.error(parsedTorrentFailedMessage(err.message));
                    console.error(err);
                    SearchAnalytics.search(this.name, type, false);
                    // GoogleAnalytics.addTorrentError(type);
                })
                .finally((_) => {
                    this.updateValue('');
                    this.isSearching = false;
                });
        },
    },
};
</script>

<template>
    <div
        tabindex="-1"
        class="search-control-nav-main"
        @keyup.enter="doSearch('enterKey')"
        @keyup.esc="hideHistory"
        @mouseover="setHover(true)"
        @mouseleave="setHover(false)"
    >
        <div :class="{ active: isActive, 'history-active': showHistoryDropdown }" class="search-control">
            <div :class="`search-control-input-container ${isPlayer} ${showErrorMessage ? 'has-request-error' : ''}`">
                <input
                    ref="searchInput"
                    :value="value"
                    :class="isPlayer"
                    type="search"
                    class="search-control-input"
                    autocomplete="off"
                    :spellcheck="false"
                    :placeholder="$t('search-control-nav.placeholder')"
                    :inputmode="inputmode"
                    @input="updateValue($event.target.value)"
                    @paste.stop
                    @keydown.down.prevent="nextHistory"
                    @keydown.up.prevent="previousHistory"
                    @keydown.stop
                    @focus="handleFocus(name)"
                    @blur="handleBlur"
                    @click="$emit('onInputClick')"
                /><SvgIcon
                    v-show="value !== ''"
                    icon="close"
                    class="icon icon-close"
                    :has-fill="true"
                    @click="updateValue('')"
                />
                <button
                    class="search-control-btn"
                    :disabled="isSearching"
                    :class="{ active: isActive }"
                    @click="doSearch('button')"
                >
                    {{ $t('search-control-nav.Go') }}
                </button>
            </div>
            <ul v-show="showHistoryDropdown" class="history-dropdown-menu">
                <div class="search-history-title">
                    <span class="title-text">{{ $t('Search History') }}</span>
                    <span class="clear-link" @mousedown.stop.prevent="clearAllSearchHistory(name)">{{
                        $t('Clear')
                    }}</span>
                </div>
                <li
                    v-for="(entry, index) in matched"
                    :key="index"
                    :class="{ selected: isSelected(index) }"
                    @mouseenter="setHistoryIndex(index)"
                >
                    <a class="history-dropdown-element" @mousedown.stop.prevent="select(name, entry)">
                        <span class="entry-text">{{ entry }}</span>
                    </a>
                </li>
            </ul>
            <div v-if="showErrorMessage" class="message-dropdown-menu request-error-message">
                <SvgIcon icon="warning-amber" class="warning-icon" :has-fill="true" style="fill: #ff6e6e" />
                <p>
                    {{ $t('request-error-message') }}
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.search-control-nav-main {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    .search-control {
        display: flex;
        flex-direction: row;
        height: 38px;
        width: 100%;
        overflow: hidden;
        .icon-close {
            fill: $neutral-white;
            padding-right: 9px;
            padding-left: 9px;
            cursor: pointer;
        }
        &.active {
            .search-control-input-container {
                border: 1px solid $brand-primary-main;
                border-right: none;
                .search-control-input {
                    padding-left: 18px;
                }
            }
        }
        .search-control-input-container {
            display: flex;
            align-items: center;
            background-color: #242433;
            border-radius: 40px;
            width: 100%;
            border: 1px solid $neutral-medium;
            border-right: none;
            &.player {
                background-color: $neutral-extra-dark;
            }

            &.has-request-error {
                border-color: #ff6e6e;
            }

            input[type='search']::-webkit-search-decoration,
            input[type='search']::-webkit-search-cancel-button,
            input[type='search']::-webkit-search-results-button,
            input[type='search']::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }
            .search-control-input {
                flex: 1;
                font-size: 16px;
                font-weight: 300;
                height: 100%;
                width: 100%;
                outline: 0;
                line-height: 17px;
                background: transparent;
                color: $neutral-white;
                padding-left: 18px;
                border-radius: 40px 0 0 40px;
                border: none;
                &::-webkit-input-placeholder {
                    /* Chrome */
                    color: $neutral-light-medium;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: $neutral-light-medium;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: $neutral-light-medium;
                }
                &:-moz-placeholder {
                    /* Firefox 4 - 18 */
                    color: $neutral-light-medium;
                }

                @media (min-width: 604px) {
                    font-size: 14px;
                }
            }
            @media screen and (max-width: 500px) {
                .search-control-input::placeholder {
                    font-size: 14px;
                }
            }
            @media screen and (max-width: 400px) {
                .search-control-input::placeholder {
                    font-size: 11px;
                }
            }
            .search-control-btn {
                font-size: 14px;
                font-weight: 700;
                height: 38px;
                border-radius: 0 17px 17px 0;
                color: $neutral-white;
                background-color: $brand-primary-main;
                padding: 0 26px;
                cursor: pointer;
                @media (max-width: 607px) {
                    min-width: 40px;
                    padding: 0 3px;
                    margin: auto;
                    top: -1px;
                    position: relative;
                    border-radius: 0 40px 40px 0;
                    &.active {
                        top: -2px;
                    }
                }
            }
        }
    }
    .message-dropdown-menu,
    .history-dropdown-menu {
        z-index: zindex(popover);
        display: block;
        position: absolute;
        top: 38px;
        overflow: hidden;
        padding: 6px 0;
        font-size: 14px;
        text-align: left;
        list-style: none;
        line-height: 17px;
        font-weight: 400;
        background-color: $neutral-medium-dark;
        border-radius: 4px;
        box-shadow: 3px 3px 4px 0 rgba(51, 51, 51, 0.4);
        letter-spacing: 0.8px;
        width: 100%;
        margin: 4px 0 0 0;
        .selected {
            background-color: rgba($neutral-dark, 0.4);
            color: $neutral-white;
        }
        .search-history-title {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px 14px 10px 16px;
            white-space: nowrap;
            background-color: transparent;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $neutral-medium;
            .title-text {
                flex: 1;
            }
            .clear-link {
                font-size: 14px;
                color: $neutral-medium;
                cursor: pointer;
                text-decoration: underline;
                font-weight: 400;
                line-height: 17px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover {
                    color: $neutral-white;
                }
            }
        }
        .history-dropdown-element {
            display: flex;
            align-items: center;
            cursor: pointer;
            color: $neutral-white;
            padding: 8px 16px;
            white-space: nowrap;
            font-size: 13px;
            .entry-text {
                flex: 1;
                text-overflow: ellipsis;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .message-dropdown-menu.request-error-message {
        padding: 16px 24px 16px 20px;
        display: flex;
        flex-direction: row;
        .warning-icon {
            font-size: 16px;
            margin: 0 4px 0 0;
        }
        p {
            display: block;
            color: #ff6e6e;
            margin: 0;
            font-size: 13px;
        }
    }
}
</style>
