<script>
export default {
    name: 'DragDropOverlay',
};
</script>

<template>
    <div id="drag-drop-overlay">
        <div id="drag-drop-border">
            <div class="icon">
                <SvgIcon icon="drag-drop" :has-fill="true" />
                {{ $t('dragDropOverlay-0') }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
#drag-drop-overlay {
    @include overlay-mask(rgba(0, 0, 0, 0.75));
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: zindex(dragdrop);
    color: $neutral-white;
    fill: $neutral-white;
    padding: 2rem;
}

#drag-drop-border {
    outline: 2.5px dashed $neutral-white;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    .icon {
        font-size: 2rem;
        margin: auto;
    }
}
</style>
