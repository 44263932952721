<template>
    <div class="sharing-life-style">
        <div class="image-holder">
            <img class="image" :src="SharingLifeStyleImage" />
        </div>
        <div class="text-holder">
            <div class="header">{{ $t(rp + 'header') }}</div>
            <div class="body">{{ $t(rp + 'body-1') }}</div>
        </div>
    </div>
</template>

<script>
const RESOURCE_PATH = 'sharing.';
import LifeStyleImage from '@/assets/LifeStyleSharing.png';

export default {
    name: 'SharingLifeStyle',

    data() {
        return {
            rp: RESOURCE_PATH,
            SharingLifeStyleImage: LifeStyleImage,
        };
    },
};
</script>

<style scoped lang="scss">
.sharing-life-style {
    padding: 1.5rem;
    display: grid;
    gap: 50px;

    max-width: 1200px;
    margin: auto;

    .image {
        max-width: 100%;
        max-height: 100%;
    }

    .header {
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 101.52%;
        padding-bottom: 1rem;
    }
    .body {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 17px;
        max-width: 420px;
    }

    @media (min-width: 606px) {
        gap: 70px;
        .header {
            font-size: 3rem;
        }
        .body {
            font-size: 1.25rem;
            line-height: 24.2px;
        }
    }

    @media (min-width: 1230px) {
        grid-template-columns: 1fr 1fr;

        .text-holder {
            margin: auto 0;
        }
    }
}
</style>
