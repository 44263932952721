<script>
export default {
    name: 'Subtitle',
    props: {
        name: {
            type: String,
            required: true,
        },
        isSelected: {
            type: Boolean,
            required: true,
        },
    },
};
</script>
<template>
    <label class="subtitle" :class="{ selected: isSelected }">
        <div class="native-name">{{ name }}</div>
    </label>
</template>
<style lang="scss" scoped>
.subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 4px 15px 4px 10px;
    color: $neutral-light;
    border-left: 5px solid transparent;
    height: 36px;
    width: 100%;
    box-sizing: border-box;
    .native-name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 5px;
    }
    &.selected {
        border-left: 5px solid $primary-green-main !important;
    }
    &:hover {
        background-color: $neutral-extra-dark;
        border-left: 5px solid $neutral-extra-dark;
    }
    &:not(:last-child) {
        border-bottom: solid 1px $neutral-extra-dark;
    }
}
</style>
