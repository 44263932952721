import { $Logger, ConsoleSink, IDBSink, LOG_LEVELS } from '@/services';
const INITIAL_STATE = {
    diagnostics: {
        localLoggingEnabled: null,
        sinks: $Logger.sinks,
    },
    defaultLanguage: null,
};

const state = { ...INITIAL_STATE };

const getters = {
    localLoggingEnabled: ({ diagnostics }) => {
        if (diagnostics.localLoggingEnabled === null) {
            diagnostics.localLoggingEnabled = localStorage.getItem('preferences.diagnostics.localLoggingEnabled');
            if (diagnostics.localLoggingEnabled === null) {
                // runtime default set to 'true'
                diagnostics.localLoggingEnabled = true;
            } else {
                diagnostics.localLoggingEnabled = JSON.parse(diagnostics.localLoggingEnabled);
            }
        }
        return diagnostics.localLoggingEnabled;
    },
    sinks: ({ diagnostics }) => diagnostics.sinks,
    defaultLanguage: ({ defaultLanguage }) => {
        if (defaultLanguage === null) {
            defaultLanguage = localStorage.getItem('preferences.defaultLanguage');
            if (defaultLanguage === null) {
                // runtime default set to 'en'
                defaultLanguage = 'en';
            } else {
                defaultLanguage = JSON.parse(defaultLanguage);
            }
        }
        return defaultLanguage;
    },
};

const mutations = {
    toggleLocalLoggingEnabled(state) {
        state.diagnostics.localLoggingEnabled = !state.diagnostics.localLoggingEnabled;
        localStorage.setItem(
            'preferences.diagnostics.localLoggingEnabled',
            JSON.stringify(state.diagnostics.localLoggingEnabled)
        );
    },
    setDefaultLanguage(state, language) {
        state.defaultLanguage = language;
        localStorage.setItem('preferences.defaultLanguage', JSON.stringify(state.defaultLanguage));
    },
    updateSinks(state) {
        state.diagnostics.sinks = [...$Logger.sinks];
    },
};

const actions = {
    initializeConsoleSink(context) {
        $Logger.addSink(new ConsoleSink(LOG_LEVELS.TRACE));
        context.commit('updateSinks');
    },
    initializeIDBSink(context) {
        const currentId = localStorage.getItem('preferences.diagnostics.currentIDBSink');
        const newId = IDBSink.newId();
        let dbPromise;
        if (currentId === null) {
            localStorage.setItem('preferences.diagnostics.currentIDBSink', newId);
            dbPromise = IDBSink.openDatabase(newId);
        } else {
            dbPromise = IDBSink.openDatabase(currentId)
                // if the existing key doesn't work, use the newId
                .catch((_) => {
                    localStorage.setItem('preferences.diagnostics.currentIDBSink', newId);
                    return IDBSink.openDatabase(newId);
                });
        }
        dbPromise
            .then((db) => new IDBSink(LOG_LEVELS.TRACE, db))
            .then((idbSink) => $Logger.addSink(idbSink))
            .then((_) => context.commit('updateSinks'));
    },
    removeIDBSinks(context) {
        const sinks = [];
        for (let s of $Logger.sinks) {
            if (s instanceof IDBSink) {
                s.close();
            } else {
                sinks.push(s);
            }
        }
        $Logger.sinks = sinks;
        context.commit('updateSinks');
    },
    deleteIDBSink(context, id) {
        $Logger.deleteSink(id);
        context.commit('updateSinks');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
