import { createStore } from 'vuex';
import player from './player';
import product from './product';
import search from './search';
import library from './library';
import preferences from './preferences';

const store = createStore({
    modules: {
        player,
        product,
        search,
        library,
        preferences,
    },
    strict: false,
});

// `store.subscribe` is an abstraction over default `store.subscribeAction` that allows
// listeners to define their action matching predicate (action.type, predicate func over action.payload)
// this imporves performance by avoiding redundant instantiation of `subscribeAction` subscribers that listen to all actions.
// it also improves usability and readability as `subscriberAction` consumers do not have to check against action.type individually
const subscribers = {};
let subscriberId = 0;
store.subscribe = ({ action, predicate, handler }) => {
    const id = subscriberId++;
    subscribers[action] = [{ id, predicate, handler }, ...(subscribers[action] || [])];
    return { action, id };
};
store.unsubscribe = ({ action, id }) => {
    if (action in subscribers) {
        for (let i = 0; i < subscribers[action].length; i++) {
            if (subscribers[action][i].id === id) {
                subscribers[action].splice(i, 1);
            }
        }
    }
};
// only one `subscribeAction` for the entire application
store.subscribeAction((action, state) => {
    if (action.type in subscribers) {
        for (let listeners of subscribers[action.type]) {
            if (!listeners.predicate || listeners.predicate(action.payload)) {
                listeners.handler(action.payload, state);
            }
        }
    }
});

export default store;
