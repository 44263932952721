<script>
export default {
    name: 'LightModal',
    components: {},
    props: {
        showCloseButton: {
            type: Boolean,
            default: false,
        },
        testid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {},
};
</script>

<template>
    <div class="modal background">
        <div class="body">
            <div v-if="showCloseButton" class="close-btn" @click="() => $emit('close')">
                <SvgIcon icon="close" :has-fill="true" />
            </div>
            <div class="header">
                <slot name="header" />
            </div>
            <div class="content">
                <slot name="content" />
            </div>
            <div class="footer">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.modal {
    width: 464px;
    height: 196px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $neutral-white;
    &.background {
        position: fixed;
        z-index: zindex(modal);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($neutral-black, 0.8);
    }

    .body {
        position: relative;
        border-radius: 6px;
        padding: 12px 28px 32px;
        background-color: $neutral-dark;
        width: 100%;
        .close-btn {
            position: absolute;
            top: 20px;
            right: 16px;
            cursor: pointer;
            font-size: 20px;
            fill: $neutral-extra-light;
        }
    }
    .footer {
        text-align: right;
    }
}
</style>
