<script>
export default {
    name: 'CarouselControl',
    props: {
        // Number of pages for the carousel
        numberOfModals: {
            type: Number,
            required: true,
        },
        // Whether to show page numbers or dots
        isNumericIndex: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeModalIndex: 0,
        };
    },
    watch: {
        activeModalIndex(newVal) {
            this.$emit('updateActiveModalIndex', newVal);
        },
    },
    created() {
        this.$emit('updateActiveModalIndex', this.activeModalIndex);
    },
    methods: {
        updateActiveModalIndex(index) {
            this.activeModalIndex = index;
        },
        decreaseActiveModalIndex() {
            this.activeModalIndex = this.activeModalIndex === 0 ? this.numberOfModals - 1 : this.activeModalIndex - 1;
            this.$emit('carouselIndexDecrease');
        },
        increaseActiveModalIndex() {
            this.activeModalIndex = this.activeModalIndex === this.numberOfModals - 1 ? 0 : this.activeModalIndex + 1;
            this.$emit('carouselIndexIncrease');
        },
        firstActiveModalIndex() {
            this.activeModalIndex = 0;
            this.$emit('carouselIndexFirst');
        },
        lastActiveModalIndex() {
            this.activeModalIndex = this.numberOfModals - 1;
            this.$emit('carouselIndexLast');
        },
        resetIndex() {
            this.activeModalIndex = 0;
        },
    },
};
</script>

<template>
    <div class="carousel-control-container">
        <div class="control-body">
            <div class="control-left">
                <button
                    v-if="isNumericIndex"
                    v-tooltip="$t('First')"
                    class="first-index-btn button-icon--secondary"
                    :disabled="activeModalIndex === 0"
                    @click="firstActiveModalIndex"
                >
                    <SvgIcon icon="skip-previous" :has-fill="true" />
                </button>
                <button
                    v-else
                    v-tooltip="$t('Previous')"
                    class="decrease-index-btn button-icon--secondary"
                    @click="decreaseActiveModalIndex"
                >
                    <SvgIcon icon="chevron-left" :has-fill="true" />
                </button>
            </div>
            <div class="carousel-indicators">
                <div v-if="isNumericIndex" class="numeric-indicator-container">
                    <button
                        v-tooltip="$t('Previous')"
                        class="decrease-index-btn button-icon--secondary"
                        :disabled="activeModalIndex === 0"
                        @click="decreaseActiveModalIndex"
                    >
                        <SvgIcon icon="chevron-left" :has-fill="true" />
                    </button>
                    <div class="numeric-indicator">{{ activeModalIndex + 1 }} of {{ numberOfModals }}</div>
                    <button
                        v-tooltip="$t('Next')"
                        class="increase-index-btn button-icon--secondary"
                        :disabled="activeModalIndex === numberOfModals - 1"
                        @click="increaseActiveModalIndex"
                    >
                        <SvgIcon icon="chevron-right" :has-fill="true" />
                    </button>
                </div>
                <ol v-else>
                    <li
                        v-for="(modal, index) in numberOfModals"
                        :key="modal"
                        class="carousel-indicator"
                        :class="{ active: index === activeModalIndex }"
                        @click="updateActiveModalIndex(index)"
                    />
                </ol>
            </div>
            <div class="control-right">
                <button
                    v-if="isNumericIndex"
                    v-tooltip="$t('Last')"
                    class="last-index-btn button-icon--secondary"
                    :disabled="activeModalIndex === numberOfModals - 1"
                    @click="lastActiveModalIndex"
                >
                    <SvgIcon icon="skip-next" :has-fill="true" />
                </button>
                <button
                    v-else
                    v-tooltip="$t('Next')"
                    class="increase-index-btn button-icon--secondary"
                    @click="increaseActiveModalIndex"
                >
                    <SvgIcon icon="chevron-right" :has-fill="true" />
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.carousel-control-container {
    position: absolute;
    bottom: -100px;
    display: flex;
    justify-content: center;
    width: calc(100% - 60px);

    .control-body {
        height: 40px;
        background-color: $neutral-white;
        border-radius: 20px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.33);
        .button-icon--secondary {
            font-size: 26px;
            padding: 0;
            color: $brand-primary-main;
            fill: $brand-primary-main;
            &:hover {
                color: $brand-primary-dark;
                fill: $brand-primary-dark;
            }
            &:disabled {
                color: $neutral-light-medium;
                fill: $neutral-light-medium;
                border: 1px solid transparent;
            }
        }
        .control-left,
        .control-right {
            display: flex;
        }
        .control-left {
            border-right: 1px solid $neutral-light-medium;
            padding-right: 3px;
            padding-left: 3px;
        }
        .control-right {
            border-left: 1px solid $neutral-light-medium;
            padding-left: 3px;
            padding-right: 3px;
        }
        .carousel-indicators {
            padding: 0 14px;
            ol {
                list-style-type: none;
                padding: 0;
                display: flex;
                justify-content: center;
                align-content: center;
            }
            .numeric-indicator-container {
                display: flex;
                align-items: center;
                justify-content: center;
                .numeric-indicator {
                    color: $brand-primary-main;
                    padding: 0 10px;
                    min-width: 80px;
                    display: flex;
                    justify-content: center;
                    font-size: 16px;
                }
            }
            .carousel-indicator {
                height: 16px;
                width: 16px;
                border-radius: 16px;
                border: 1px solid $brand-primary-main;
                background-color: transparent;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                &.active {
                    background-color: $brand-primary-main;
                }
                &:not(:last-child) {
                    margin-right: 10px;
                }
                &:hover {
                    cursor: pointer;
                    border: 2px solid $brand-primary-main;
                }
            }
        }
    }
}
</style>
