<script>
import { mapGetters } from 'vuex';
import LightModal from './LightModal';
import { SessionAnalytics } from '../../services/analytics';

export default {
    name: 'PlaybackInterruptModal',
    components: {
        LightModal,
    },
    computed: {
        ...mapGetters('player', ['name']),
        ...mapGetters('product', ['interruptSignal']),
    },
    mounted() {
        SessionAnalytics.engagementPing('interrupt_modal', 'open');
    },
    unmounted() {
        SessionAnalytics.engagementPing('interrupt_modal', 'close');
    },
};
</script>

<template>
    <LightModal testid="playback-interrupt-modal" :show-close-button="true" @close="() => $emit('update', 'cancel')">
        <template #header>
            <div class="header"></div>
        </template>
        <template #content>
            <div class="content">
                <p v-if="interruptSignal === 'new'">{{ $t('playback-interrupt-modal.header.new') }} "{{ name }}" ?</p>
                <p v-if="interruptSignal === 'leaving'">{{ $t('playback-interrupt-modal.header.leave') }}</p>
            </div>
        </template>
        <template #footer>
            <div class="buttons">
                <BtButton
                    v-if="interruptSignal === 'new'"
                    class="replace-button"
                    :data-gtm-event="'engagement_player_interrupt_modal_replace'"
                    :style="{ textTransform: $i18n.locale === `ru` ? `inherit` : 'capitalize' }"
                    @click="$emit('update', 'replace')"
                >
                    {{ $t('playback-interrupt-modal.replace') }}
                </BtButton>
                <BtButton
                    v-if="interruptSignal === 'leaving'"
                    class="replace-button"
                    :data-gtm-event="'engagement_player_interrupt_modal_replace'"
                    :style="{ textTransform: $i18n.locale === `ru` ? `inherit` : 'capitalize' }"
                    @click="$emit('update', 'replace')"
                >
                    {{ $t('playback-interrupt-modal.close') }}
                </BtButton>

                <BtButton
                    class="open-new-tab-button"
                    :data-gtm-event="'engagement_player_interrupt_modal_new_tab'"
                    :style="{ textTransform: $i18n.locale === `ru` ? `inherit` : 'capitalize' }"
                    @click="$emit('update', 'new')"
                >
                    {{ $t('playback-interrupt-modal.open') }}
                </BtButton>
            </div>
        </template>
    </LightModal>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
:deep(.body) {
    margin-right: 16px;
    margin-left: 16px;
    max-width: 500px;
}
.header {
    width: 100%;
    position: relative;
    .close-btn {
        position: absolute;
        top: 10px;
        right: 0;
        cursor: pointer;
        font-size: 20px;
        fill: $neutral-extra-light;
    }
}
.content {
    width: 100%;
    line-height: 1.56;
    color: $neutral-white;
    font-weight: 16px;
    padding-right: 10px;
}
.buttons {
    margin-top: 36px;
    display: flex;
    gap: 14px 24px;
    @media screen and (max-width: 617px) {
        flex-wrap: wrap;
        justify-content: center;
    }
    .replace-button {
        font-size: 14px;
        color: $brand-primary-light !important;
        border: solid 1px $brand-primary-light !important;
        background-color: $neutral-dark !important;
        &:hover {
            color: $brand-primary-dark;
            fill: $brand-primary-dark;
        }
        &:disabled {
            color: $neutral-light-medium;
            fill: $neutral-light-medium;
            border: 1px solid transparent;
        }
    }
    .open-new-tab-button {
        min-width: 141px;
        font-size: 14px;
        color: #fff !important;
        border: solid 1px $brand-primary-main !important;
        background-color: $brand-primary-main !important;
        &:hover {
            color: $brand-primary-dark;
            fill: $brand-primary-dark;
        }
        &:disabled {
            color: $neutral-light-medium;
            fill: $neutral-light-medium;
            border: 1px solid transparent;
        }
    }
}
</style>
