<script>
import _ from 'lodash';
import Subtitle from './Subtitle';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'SubtitleMenu',
    components: {
        Subtitle,
    },
    props: {
        showMenu: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters('player', ['currentFiles', 'currentSubtitle', 'subtitles']),
        subtitleIndices() {
            let ret = [];
            let keys = this.subtitles.keys();
            while (true) {
                let { value, done } = keys.next();
                if (done) {
                    return ret;
                }
                if (this.currentFiles[value]) {
                    ret.push(value);
                }
            }
        },
    },
    methods: {
        ...mapMutations('player', ['setCurrentSubtitle']),
        toggleMenu(state = null) {
            this.$emit('toggleMenu', state);
        },
        selectSubtitleTrack(fileIndex) {
            this.toggleMenu(false);
            if (this.currentSubtitle === fileIndex) {
                this.setCurrentSubtitle(null);
                return;
            }
            this.setCurrentSubtitle(fileIndex);
        },
        isSelected(fileIndex) {
            return fileIndex === this.currentSubtitle;
        },
    },
};
</script>
<template>
    <div
        v-show="subtitleIndices.length > 0"
        id="subtitle-menu-container"
        class="control"
        tabindex="1"
        @keyup.esc="toggleMenu(false)"
    >
        <div
            id="subtitle-menu-btn"
            v-tooltip="{ content: $t('Subtitles'), container: '#media-player' }"
            :class="{ active: showMenu, selected: currentSubtitle !== null }"
            @click="toggleMenu()"
        >
            <svg-icon icon="closed-caption" class="bt-icons" :has-fill="true" />
        </div>
        <div v-show="showMenu" id="subtitle-menu">
            <div>
                <div id="subtitle-scroll-container">
                    <li
                        id="subtitle-off"
                        :class="{ selected: currentSubtitle === null }"
                        @click="selectSubtitleTrack(null)"
                    >
                        OFF
                    </li>
                    <Subtitle
                        v-for="fileIndex of subtitleIndices"
                        :key="fileIndex"
                        :name="currentFiles[fileIndex].name"
                        :is-selected="isSelected(fileIndex)"
                        @mousedown.stop.prevent="selectSubtitleTrack(fileIndex)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
#subtitle-menu-container {
    position: relative;
    display: flex;
    flex-direction: column;
    #subtitle-menu-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.selected {
            &::after {
                content: '';
                height: 2px;
                border-radius: 2px;
                width: 20px;
                bottom: 3px;
                background-color: $primary-green-main;
                transition: left 0.25s cubic-bezier(0, 0, 0.2, 1), width 0.25s cubic-bezier(0, 0, 0.2, 1);
            }
        }
        &::after {
            content: '';
            height: 2px;
            border-radius: 2px;
            width: 0;
            bottom: 3px;
            background-color: $primary-green-main;
            transition: left 0.25s cubic-bezier(0.4, 0, 1, 1), width 0.25s cubic-bezier(0.4, 0, 1, 1);
        }
    }
}
#subtitle-menu {
    position: absolute;
    top: auto;
    bottom: calc(100% + 20px);
    right: 0;
    left: auto;
    width: 360px;
    padding: 5px 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
    background-color: rgba(28, 28, 28, 0.9);
    border-radius: 4px;
}

#subtitle-scroll-container {
    max-height: 210px;
    width: 360px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #343448;
        border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
        background: #c7cdd2;
        border-radius: 20px;
    }
}

#subtitle-off {
    display: flex;
    align-items: center;
    color: $neutral-light-medium;
    padding: 4px 15px 4px 10px;
    font-size: 16px;
    border-bottom: solid 1px $neutral-extra-dark;
    border-left: 5px solid rgba(0, 0, 0, 0);
    height: 36px;
    width: 100%;
    box-sizing: border-box;
    &.selected {
        border-left: 5px solid $primary-green-main !important;
    }
    &:hover {
        background-color: $neutral-extra-dark;
    }
}
</style>
