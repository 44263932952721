<script>
import { PopperWrapper } from 'floating-vue';

export default {
    ...PopperWrapper,
    name: 'HeaderDropdown',
    vPopperTheme: 'header-dropdown',
};
</script>

<style>
.v-popper__popper.v-popper--theme-header-dropdown {
    left: -60px;
}
.v-popper--theme-header-dropdown {
    .v-popper__arrow-container {
        display: none;
    }

    .v-popper__inner {
        background-color: #191924;
        border-radius: 4px;
        border: rgba(199, 199, 199, 0.15) 1px solid;
        ul {
            display: block;
            float: left;
            min-width: 160px;
            width: 100%;
            overflow: hidden;
            padding: 8px 0px;
            margin: 0;
            font-size: 12px;
            text-align: left;
            list-style: none;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.35);
            border-radius: 4px;
            letter-spacing: 0.8px;
            li {
                display: flex;
                align-items: center;
                cursor: pointer;
                color: #e0e5e9;
                padding: 4px 20px;
                white-space: nowrap;
                height: 30px;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.08);
                    color: #fff;
                }
                &.separator {
                    margin: 5px 0;
                    height: 1px;
                    padding: 0;
                    background-color: rgba(199, 199, 199, 0.15);
                }
            }
        }
    }
}
</style>
