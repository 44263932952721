export class MTimeRanges {
    constructor(ranges) {
        this.ranges = ranges;
    }
    get length() {
        return this.ranges.length;
    }
    start(index) {
        if (index >= this.ranges.length || index < 0) {
            throw Error('Invalid index');
        }
        return this.ranges[index][0];
    }

    end(index) {
        if (index >= this.ranges.length || index < 0) {
            throw Error('Invalid index');
        }
        return this.ranges[index][1];
    }
}
