<script>
import { mapGetters, mapMutations } from 'vuex';
import StatusCard, { CONNECTION_STATUS } from './StatusCard';

export default {
    name: 'StatusDashboard',
    components: {
        StatusCard,
    },
    props: {
        mediaPlayerLoaded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            expandedStep: null,
        };
    },
    computed: {
        ...mapGetters('player', ['currentTorrent', 'loading', 'loadingMetadata']),
        ...mapGetters('library', ['peersMap', 'trackersMap']),
        peers() {
            let peers = [];
            for (let [name, wire] of this.peersMap(this.currentTorrent.infoHash).entries()) {
                peers.push({
                    name,
                    wire,
                    status:
                        wire === null
                            ? CONNECTION_STATUS.CONNECTING
                            : wire === undefined
                            ? CONNECTION_STATUS.ERROR
                            : CONNECTION_STATUS.CONNECTED,
                });
            }
            return peers;
        },
        trackers() {
            let trackers = [];
            for (let [name, socket] of this.trackersMap(this.currentTorrent.infoHash).entries()) {
                trackers.push({
                    name,
                    socket,
                    status:
                        socket === null
                            ? CONNECTION_STATUS.CONNECTING
                            : socket === undefined
                            ? CONNECTION_STATUS.ERROR
                            : CONNECTION_STATUS.CONNECTED,
                });
            }
            return trackers;
        },
        metadata() {
            return [
                {
                    name: this.$t('statusDashboard.metadataTask'),
                    status: this.loadingMetadata ? CONNECTION_STATUS.CONNECTING : CONNECTION_STATUS.CONNECTED,
                },
                {
                    name: this.$t('statusDashboard.framesTask'),
                    status: this.loading ? CONNECTION_STATUS.CONNECTING : CONNECTION_STATUS.CONNECTED,
                },
            ];
        },
    },
    methods: {
        toggleDashboard(state = null) {
            this.$emit('toggleDashboard', state);
        },
    },
};
</script>

<template>
    <div id="status-dashboard">
        <div class="dashboard-inner-container">
            <StatusCard
                :key="`step-trackers`"
                :data="trackers"
                :title="$t('statusDashboard.trackersStep')"
                :expanded="expandedStep === 0"
                @click="expandedStep = expandedStep !== 0 ? 0 : null"
            />
            <StatusCard
                :key="`step-peers`"
                :data="peers"
                :title="$t('statusDashboard.peersStep')"
                :expanded="expandedStep === 1"
                @click="expandedStep = expandedStep !== 1 ? 1 : null"
            />
            <StatusCard
                :key="`step-metadata`"
                :data="metadata"
                :title="$t('statusDashboard.contentsStep')"
                :expanded="expandedStep === 2"
                :minimized="expandedStep !== 2"
                @click="expandedStep = expandedStep !== 2 ? 2 : null"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
#status-dashboard {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 20px 20px 85px 20px;
    z-index: zindex(status-dashboard);
    .dashboard-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        height: 100%;
        width: 100%;
        border-radius: 3px;
        overflow: hidden;
        @media (max-width: 880px) {
            gap: 10px;
        }
        @media (max-width: 700px) {
            gap: 0;
        }
    }
}
</style>
