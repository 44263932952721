<template>
    <div>
        <svg
            v-for="index in 5"
            :key="'rating ' + index"
            class="star-selector-image"
            :fill="getFillColor(index)"
            @click="
                starValue = index;
                $emit('update:modelValue', starValue);
            "
            @mouseover="hoverVal = index"
            @mouseout="hoverVal = -1"
        >
            <use xlink:href="@/assets/star-rating.svg#star-rating-svg"></use>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'StarSelector',
    props: {
        modelValue: {
            type: Number,
            default: 0,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            starValue: 0,
            hoverVal: 0,
        };
    },
    created() {
        this.starValue = this.modelValue;
    },
    methods: {
        getFillColor(index) {
            if (this.starValue >= index) {
                return '#FFD300';
            } else if (this.hoverVal >= index) {
                return '#f3d06f';
            }

            return '#E0E5E9';
        },
    },
};
</script>

<style scoped>
.star-selector-image {
    width: 32px;
    height: 32px;
    margin: 0 5px;
    cursor: pointer;
}
</style>
