<template>
    <div class="collapse-content">
        <div :class="`content-title ${toggleContent ? '' : 'collapsed'}`" @click="toggleContent = !toggleContent">
            <h2 :class="`title`">
                {{ contentTitle }}
            </h2>
        </div>
        <div :class="`content-description collapse ${toggleContent ? 'show' : ''}`">
            <p>{{ contentDescription }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        contentTitle: {
            type: String,
            default: '',
        },
        contentDescription: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            toggleContent: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.collapse-content {
    margin: 1em 0;
    background: #343448;
    border-radius: 16px;
}

h2 {
    cursor: pointer;
    padding: 0.5em;
    font-weight: bold;

    @media (max-width: 604px) {
        font-size: 1.125rem;
    }
}
.collapse {
    display: block;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    &.show {
        max-height: 99em;
        transition: max-height 0.5s ease-in-out;
    }
}

.content-title {
    background: #191924;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    border-radius: 16px;
    display: flex;
    width: 100%;

    @media (max-width: 604px) {
        border-radius: 10px;
        font-size: 18px;
    }

    &::before {
        color: #52a911;
        font-family: monospace;
        content: '- ';
        position: relative;
        text-align: center;
        flex-shrink: 0;
        padding: 9px 0 0 0.5em;
    }

    &.collapsed::before {
        content: '+';
    }
}

.content-description p {
    background: #343448;
    margin: 0;
    padding: 1em 2.5em;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    border-radius: 16px;

    @media (max-width: 604px) {
        border-radius: 10px;
        font-size: 14px;
        line-height: 20px;
    }
}
</style>
