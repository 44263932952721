<script>
import { Modal, NotificationToolTip } from '@/components/shared';
import { copyToClipboard } from '@/utils/agent';
import { parseRoute } from '@/utils';

export default {
    name: 'TorrentShareModal',
    components: {
        NotificationToolTip,
        Modal,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        magnetLink: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            message: `${this.$t('torrent-share-modal.content-1')}\n\n${this.$t(
                'torrent-share-modal.content-2'
            )}\n\n${this.$t('torrent-share-modal.enjoy')}`,
            showCopyLinkNotification: false,
            showCopyMessageAndLinkNotification: false,
            notificationDisplayTime: 2000,
        };
    },
    computed: {
        shareMessage() {
            return this.message + '\n\n' + this.shareLink;
        },
        shareLink() {
            const { origin } = window.location;
            const { magnetLink, urlFileIndex } = parseRoute(this.$route);
            return `${origin}/lite/#/player?m=${btoa(magnetLink)}${
                urlFileIndex === null ? '' : '&i=' + urlFileIndex
            }&utm_source=btl&utm_medium=modal`;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        copyMessageAndLink() {
            copyToClipboard(this.shareMessage);
            this.showCopyLinkNotification = false;
            if (!this.showCopyMessageAndLinkNotification) {
                this.showCopyMessageAndLinkNotification = true;
                setTimeout(() => (this.showCopyMessageAndLinkNotification = false), this.notificationDisplayTime);
            }
        },
        async copyLink() {
            await copyToClipboard(this.shareLink);
            this.showCopyMessageAndLinkNotification = false;
            if (!this.showCopyLinkNotification) {
                this.showCopyLinkNotification = true;
                setTimeout(() => (this.showCopyLinkNotification = false), this.notificationDisplayTime);
            }
        },
    },
};
</script>

<template>
    <Modal :show="true" :close-button-event="'engagement_share_modal_close'" @close="close">
        <div id="torrent-share-modal">
            <div class="torrent-share-header">
                <span>{{ $t('Share torrent') }}</span>
                <VDropdown placement="bottom-start">
                    <SvgIcon icon="help-outline" :has-fill="true" class="share-help-icon" />
                    <template #popper>
                        <div class="share-tooltip">
                            {{ $t('share-torrent-tooltip') }}
                        </div>
                    </template>
                </VDropdown>
            </div>
            <div class="modal-body">
                <div class="message-title">
                    {{ $t('Message') }}
                </div>
                <textarea v-model="message" class="message" rows="9" />
                <div class="share-link-title">
                    {{ $t('Torrent link') }}
                </div>
                <div class="share-link-input-container">
                    <input v-model="shareLink" class="share-link-input" disabled @paste.stop @keydown.stop />
                    <div class="notification-wrapper">
                        <div
                            class="button-icon--primary copy-button"
                            :data-gtm-event="'engagement_share_modal_copy_link'"
                            @click.stop="copyLink"
                        >
                            <SvgIcon icon="copy" class="copy-icon" :has-fill="true" />
                        </div>
                        <NotificationToolTip
                            v-show="showCopyLinkNotification"
                            :text="$t('Link copied!')"
                            :width="'110px'"
                            :adjust-position="[
                                { position: 'top', amount: '-45px' },
                                { position: 'right', amount: '0' },
                            ]"
                        />
                    </div>
                </div>
            </div>
            <div class="modal-two-button-footer">
                <button
                    class="cancel-btn modal-footer-left-button button-outline--secondary"
                    :data-gtm-event="'engagement_share_modal_close'"
                    @click="close"
                >
                    {{ $t('Close') }}
                </button>
                <div class="notification-wrapper">
                    <div
                        class="copy-btn button modal-footer-right-button button--secondary"
                        :data-gtm-event="'engagement_share_modal_copy_link_and_message'"
                        @click="copyMessageAndLink"
                    >
                        {{ $t('Copy message & link') }}
                    </div>
                    <NotificationToolTip
                        v-show="showCopyMessageAndLinkNotification"
                        :text="$t('Message and link copied!')"
                        :width="'110%'"
                        :adjust-position="[
                            { position: 'top', amount: '-45px' },
                            { position: 'left', amount: '-10px' },
                        ]"
                    />
                </div>
            </div>
        </div>
    </Modal>
</template>

<style lang="scss">
.modal-container {
    margin-left: 16px;
    margin-right: 16px;
}
</style>

<style lang="scss" scoped>
#torrent-share-modal {
    padding: 24px;
    background-color: $neutral-white;
    border-radius: 3px;
    width: 500px;
    max-width: 100%;
}
.torrent-share-header {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: 600;
    color: $brand-primary-extra-dark;
    .share-help-icon {
        display: flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
        font-size: 18px;
        fill: $neutral-main;
    }
}
.share-link-title,
.message-title {
    font-size: 14px;
    color: $neutral-medium-dark;
    margin-bottom: 8px;
    margin-left: 2px;
    font-weight: 500;
}
.message {
    color: $neutral-medium-dark;
    border-radius: 4px;
    border: solid 1px #c7cdd2;
    resize: none;
    overflow: auto;
    padding: 10px 14px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;
    margin-bottom: 20px;
}
.share-link-input-container {
    display: flex;
    position: relative;
    height: 100%;
    .share-link-input {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        border: solid 1px $neutral-light-medium;
        background-color: $neutral-extra-light;
        color: $neutral-medium-dark;
        padding-left: 10px;
        padding-right: 40px;
        font-size: 16px;
    }
    .copy-button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        color: $neutral-main;
        fill: $neutral-main;
        right: 4px;
        height: 100%;
        padding: 0;
        cursor: pointer;
        &:hover {
            color: $neutral-main !important;
        }
        .copy-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            border-radius: 50%;
            padding: 6px;
            &:hover {
                background: $neutral-light;
            }
        }
    }
}
.cancel-btn,
.copy-btn {
    font-size: 14px;
    padding-left: 18px;
    padding-right: 18px;
    height: 40px;
    min-width: 100px;
    white-space: inherit;
}
.notification-wrapper {
    position: relative;
}
</style>
