import { SessionAnalytics, getNormalizedTime } from './session';
import { GoogleAnalytics } from './ga';
import { v4 as uuid } from 'uuid';

const SESSION_INFO_HASH_MAP = new Map();
// anonymise infoHashes used per session
const anonymizedInfoHash = (infoHash) => {
    if (SESSION_INFO_HASH_MAP.get(infoHash) === undefined) {
        SESSION_INFO_HASH_MAP.set(infoHash, uuid());
    }
    return SESSION_INFO_HASH_MAP.get(infoHash);
};

class TorrentAnalytics {
    static scope = 'torrent';
    static scope_1 = 't';

    static ping(eventName, parameters = {}) {
        GoogleAnalytics.sendCustomEvent(`${TorrentAnalytics.scope_1}_${eventName}`, parameters);
    }

    constructor(infoHash) {
        this.isSubmitted = false;
        this.initialzied = Date.now();
        this.isDone = false;
        this.hasMetadata = false;
        this.infoHash = infoHash;
        this.peerConnected = 0;
        this.peerDestroyed = 0;
        this.subtitleCount = 0;
        GoogleAnalytics.healthPing(TorrentAnalytics.scope, 'added');
        TorrentAnalytics.ping('add', {
            infoHash: this.infoHash,
        });
        SessionAnalytics.register(this);
        SessionAnalytics.torrentCount += 1;
    }
    noPeer(method) {}
    wire() {}
    ready() {}
    metadata() {
        this.hasMetadata = true;
    }
    download() {}
    done() {
        this.isDone = true;
    }
    peerConnect() {
        this.peerConnected += 1;
    }
    peerDestroy() {
        this.peerDestroyed += 1;
    }
    subtitle() {
        this.subtitleCount += 1;
    }
    error(error) {
        TorrentAnalytics.ping('error');
    }
    submit() {
        if (this.isSubmitted) {
            return;
        }
        this.isSubmitted = true;
        SessionAnalytics.unregister(this);

        TorrentAnalytics.ping('submit', {
            infoHash: this.infoHash,
            lifetime: getNormalizedTime(Date.now() - this.initialzied),
            isDone: this.isDone,
            hasMetadata: this.hasMetadata,
            peerConnected: this.peerConnected,
            peerDestroyed: this.peerDestroyed,
            subtitleCount: this.subtitleCount,
        });
    }
}

export { TorrentAnalytics };
