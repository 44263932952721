<script>
import { mapGetters } from 'vuex';
import MediaFile from './MediaFile.vue';
import TorrentShareModal from './TorrentShareModal';

export default {
    name: 'MediaElement',
    components: {
        MediaFile,
        TorrentShareModal,
    },
    props: {},
    data() {
        return {
            shareModal: false,
        };
    },
    computed: {
        ...mapGetters('player', ['coverImage', 'currentFile', 'currentFiles', 'currentTorrent', 'numPeers', 'name']),
        peers() {
            return this.numPeers;
        },
        streamable() {
            return true;
        },
        mediaTypeIcon() {
            return 'videocam';
        },
    },
};
</script>
<template>
    <div
        class="media-element"
        :class="{
            hidden: false,
            streamable: streamable,
        }"
    >
        <div class="media-element-info-container">
            <div class="media-element-info">
                <div class="media-cover">
                    <div
                        v-if="coverImage !== null"
                        class="media-cover-image"
                        :style="{ backgroundImage: 'url(' + coverImage + ')' }"
                    />
                    <SvgIcon :icon="mediaTypeIcon" class="media-cover-icon" :has-fill="true" />
                </div>
                <div class="media-element-info-details">
                    <div class="media-element-title torrent-name" :title="name">
                        {{ name }}
                    </div>
                    <div class="media-field">
                        <div class="peers-info">
                            <div class="total-peers">
                                <SvgIcon icon="peers" class="peers-icon" :has-fill="true" />
                                <div :class="{ 'no-peers': peers === 0 }">
                                    {{ peers }}
                                </div>
                            </div>
                            <div v-show="peers < 5 && peers > 0" class="low-peers-warning-container">
                                <div class="low-peers-warning-text">
                                    {{ $t('Low peers') }}
                                </div>
                                <VDropdown :distance="12" :skidding="40">
                                    <SvgIcon icon="help-outline" class="low-peers-help-icon" :has-fill="true" />
                                    <template #popper>
                                        <div>
                                            {{ $t('low-peer-helptext') }}
                                        </div>
                                    </template>
                                </VDropdown>
                            </div>
                        </div>
                    </div>
                    <div
                        class="media-action button--secondary"
                        :data-gtm-event="'engagement_share_media_element_open_modal'"
                        @click.stop="shareModal = true"
                    >
                        <SvgIcon class="share-icon" icon="share" :has-fill="true" />
                        <span>{{ $t('Share') }}</span>
                    </div>
                </div>
            </div>
            <div class="media-element-details">
                <div class="media-element-files-header">
                    <span class="file-name-header file-header">{{ $t('Files') }}</span>
                    <span class="file-watched-header file-header">{{ $t('Progress') }}</span>
                    <span class="file-size-header file-header">{{ $t('Size') }}</span>
                </div>
                <hr class="media-file-separator" />
                <div ref="mediaElementFiles" class="media-element-files">
                    <MediaFile v-for="(_, index) in currentFiles" :key="index" :index="index" />
                </div>
            </div>
        </div>
        <TorrentShareModal v-if="shareModal" @close="shareModal = false" />
    </div>
</template>

<style lang="scss" scoped>
.media-element {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-shrink: 0;
    color: $neutral-light;
    fill: $neutral-light;
    border-radius: 10px;
    width: 100%;
    min-width: 375px;
    margin-top: 50px;
    &.hidden {
        display: none;
    }
    &:not(.streamable) {
        .media-cover-icon {
            display: none !important;
        }
    }
    .current-playing-file-name {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 36px;
        line-height: 24px;
        position: relative;
        pointer-events: none;
        @media (max-width: 607px) {
            pointer-events: auto;
            cursor: pointer;
        }
        .truncated-text {
            @media (max-width: 607px) {
                overflow: hidden;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                padding-right: 40px;
                word-break: break-all;
            }
        }
        .expand-text-btn {
            text-decoration: underline;
            border: 0;
            padding: 0;
            margin: 0;
            background: transparent;
            color: #3265d2;
            font-size: 16px;
            font-weight: 500;
            display: inline-block;
            position: absolute;
            right: 0;
            top: 50px;
            @media (min-width: 607px) {
                display: none;
            }
            &.truncated-button {
                position: relative;
                top: 0px;
            }
        }
        @media (max-width: 607px) {
            padding-top: 16px;
            font-size: 16px;
        }
    }
    .media-element-info-container {
        max-width: 974px;
        margin: 0px auto;
        width: 100%;
        background-color: $neutral-dark;
        padding: 20px 24px;
        border-radius: 2px;
    }
    .media-element-info {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        @media (max-width: 607px) {
            margin-bottom: 32px;
        }
        .media-cover {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            background-color: $neutral-medium-dark;
            width: 64px;
            height: 64px;
            margin-right: 20px;
            border: 4px solid #303233;
            flex-shrink: 0;
            .media-cover-icon {
                z-index: zindex(media_cover_icon);
                position: absolute;
                font-size: 30px;
            }
            .media-cover-image {
                z-index: zindex(media_cover_image);
                background-size: cover;
                background-position: center;
                width: 100%;
                height: 100%;
            }
        }
        .media-element-info-details {
            @extend .no-select;
            width: 100%;
            align-self: stretch;
            font-size: 1rem;
            display: grid;
            grid: 1fr 1fr / 1fr min-content;
            grid-template-areas: 'title share-btn' 'details share-btn';
            column-gap: 20px;
            @media (max-width: 607px) {
                grid-template-areas: 'title title' 'details share-btn';
                column-gap: 10px;
            }
            .media-element-title {
                grid-area: title;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
                margin-bottom: 4px;
                @media (max-width: 607px) {
                    font-weight: 600;
                }
            }
            .media-field {
                grid-area: details;
                align-self: end;
                display: flex;
                position: relative;
                flex-wrap: wrap;
                font-size: 13px;
                align-items: center;
                .peers-info {
                    display: flex;
                    position: relative;
                    align-items: center;
                    .peers-icon {
                        font-size: 18px;
                        margin-right: 6px;
                        color: $neutral-white;
                        fill: $neutral-white;
                    }
                    .total-peers {
                        display: flex;
                        align-items: center;
                    }
                    .no-peers {
                        color: $accents-yellow-main;
                    }
                    .low-peers-warning-container {
                        display: flex;
                        white-space: nowrap;
                        padding: 6px 8px;
                        background-color: $accents-yellow-medium;
                        color: $neutral-dark;
                        border-radius: 2px;
                        font-weight: 400;
                        font-size: 13px;
                        align-items: center;
                        margin-left: 10px;
                        right: -72px;
                        .low-peers-help-icon {
                            display: flex;
                            align-items: center;
                            margin-left: 6px;
                            cursor: pointer;
                            font-size: 18px;
                            fill: $neutral-dark;
                        }
                    }
                }
                .media-element-divider {
                    display: flex;
                    height: 14px;
                    width: 1px;
                    background-color: $neutral-main;
                    margin: 0 14px;
                    @media (max-width: 400px) {
                        margin: 0 10px;
                    }
                }
            }
            .media-action {
                grid-area: share-btn;
                align-self: start;
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;

                * {
                    pointer-events: none;
                }
            }
            .media-field,
            .media-action {
                margin: 0;
            }
        }
    }
    .share-icon {
        font-size: 20px;
        color: $neutral-white;
        fill: $neutral-white;
    }
    .button--secondary {
        font-size: 14px;
        display: inline-flex;
        padding: 10px 16px;
        gap: 8px;
        border-radius: 100px;
        @media (max-width: 607px) {
            padding: 8px 12px;
        }
    }
    @media (max-width: 607px) {
        padding: 0 16px;
    }
}
.media-element-files::-webkit-scrollbar {
    width: 8px;
}

.media-element-files::-webkit-scrollbar-track {
    background: $neutral-light-medium;
    border-radius: 20px;
}

.media-element-files::-webkit-scrollbar-thumb {
    background-color: $neutral-medium-dark;
    border-radius: 20px;
}
.media-element-details {
    background-color: $neutral-dark;
    border-radius: 13px;
    max-height: 269px;
    .media-element-files-header {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 0 32px 0px 12px;
        color: $neutral-light-medium;
        column-gap: 32px;
        word-wrap: break-word;
        .file-name-header {
            flex: 1;
        }
        .file-watched-header {
            display: flex;
            width: 122px;
        }
        .file-size-header {
            display: flex;
            width: 72px;
        }
        .file-header {
            height: 100%;
            padding-bottom: 16px;
            font-size: 1rem;
            font-weight: 500;
            color: $neutral-medium;
        }
        .file-watched-header,
        .file-size-header {
            @media (max-width: 607px) {
                display: none;
            }
        }
    }
    @media (max-width: 607px) {
        max-height: 303px;
        padding: 16px 0px 24px 0;
        margin: 0px -16px 0px;
    }
}

.media-element-files {
    @extend .no-select;
    display: flex;
    position: relative;
    flex-direction: column;
    max-height: 176px;
    overflow-y: auto;
    @media (max-width: 607px) {
        max-height: 228px;
    }
}
</style>
