'use strict';

var FORMAT_NAME = 'json';

/******************************************************************************************
 * Parses captions in JSON format
 ******************************************************************************************/
function parse(content, options) {
    return JSON.parse(content);
}

/******************************************************************************************
 * Builds captions in JSON format
 ******************************************************************************************/
function build(captions, options) {
    return JSON.stringify(captions, ' ', 2);
}

/******************************************************************************************
 * Detects a subtitle format from the content.
 ******************************************************************************************/
function detect(content) {
    if (typeof content === 'string') {
        if (/^\[[\s\r\n]*\{[\s\S]*\}[\s\r\n]*\]$/g.test(content)) {
            /*
      [
        { ... }
      ]
      */
            return 'json';
        }
    }
}

/******************************************************************************************
 * Export
 ******************************************************************************************/
module.exports = {
    name: FORMAT_NAME,
    detect: detect,
    parse: parse,
    build: build,
};
