export const rand = (len, chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => {
    let s = '';
    for (let i = 0; i < len; ++i) {
        s += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return s;
};

export const formatBytes = (bytes, decimals) => {
    if (bytes == 0) {
        return '0 B';
    }
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    // const size = Vue.i18n.translate(sizes[i]);
    const size = sizes[i];
    return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${size}`;
};

export const formatHHMMSS = (num) => {
    var secNum = num | 0; // don't forget the second param
    var hours = ~~(secNum / 3600);
    var minutes = ~~((secNum - hours * 3600) / 60);
    var seconds = secNum - hours * 3600 - minutes * 60;
    var time = '';

    if (hours) {
        time += hours + ':';
    }

    if (minutes < 10 && hours > 0) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }

    time += minutes + ':' + seconds;
    return time;
};
