<script>
import { setConsent } from '@/router/guards';

export default {
    name: 'TermsOfServicePopup',
    emits: ['update'],
    data() {
        return {
            agreeChecked: false,
        };
    },
    methods: {
        agree() {
            setConsent();
            this.$emit('update');
        },
    },
};
</script>

<template>
    <div id="tos-overlay">
        <div class="tos-popup">
            <div class="tos-header-text">{{ $t('terms-of-service.header') }}</div>

            <div class="tos-body">
                <div class="tos-line">{{ $t('terms-of-service.prompt') }}</div>

                <div class="tos-link">
                    <a
                        href="https://www.bittorrent.com/legal/terms-of-use/"
                        target="_blank"
                        rel="noopenner noreferrer"
                        class="tos-link-text"
                        >{{ $t('terms-of-service.tou') }}</a
                    >
                </div>
                <div class="tos-link">
                    <a
                        href="https://www.bittorrent.com/legal/eula/"
                        target="_blank"
                        rel="noopenner noreferrer"
                        class="tos-link-text"
                        >{{ $t('terms-of-service.eula') }}</a
                    >
                </div>
                <div class="tos-link">
                    <a
                        href="https://www.bittorrent.com/legal/privacy-policy/"
                        target="_blank"
                        rel="noopenner noreferrer"
                        class="tos-link-text"
                        >{{ $t('terms-of-service.privacy-policy') }}</a
                    >
                </div>
            </div>

            <div class="tos-confirmation">
                <BtCheckbox id="tos-checkbox" v-model="agreeChecked" class="tos-checkbox" />
                <label for="tos-checkbox" class="tos-confirmation-text">{{ $t('terms-of-service.confirm') }}</label>
            </div>

            <div class="tos-button-holder">
                <BtButton
                    class="tos-button"
                    :disabled="!agreeChecked"
                    :style="{ textTransform: $i18n.locale === `ru` ? `inherit` : 'capitalize' }"
                    @click="agree"
                >
                    {{ $t('terms-of-service.agree') }}
                </BtButton>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
#tos-overlay {
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: zindex(tos_overlay);
    display: flex;

    .tos-popup {
        background-color: #fff;
        color: #000;
        margin: auto auto;

        text-align: center;
        padding: 1rem;
        max-width: 350px;
    }

    .tos-header-text {
        margin: 0.5rem 0 1.5rem;
        font-weight: 600;
    }

    .tos-body {
        padding: 0.75rem;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        background-color: #f0f3f6;
    }

    .tos-line {
        padding-bottom: 0.75rem;
        font-weight: 500;
    }

    .tos-link {
        display: flex;
        padding: 0.4rem 0;
        vertical-align: top;
    }

    .tos-link-text {
        //margin-left: 14px;
        font-size: 14px;
        text-align: start;
        color: #3477ff;
    }

    .tos-button-holder {
        display: flex;
        justify-content: center;
        margin: 1rem 0;
    }

    .tos-confirmation {
        display: flex;
        padding: 1rem 0;
        vertical-align: top;
    }
    .tos-checkbox {
        margin-bottom: auto;
    }
    .tos-confirmation-text {
        padding-left: 1rem;
        text-align: start;
        font-size: 0.9rem;
    }

    .tos-button {
        transition: 0.2s;
    }
}
</style>
