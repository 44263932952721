<template>
    <div class="new-to-torrenting">
        <div class="section-1">
            <img class="lightning-bolt" src="@/assets/home/lightning.svg" alt="lightning bolt" />
            <div class="header">{{ $t('new-to-torrenting.header') }}</div>
            <div class="body">{{ $t('new-to-torrenting.subheader') }}</div>
            <div class="body body-faq">
                <router-link to="/faq">{{ $t('new-to-torrenting.faq') }}</router-link>
            </div>
        </div>
        <div class="section-2">
            <div v-for="(newToTorrentingTopic, index) in $tm('new-to-torrenting-topics')" :key="index">
                <CollapseContent
                    :content-title="newToTorrentingTopic.contentTitle"
                    :content-description="newToTorrentingTopic.contentDescription"
                ></CollapseContent>
            </div>
        </div>
    </div>
</template>

<script>
import CollapseContent from './CollapseContent.vue';

export default {
    name: 'NewToTorrenting',
    components: { CollapseContent },
};
</script>

<style lang="scss" scoped>
.new-to-torrenting {
    display: grid;
    gap: 60px;
    max-width: 1200px;
    margin: 250px auto 200px auto;
    padding: 0 1.5rem;

    @media (max-width: 818px) {
        gap: 28px;
        margin: 200px auto 150px auto;
    }
    @media (max-width: 604px) {
        gap: 13px;
        margin: 130px auto 130px auto;
        grid-template-columns: 100%;
    }

    .header {
        font-weight: 700;
        font-size: 3rem;
        line-height: 101.52%;
        padding-bottom: 1rem;
        position: relative;
        @media (max-width: 604px) {
            font-size: 2.25rem;
            width: 300px;
        }
    }

    .body {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        position: relative;
        @media (max-width: 604px) {
            font-size: 14px;
            width: 200px;
        }
    }

    .body-faq {
        margin-top: 1em;
        color: #52a911;

        &::after {
            content: ' >';
        }
    }

    .section-1 {
        margin: 1em 0;
        position: relative;

        .lightning-bolt {
            position: absolute;
            left: -129px;
            top: -114px;

            @media (max-width: 818px) {
                left: -57px;
                top: -120px;
            }

            @media (max-width: 604px) {
                left: -22px;
                top: -88px;
                width: 255px;
            }
        }
    }

    .section-2 {
        position: relative;
    }

    @media (min-width: 1230px) {
        grid-template-columns: 300px auto;
    }
}
</style>
