const debug = localStorage.getItem('debug');

const state = {
    askForConsentResolver: null,
    askForInterruptResolver: null,
    interruptSignal: null,
    debugMode: debug ? debug.includes('lite') || debug === '*' : false,
};

const getters = {
    askForConsentResolver: ({ askForConsentResolver }) => askForConsentResolver,
    askForInterruptResolver: ({ askForInterruptResolver }) => askForInterruptResolver,
    interruptSignal: ({ interruptSignal }) => interruptSignal,
    debugMode: ({ debugMode }) => debugMode,
    replaceModal: ({ replaceModal }) => replaceModal,
};

const mutations = {
    askForConsentResolver: (state, askForConsentResolver) => (state.askForConsentResolver = askForConsentResolver),
    askForInterruptResolver: (state, { askForInterruptResolver, signal }) => {
        state.askForInterruptResolver = askForInterruptResolver;
        state.interruptSignal = signal;
    },
    setReplaceModal: (state, setOpen) => (state.replaceModal = setOpen),
};

const actions = {
    askConsent({ commit }) {
        return new Promise((res) =>
            commit('askForConsentResolver', () => {
                res();
                commit('askForConsentResolver', null);
            })
        );
    },
    askForInterrupt({ commit }, signal) {
        return new Promise((res) =>
            commit('askForInterruptResolver', {
                askForInterruptResolver: (response) => {
                    res(response);
                    commit('askForInterruptResolver', { askForInterruptResolver: null, signal: null });
                },
                signal,
            })
        );
    },
    keyup() {},
    keydown() {},
    resize() {},
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
