<script>
export default {
    name: 'LoadingSpinner',
};
</script>

<template>
    <div class="loading-spinner-container">
        <div class="loading" />
    </div>
</template>

<style lang="scss" scoped>
$spacing: 0.75em;
$small: 3.75em;
$medium: ($small + $spacing);
$large: ($small + ($spacing * 2));

.loading-spinner-container {
    z-index: zindex(min);
    border-radius: 50%;
    width: ($small + ($spacing * 3));
    height: ($small + ($spacing * 3));
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading,
    .loading::before,
    .loading::after {
        position: absolute;
        top: 50%;
        left: 50%;
        border: 3px solid rgba(118, 184, 63, 0);
        border-left-color: rgba(118, 184, 63, 1);
        -webkit-border-radius: 999px;
        -moz-border-radius: 999px;
        border-radius: 999px;
    }

    .loading {
        margin: math.div(-$medium, 2) 0 0 math.div(-$medium, 2);
        height: $medium;
        width: $medium;
        -webkit-animation: animation-rotate 1000ms linear infinite;
        -moz-animation: animation-rotate 1000ms linear infinite;
        -o-animation: animation-rotate 1000ms linear infinite;
        animation: animation-rotate 1000ms linear infinite;
    }

    .loading::before {
        content: '';
        margin: math.div(-$small, 2) 0 0 math.div(-$small, 2);
        height: $small;
        width: $small;
        -webkit-animation: animation-rotate 1000ms linear infinite;
        -moz-animation: animation-rotate 1000ms linear infinite;
        -o-animation: animation-rotate 1000ms linear infinite;
        animation: animation-rotate 1000ms linear infinite;
    }

    .loading::after {
        content: '';
        margin: math.div(-$large, 2) 0 0 math.div(-$large, 2);
        height: $large;
        width: $large;
        -webkit-animation: animation-rotate 2000ms linear infinite;
        -moz-animation: animation-rotate 2000ms linear infinite;
        -o-animation: animation-rotate 2000ms linear infinite;
        animation: animation-rotate 2000ms linear infinite;
    }

    @-webkit-keyframes animation-rotate {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @-moz-keyframes animation-rotate {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-o-keyframes animation-rotate {
        100% {
            -o-transform: rotate(360deg);
        }
    }

    @keyframes animation-rotate {
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
