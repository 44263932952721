const EventEmitter = require('events');
const mp4 = require('mp4-stream');

// if we want to ignore more than this many bytes, request a new stream.
// if we want to ignore fewer, just skip them.
const FIND_MOOV_SEEK_SIZE = 4096;

class MoovReader extends EventEmitter {
    constructor(file) {
        super();

        this._tracks = [];
        this._file = file;
        this._decoder = null;
        this.mimesResolve = undefined;
        this._mimes = new Promise((resolve) => {
            this.mimesResolve = resolve;
        });
        this._findMoov(0);
    }

    _findMoov(offset) {
        if (this._decoder) {
            this._decoder.destroy();
        }

        let toSkip = 0;
        this._decoder = mp4.decode();
        const fileStream = this._file.createReadStream({
            start: offset,
        });
        fileStream.pipe(this._decoder);

        const boxHandler = (headers) => {
            if (headers.type === 'moov') {
                this._decoder.removeListener('box', boxHandler);
                this._decoder.decode((moov) => {
                    fileStream.destroy();
                    try {
                        this._processMoov(moov);
                    } catch (err) {
                        err.message = `Cannot parse mp4 file: ${err.message}`;
                        this.emit('error', err);
                    }
                });
            } else if (headers.length < FIND_MOOV_SEEK_SIZE) {
                toSkip += headers.length;
                this._decoder.ignore();
            } else {
                this._decoder.removeListener('box', boxHandler);
                toSkip += headers.length;
                fileStream.destroy();
                this._decoder.destroy();
                this._findMoov(offset + toSkip);
            }
        };
        this._decoder.on('box', boxHandler);
    }

    _processMoov(moov) {
        const traks = moov.traks;
        this._tracks = [];
        this._hasVideo = false;
        this._hasAudio = false;
        for (let i = 0; i < traks.length; i++) {
            const trak = traks[i];
            const stbl = trak.mdia.minf.stbl;
            const stsdEntry = stbl.stsd.entries[0];
            const handlerType = trak.mdia.hdlr.handlerType;
            let codec;
            let mime;
            if (handlerType === 'vide' && stsdEntry.type === 'avc1') {
                if (this._hasVideo) {
                    continue;
                }
                this._hasVideo = true;
                codec = 'avc1';
                if (stsdEntry.avcC) {
                    codec += `.${stsdEntry.avcC.mimeCodec}`;
                }
                mime = `video/mp4; codecs="${codec}"`;
            } else if (handlerType === 'soun' && stsdEntry.type === 'mp4a') {
                if (this._hasAudio) {
                    continue;
                }
                this._hasAudio = true;
                codec = 'mp4a';
                if (stsdEntry.esds && stsdEntry.esds.mimeCodec) {
                    codec += `.${stsdEntry.esds.mimeCodec}`;
                }
                mime = `audio/mp4; codecs="${codec}"`;
            } else {
                continue;
            }

            this._tracks.push({
                mime,
            });
        }

        if (this._tracks.length === 0) {
            this.emit('error', new Error('no playable tracks'));
            return;
        }

        const data = this._tracks.map((track) => {
            return {
                mime: track.mime,
            };
        });

        this.mimesResolve(data);
    }
}

export default MoovReader;
