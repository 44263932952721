<script>
import OnboardOverlay from './OnboardOverlay';

export default {
    name: 'SearchTutorial',
    components: {
        OnboardOverlay,
    },
    props: {
        initState: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            state: this.initState,
        };
    },
    computed: {},
    created() {
        this.content = [
            {
                number: 1,
                text: `${this.$t('search-tutorial.content-1')}`,
            },
            {
                number: 2,
                text: `${this.$t('search-tutorial.content-2')}`,
            },
            {
                number: 3,
                text: `${this.$t('search-tutorial.content-3')}`,
            },
            {
                number: 4,
                text: `${this.$t('search-tutorial.content-4')}`,
            },
        ];
    },
    beforeUnmount() {
        localStorage.setItem('hasSeenSearchTutorial', true);
    },
    mounted() {
        /*
        must wait for first img to load to fully load in before autoscrolling
        to ensure height calculations are correct
        */
        this.waitForFirstImg = new Promise((res) => {
            this.resolveWaitForFirstImg = res;
        }).then(() => {
            let searchTutorialContainer = this.$el.getElementsByClassName('container')[0];
            let searchTutorialContainerRect = searchTutorialContainer.getBoundingClientRect();
            if (
                window.innerHeight < searchTutorialContainerRect.top + searchTutorialContainerRect.height &&
                this.initState === 1
            ) {
                this.$el.getElementsByClassName('container')[0].scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        });
    },
    methods: {
        firstImgLoaded() {
            this.resolveWaitForFirstImg();
        },
        nextStep() {
            let nextState = this.state + 1;
            this.state = nextState;
        },
        prevStep() {
            let nextState = this.state - 1;
            this.state = nextState;
        },
        jumpStep(stepNum) {
            this.state = stepNum;
        },
    },
};
</script>

<template>
    <div class="search-tutorial" :class="{ 'show-all': state === 0 }">
        <div class="bg"></div>
        <OnboardOverlay target=".search-control-input-container" :update-interval="10" :offset="0" />
        <div class="container">
            <div class="close" @click="$emit('close')">
                <SvgIcon alt="close" icon="close" :has-fill="true" />
            </div>
            <div>{{ $t('search-tutorial.how-to-use') }}</div>
            <div class="content">
                <div
                    v-for="step in content"
                    :key="`step-${step.number}`"
                    class="step"
                    :class="{ visible: state === step.number || state === 0 }"
                >
                    <div class="text-container">
                        <p class="number">
                            <span>{{ $t('Step') }}</span> {{ step.number }}<span>/{{ content.length }}</span
                            ><span>.</span>
                        </p>
                        <p class="text">{{ step.text }}</p>
                    </div>
                    <div v-if="step.number === 1" class="img-container">
                        <img
                            class="tutorial-img tutorial-img-1"
                            src="@/assets/search-tutorial/step1.svg"
                            @load="firstImgLoaded"
                        />
                        <img class="tutorial-img-show-all" src="@/assets/search-tutorial/step1-showall.svg" />
                    </div>
                    <div v-if="step.number === 2" class="img-container">
                        <div>
                            <p class="img-description">{{ $t('search-tutorial.download') }}</p>
                            <img class="tutorial-img" src="@/assets/search-tutorial/step2-1.svg" />
                            <img class="tutorial-img-show-all" src="@/assets/search-tutorial/step2-1-showall.svg" />
                        </div>
                        <span class="img-separator">{{ $t('or') }}</span>
                        <div>
                            <p class="img-description">{{ $t('search-tutorial.copy') }}</p>
                            <img class="tutorial-img" src="@/assets/search-tutorial/step2-2.svg" />
                            <img class="tutorial-img-show-all" src="@/assets/search-tutorial/step2-2-showall.svg" />
                        </div>
                    </div>
                    <div v-if="step.number === 3" class="img-container">
                        <div>
                            <p class="img-description">{{ $t('search-tutorial.drop-or-click') }}</p>
                            <img class="tutorial-img" src="@/assets/search-tutorial/step3-1.svg" />
                            <img class="tutorial-img-show-all" src="@/assets/search-tutorial/step3-1-showall.svg" />
                        </div>
                        <span class="img-separator">{{ $t('or') }}</span>
                        <div>
                            <p class="img-description">{{ $t('search-tutorial.paste') }}</p>
                            <img class="tutorial-img" src="@/assets/search-tutorial/step3-2.svg" />
                            <img class="tutorial-img-show-all" src="@/assets/search-tutorial/step3-2-showall.svg" />
                        </div>
                    </div>
                    <div v-if="step.number === 4" class="img-container">
                        <img class="tutorial-img" src="@/assets/search-tutorial/step4.svg" />
                        <img class="tutorial-img-show-all" src="@/assets/search-tutorial/step4-showall.svg" />
                    </div>
                </div>
            </div>
            <div class="step-indicator-container">
                <button
                    :disabled="state === 1"
                    class="chevron-container"
                    :class="{ disabled: state === 1 }"
                    @click="prevStep"
                >
                    <SvgIcon class="chevron" icon="chevron-left" :has-fill="true" />
                </button>
                <button
                    v-for="step in content"
                    :key="`dot-${step.number}`"
                    class="dots"
                    :class="{ active: state === step.number }"
                    @click="jumpStep(step.number)"
                ></button>
                <button
                    :disabled="state === 4"
                    class="chevron-container"
                    :class="{ disabled: state === 4 }"
                    @click="nextStep"
                >
                    <SvgIcon class="chevron" icon="chevron-right" :has-fill="true" />
                </button>
            </div>
        </div>
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
}
.container {
    position: absolute;
    color: $neutral-white;
    z-index: zindex(onboard_overlay);
    padding: 24px 33px 20px 33px;
    border: 1px solid $brand-primary-medium;
    width: 99%;
    margin: auto;
    border-radius: 4px;
    text-align: left;
    font-size: 20px;
    background-color: $neutral-dark;
    max-width: 626px;
    margin: 0 auto;
    left: 0;
    right: 0;

    @media screen and (max-width: 817px) {
        padding: 20px 20px 20px 18px;
    }
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    fill: $neutral-white;
    cursor: pointer;
}
.tutorial-img-show-all {
    max-width: 100%;
    display: none;
}
.search-tutorial {
    position: relative;
    top: 4px;

    &.show-all {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;

        .container {
            background-color: $neutral-light;
            color: $neutral-dark;
            border: none;
            padding: 24px;
            max-width: 773px;
            margin: 54px 0 0 0;

            .close {
                fill: $neutral-dark;
            }
            .img-container {
                color: $brand-primary-main;

                .img-separator {
                    align-items: flex-start;
                    margin: 67px 27px 0 27px;
                    text-transform: capitalize;
                }
            }

            .text-container {
                flex-direction: column;
                row-gap: 10px;
                font-size: 20px;
            }

            .step-indicator-container {
                display: none;
            }

            .tutorial-img {
                display: none;
            }
            .tutorial-img-show-all {
                display: block;
            }
            .number {
                color: $brand-primary-main;

                span:first-of-type,
                span:last-of-type {
                    display: inline;
                }
                span:nth-of-type(2) {
                    display: none;
                }
            }
        }

        .step {
            margin: 50px 0;
        }
    }
}
.step {
    display: none;
    margin: 24px 0;
    .text-container {
        display: flex;
        align-items: flex-start;
        font-size: 16px;
        .number {
            margin: 0 10px 0 0;
            color: $brand-primary-light;
            span:first-of-type,
            span:last-of-type {
                display: none;
            }
            span:nth-of-type(2) {
                display: inline;
            }
        }
        .text {
            font-size: 14px;
            margin: 0;
            line-height: 17px;
        }
    }
    .img-container {
        display: flex;
        justify-content: center;
        margin: 24px 0 0 0;
        color: $brand-primary-medium;

        .img-description {
            font-size: 13px;
            margin: 0 0 8px 0;
            text-align: center;
        }
        .img-separator {
            font-size: 13px;
            margin: 28px 27px 0 27px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: $neutral-light-medium;

            @media screen and (max-width: 817px) {
                margin: 28px 20px 0 20px;
            }
        }

        .tutorial-img {
            max-width: 100%;
        }

        .tutorial-img-1 {
            margin: 22px 0;
        }
    }

    &.visible {
        display: block;
    }
}
.step-indicator-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .chevron-container {
        background: $brand-primary-main;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        margin: 0 13px;
        .chevron {
            fill: $neutral-white;
            font-size: 36px;
        }
        &.disabled {
            opacity: 0.3;
        }
    }
    .dots {
        border-radius: 50%;
        height: 6px;
        width: 6px;
        background-color: $neutral-main;
        margin: 3px;
        padding: 3px;

        &.active {
            background-color: $neutral-light-medium;
        }
    }
    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
}
</style>
