<template>
    <div class="life-style-container">
        <div class="image-holder">
            <img class="image" :src="LifeStyleImage" />
        </div>
        <div class="tile group-1">
            <div class="header">{{ $t(rp + 'header-1') }}</div>
            <div class="body">{{ $t(rp + 'body-1') }}</div>
        </div>

        <div class="tile group-2">
            <div class="header">{{ $t(rp + 'header-2') }}</div>
            <div class="body">{{ $t(rp + 'body-2') }}</div>
        </div>

        <div class="tile group-3">
            <div class="header">{{ $t(rp + 'header-3') }}</div>
            <div class="body">{{ $t(rp + 'body-3') }}</div>
        </div>

        <div class="tile group-4">
            <div class="header">{{ $t(rp + 'header-4') }}</div>
            <div class="body">{{ $t(rp + 'body-4') }}</div>
        </div>
    </div>
</template>

<script>
import LifeStyleImage from '@/assets/LifeStyleLarge.png';
const RESOURCE_PATH = 'lifestyle.';

export default {
    name: 'LifeStyle',
    data() {
        return {
            rp: RESOURCE_PATH,
            LifeStyleImage: LifeStyleImage,
        };
    },
};
</script>

<style scoped lang="scss">
.life-style-container {
    padding: 1.5rem;
    display: grid;
    gap: 25px;

    max-width: 1200px;
    margin: auto;

    .image {
        display: flex;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
    }

    .body {
        font-size: 0.9rem;
        line-height: 24px;
    }

    .tile {
        border-left: 1px solid #52a911;
        padding: 0.4rem 1.25rem;
    }

    .header {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 24px;
        padding-bottom: 0.5rem;
    }

    @media (min-width: 818px) {
        gap: 50px;
        grid-template-columns: 1fr 1fr;

        .image-holder {
            grid-column: span 2;
        }

        .header {
            font-size: 2rem;
            line-height: 2rem;
            padding-bottom: 1rem;
        }
    }

    @media (min-width: 1230px) {
        gap: 20px;
        grid-template-columns: 30% 40% 30%;

        .image-holder {
            display: flex;
        }
        .image {
            margin: auto auto;
            padding: 50px 0;
        }
        .image-holder {
            grid-column: 2;
            grid-row: span 3;
        }

        .group-1 {
            grid-column: 1;
            grid-row: 1;
        }

        .group-2 {
            grid-column: 3;
            grid-row: 1;
        }

        .group-3 {
            grid-column: 1;
            grid-row: 3;
        }

        .group-4 {
            grid-column: 3;
            grid-row: 3;
        }
    }
}
</style>
