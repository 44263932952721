<script>
export default {
    name: 'SliderThumb',
    mounted() {
        this.$Logger.debug('lifecycle', { event: 'mounted', component: 'SliderThumb' });
        this.$emit('thumbLoaded', this.$el.offsetWidth);
    },
    methods: {
        emitMouseDown() {
            this.$emit('mousedown');
        },
        emitMouseEnter() {
            this.$emit('mouseenter');
        },
        emitMouseLeave() {
            this.$emit('mouseleave');
        },
    },
};
</script>
<template>
    <div @mousedown="emitMouseDown" @mouseenter="emitMouseEnter" @mouseleave="emitMouseLeave" />
</template>
