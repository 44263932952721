import Store from '@/store';

const TOS_AGREEMENT = 'TOS-AGREEMENT-V1';

let _hasConsent = null;

const hasConsent = () => {
    if (_hasConsent === null) {
        _hasConsent = localStorage.getItem(TOS_AGREEMENT) === 'true';
    }
    return _hasConsent;
};

const askConsent = () => {
    return Store.dispatch('product/askConsent');
};

export const ConsentGuard = async (to) => {
    if (to.meta.requiresConsent === true && !hasConsent()) {
        try {
            await askConsent();
        } catch (err) {
            console.error(err);
            return false;
        }
    }
};

export const setConsent = () => {
    _hasConsent = true;
    localStorage.setItem(TOS_AGREEMENT, _hasConsent);
};
