import { v4 as uuid } from 'uuid';

export class IDBSink {
    static newId() {
        return `idbsink-${uuid()}`;
    }
    static async deleteDatabase(id) {
        const handler = indexedDB.deleteDatabase(id);
        return new Promise((res, rej) => {
            handler.onsuccess = () => {
                res();
            };
            handler.onerror = (e) => {
                rej();
            };
            handler.onblocked = (e) => {
                rej();
            };
        });
    }
    static async listDatabases() {
        return window.indexedDB.databases();
    }
    static async openDatabase(id) {
        const handler = window.indexedDB.open(id);
        return new Promise((res, rej) => {
            handler.onsuccess = () => {
                res(handler.result);
            };
            handler.onerror = (e) => {
                console.error(e);
                rej(null);
            };
            handler.onupgradeneeded = (e) => {
                e.target.result.createObjectStore('blob', { keyPath: 'id', autoIncrement: true });
            };
        });
    }
    constructor(level, idb, memoryWriteLimit = 2) {
        this.level = level;
        this.memoryWriteLimit = memoryWriteLimit;
        this.logs = [];
        this.db = idb;
        this._isWriting = false;
        // add a schedule based auto write, add event listener to write before close
    }
    get id() {
        return this.db.name;
    }
    async close() {
        return this.db.close();
    }
    async delete() {
        return this.close().then((_) => IDBSink.deleteDatabase(this.id));
    }
    write(log = null) {
        if (log !== null) {
            this.logs.push(log);
        }
        if (this.db !== null && this.logs.length > this.memoryWriteLimit && this._isWriting === false) {
            this._isWriting = true;
            this._write().finally(() => {
                this._isWriting = false;
                if (this.logs.length > this.memoryWriteLimit) {
                    this.write();
                }
            });
        }
    }
    _serializeLogs() {
        let stream = '';
        for (let i = 0; i < this.logs.length; i++) {
            stream += JSON.stringify(this.logs[i]);
            stream += '\r\n';
        }
        return [stream, this.logs.length];
    }
    async _write() {
        return new Promise((res, rej) => {
            const [stream, length] = this._serializeLogs();
            const t = this.db.transaction(['blob'], 'readwrite');
            const store = t.objectStore('blob');
            let r = store.add({ b: stream });
            r.onerror = (e) => {
                console.error(e);
                rej();
            };
            r.onsuccess = (e) => {
                this.logs.splice(0, length);
                res();
            };
        });
    }
    async toBlob() {
        // need to await _isWriting
        this._isWriting = true;
        return new Promise((res) => {
            const t = this.db.transaction(['blob'], 'readwrite');
            const store = t.objectStore('blob');
            let r = store.getAll();
            const [cacheItems] = this._serializeLogs();
            r.onsuccess = (e) => {
                const dbItems = e.target.result;
                const b = new Blob([...dbItems.map((i) => i.b), cacheItems], { type: 'text/plain' });
                this._isWriting = false;
                res(b);
            };
            r.onerror = (e) => {
                console.error(e);
                const b = new Blob([cacheItems], { type: 'text/plain' });
                this._isWriting = false;
                res(b);
            };
        });
    }
}
