import _ from 'lodash';
import MoovReader from '../moovReader';
import TorrentParser from 'parse-torrent';
import mimetype from 'mime-types';
import { semanticVersion, liteIdentifier } from './version';
const randombytes = require('randombytes');

// default trackers are appended to trackers list defined in the torrent
const DEFAULT_TRACKERS = ['wss://wstracker.online'];

// video extensions
const VIDEOSTREAM_EXTS = ['.mp4'];
const MEDIASOURCE_VIDEO_EXTS = ['.m4v', '.mkv', '.webm'];
const VIDEO_EXTS = ['.mov', '.ogv'];

// audio extensions
export const MEDIASOURCE_AUDIO_EXTS = ['.m4a', '.m4b', '.m4p', '.mp3'];
export const AUDIO_EXTS = ['.aac', '.oga', '.ogg', '.wav', '.flac'];

const PLAYABLE_EXTS = [...VIDEOSTREAM_EXTS, ...MEDIASOURCE_AUDIO_EXTS, ...AUDIO_EXTS];

export const isStreamable = (extension) => {
    return PLAYABLE_EXTS.includes(extension);
};
const localStorageDefaultTrackers = [];

try {
    let trs = JSON.parse(localStorage.getItem('DEFAULT_TRACKERS'));
    if (Array.isArray(trs)) {
        for (let t of trs) {
            localStorageDefaultTrackers.push(t);
        }
    }
} catch (err) {}

export const defaultTrackers = new Set([...DEFAULT_TRACKERS, ...localStorageDefaultTrackers]);

export const isMagnet = (id) => {
    return typeof id === 'string' && /^(stream-)?magnet:/.test(id);
};
export const isInfoHash = (id) => {
    return typeof id === 'string' && (/^[a-f0-9]{40}$/i.test(id) || /^[a-z2-7]{32}$/i.test(id));
};

export const isUrl = (id) => {
    return id.indexOf('http') === 0 && id.endsWith('.torrent');
};

export const parseTorrents = (torrentId) => {
    return new Promise((res, reject) => {
        if (typeof torrentId === 'object') {
            TorrentParser.remote(torrentId, (error, torrentObject) => {
                if (error) {
                    reject(error);
                } else {
                    torrentObject.announce = [...new Set([...torrentObject.announce, ...defaultTrackers])];
                    res(TorrentParser.toMagnetURI(torrentObject));
                }
            });
        } else {
            try {
                let torrentObject = TorrentParser(torrentId);
                torrentObject.announce = [...new Set([...torrentObject.announce, ...defaultTrackers])];
                res(TorrentParser.toMagnetURI(torrentObject));
            } catch (error) {
                reject(error);
            }
        }
    });
};

export const isMagnetLink = (torrentId) => {
    let isMagnetLink = typeof torrentId === 'string' && /^(stream-)?magnet:/.test(torrentId);
    return isMagnetLink;
};

export const parsedTorrentFailedMessage = (message = '') => {
    return message ? 'Load torrent failed: ' + message : 'Load torrent failed';
};

//finds best file based on extension and returns its index
export const getBestFile = (files) => {
    for (let i = 0; i < files.length; i++) {
        let ext = getFileExtension(files[i].name);
        if (PLAYABLE_EXTS.includes(ext)) {
            return i;
        }
    }
    return 0;
};

export const getInitialChunkIndexes = (file) => {
    const startPiece = file._startPiece;
    const endPiece = Math.min(file._endPiece, file._startPiece + 10);
    return [startPiece, endPiece];
};

export const getFileExtension = (name) => {
    let ext = '.' + name.split('.').pop();
    return ext.toLowerCase();
};

export const isVideoCodecSupported = async function (file) {
    // Default to true if we cant determine codec support since we cant read codecs of non-mp4 files
    if (getFileExtension(file.name) !== '.mp4') {
        return true;
    }

    let isSupported = true;
    let _moovReader = new MoovReader(file);
    let mimeTypeList = await _moovReader._mimes;

    let logMessage = '';
    for (let i = 0; i < mimeTypeList.length; i++) {
        let mime = mimeTypeList[i].mime;
        if (MediaSource.isTypeSupported(mime) === false) {
            isSupported = false;
            // break;
        }
        logMessage += `mime: ${mime} isSupported:${MediaSource.isTypeSupported(mime)},`;
    }
    return isSupported;
};

// https://www.bittorrent.org/beps/bep_0020.html
// scheme consists of -ABCDEF-XX...XX where
// AB -> LI
// C -> Major Hexadecimal
// D -> Minor Hexadecimal
// E/EF -> Patch Hexadecimal (up to two bytes for the patch)
// XX...XX random bytes fill to 20 bytes
export const generatePeerId = () => {
    if (semanticVersion.major > 15 || semanticVersion.minor > 15 || semanticVersion.patch > 255) {
        throw new Error('Invalid version range!');
    }
    let major = semanticVersion.major.toString(16);
    let minor = semanticVersion.minor.toString(16);
    let patch = semanticVersion.patch.toString(16);
    let litePrefix = Buffer.from(`-${liteIdentifier}${major}${minor}${patch}-`, 'utf-8');
    let randomIdentifier = Buffer.from(randombytes(20 - litePrefix.length));
    return Buffer.concat([litePrefix, randomIdentifier]).toString('hex');
};
export const parsePeerId = (id) => {
    // hex string id
    let peerId = Buffer.from(id, 'hex');
    let liteIdentifierBuffer = Buffer.from(liteIdentifier, 'utf-8');
    let isLite =
        liteIdentifierBuffer.compare(Buffer.from(peerId.subarray(1, liteIdentifierBuffer.length + 1), 'utf-8')) === 0;
    let liteVersion, randomIdentifier;
    if (isLite) {
        let dash = Buffer.from('-', 'utf-8');
        let rest = Buffer.from(peerId.subarray(liteIdentifierBuffer.length + 1));
        let dashIndex = rest.indexOf(dash);
        liteVersion = Buffer.from(rest.subarray(0, dashIndex), 'utf-8').toString();
        randomIdentifier = Buffer.from(rest.subarray(dashIndex + 1)).toString('hex');
    }
    return {
        id,
        isLite,
        liteVersion,
        randomIdentifier,
    };
};

export const fileMimeType = (filename) => {
    return mimetype.lookup(filename) ? mimetype.lookup(filename) : 'unknown';
};
export const fileType = (filename) => {
    return fileMimeType(filename).split('/')[0];
};
export const fileSubType = (filename) => {
    return fileMimeType(filename).split('/')[1];
};
export const isAudio = (filename) => {
    var type = fileType(filename);
    return type === 'audio';
};
export const isVideo = (filename) => {
    var type = fileType(filename);
    return type === 'video';
};
export const isMedia = (filename) => {
    return isAudio(filename) || isVideo(filename);
};

export const isCoverImage = (filename) => {
    // Do not use these image subtypes as cover images
    var exclude = ['gif'];
    var type = fileType(filename);
    var subType = fileSubType(filename);

    return type === 'image' && _.indexOf(exclude, subType) === -1;
};
export const getCoverImageIndex = (files) => {
    // Search for cover image in following priority
    // 1. cover.jpg name
    // 2. image type ext
    let coverIndex = null;
    let coverFound = false;
    for (let i = 0; i < files.length; ++i) {
        if (files['name'] && files['name'].includes('cover') && isCoverImage(files['name'])) {
            coverIndex = index;
            break;
        }
        if (!coverFound && isCoverImage(files['name'])) {
            coverIndex = index;
            coverFound = true;
        }
    }
    return coverIndex;
};
