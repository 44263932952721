import { v4 as uuid } from 'uuid';
import { Log } from './types';
import { ConsoleSink, IDBSink } from './sinks';

class Logger {
    static _sid = null;
    static _oid = null;
    constructor() {
        this.sinks = [];
    }
    get sessionId() {
        if (Logger._sid === null) {
            Logger._sid = sessionStorage.getItem('LOGGER_SID');
            if (Logger._sid === null) {
                Logger._sid = uuid();
                sessionStorage.setItem('LOGGER_SID', Logger._sid);
            }
        }
        return Logger._sid;
    }
    get originId() {
        if (Logger._oid === null) {
            Logger._oid = localStorage.getItem('LOGGER_OID');
            if (Logger._oid === null) {
                Logger._oid = uuid();
                localStorage.setItem('LOGGER_OID', Logger._oid);
            }
        }
        return Logger._oid;
    }
    static time() {
        return {
            utc: new Date().toISOString(),
            monotonic: performance.now(),
        };
    }
    addSink(sink) {
        this.sinks.push(sink);
    }
    deleteSink(id) {
        let i = 0;
        for (; i < this.sinks.length; i++) {
            if (this.sinks[i].id === id) {
                break;
            }
        }
        const sink = this.sinks.splice(i)[0];
        sink.delete();
    }
    getIDBSinks() {
        return this.sinks.filter((s) => s instanceof IDBSink);
    }
    error(event, data) {
        this.log(
            new Log({
                event,
                data,
                level: 1,
            })
        );
    }
    critical(event, data) {
        this.log(
            new Log({
                event,
                data,
                level: 0,
            })
        );
    }
    debug(event, data) {
        this.log(
            new Log({
                event,
                data,
                level: 3,
            })
        );
    }
    info(event, data) {
        this.log(
            new Log({
                event,
                data,
                level: 2,
            })
        );
    }
    trace(event, data) {
        this.log(
            new Log({
                event,
                data,
                level: 4,
            })
        );
    }
    log(log) {
        log.time = Logger.time();
        log.sid = this.sessionId;
        log.oid = this.originId;
        for (let s of this.sinks) {
            s.write(log);
        }
    }
}

export const $Logger = new Logger();
