import _ from 'lodash';

const SearchMixin = {
    data() {
        return {
            value: '',
            matched: [],
            historyActive: false,
            selectedIndex: -1,
            focused: false,
        };
    },
    methods: {
        clear(name) {
            this.value = '';
            this.matched = [];
            this.hideHistory();
            // this.searchTracking(name, 'clear');
        },
        handleFocus(name) {
            this.focused = true;
            this.showHistory();
            this.setMatches(this.value, 10);
            // this.searchTracking(name, 'focus');
        },
        handleBlur() {
            this.focused = false;
            this.hideHistory();
        },
        searchTracking(name, action, label) {},
        select(name, item) {
            this.value = item;
            this.hideHistory();
            // this.$Tracking.search(name, 'selectHistoryItem');
        },
        hideHistory() {
            this.selectedIndex = -1;
            this.historyActive = false;
        },
        showHistory() {
            this.historyActive = true;
        },
        nextHistory() {
            if (!this.showHistoryDropdown) {
                return;
            }
            if (this.selectedIndex < this.matched.length - 1) {
                this.selectedIndex++;
            } else {
                this.selectedIndex = 0;
            }
            this.value = this.matched[this.selectedIndex];
        },
        previousHistory() {
            if (!this.showHistoryDropdown) {
                return;
            }
            if (this.selectedIndex > 0) {
                this.selectedIndex--;
            } else {
                this.selectedIndex = this.matched.length - 1;
            }
            this.value = this.matched[this.selectedIndex];
        },
        removeHistoryEntry(name, entry) {
            this.$store.commit('removeSearchHistoryEntry', entry);
            this.setMatches(this.value, 6);
            this.searchTracking(name, 'removeSearchHistoryEntry');
        },
        clearAllSearchHistory(name) {
            this.$store.commit('clearSearchHistory');
            this.searchTracking(name, 'clearSearchHistory');
            this.hideHistory();
        },
        setHistoryIndex(index) {
            this.selectedIndex = index;
        },
        isSelected(index) {
            return index === this.selectedIndex;
        },
        updateValue(value) {
            this.value = value.trim();
            this.updateMatches(value);
            this.$store.commit('showErrorMessage', false); // Hide previous request error, if any
        },
        setMatches(value, max) {
            var result = [];
            if (!value) {
                // Dont modify original array, we use reverse just to show recent history items
                result = _.reverse(_.clone(this.history));
            } else {
                // eslint-disable-next-line max-len
                result = _.filter(this.history, (entry) => entry.toLowerCase().startsWith(value.toLowerCase())).sort();
            }
            this.matched = _.slice(result, 0, max);
        },
        // eslint-disable-next-line func-names
        updateMatches: _.debounce(
            function (value) {
                this.showHistory();
                this.setMatches(value, 8);
            },
            500,
            { leading: true }
        ),
    },
    computed: {
        history() {
            return this.$store.state.search.history;
        },
        showHistoryDropdown() {
            return (
                this.matched.length > 0 &&
                this.historyActive &&
                this.focused &&
                this.value !== '' &&
                !this.showErrorMessage
            );
        },
        showErrorMessage() {
            return this.$store.state.search.showErrorMessage;
        },
        hasUserInput() {
            return this.value.length > 0;
        },
    },
};

export default SearchMixin;
