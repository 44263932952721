<script>
import { nextTick } from 'vue';

function recursivelyRemoveFill(el) {
    if (!el) {
        return;
    }
    el.removeAttribute('fill');
    [].forEach.call(el.children, (child) => {
        recursivelyRemoveFill(child);
    });
}
export default {
    name: 'SvgIcon',
    inject: {
        isHtmlLoaderUsed: { default: false },
        setUniqueIds: { default: false },
    },
    props: {
        icon: {
            type: String,
            default: null,
        },
        hasFill: {
            type: Boolean,
            default: false,
        },
        growByHeight: {
            type: Boolean,
            default: true,
        },
        source: {
            type: String,
            default: null,
        },
    },
    computed: {
        svgPath() {
            return this.isHtmlLoaderUsed
                ? require(`!html-loader!@/assets/svg/${this.icon}.svg`)
                : require(`!svg-inline-loader!@/assets/svg/${this.icon}.svg`);
        },
        svg() {
            return this.source ? this.source : this.svgPath;
        },
    },
    watch: {
        svg() {
            // Wait for DOM to get updated with new SVG before updating
            nextTick(() => {
                this.updateSVGElement();
                this.updateIds();
            });
        },
    },
    mounted() {
        this.updateSVGElement();
        this.updateIds();
    },
    updated() {
        this.updateSVGElement();
    },
    methods: {
        updateSVGElement() {
            if (
                this.svg &&
                this.$refs.svgContainer.firstElementChild &&
                this.$refs.svgContainer.firstElementChild.nodeName === 'svg'
            ) {
                const svgElement = this.$refs.svgContainer.firstElementChild;

                // use `viewBox` attribute to get the svg's inherent width and height
                const viewBox = svgElement
                    .getAttribute('viewBox')
                    .split(' ')
                    .map((n) => Number(n));
                const widthToHeight = (viewBox[2] / viewBox[3]).toFixed(2);
                if (this.hasFill) {
                    // recursively remove all fill attribute of element and its nested children
                    recursivelyRemoveFill(svgElement);
                }
                // set width and height relative to font size
                // if growByHeight is true, height set to 1em else width set to 1em and remaining is calculated based on widthToHeight ratio
                if (this.growByHeight) {
                    svgElement.setAttribute('height', '1em');
                    svgElement.setAttribute('width', `${widthToHeight}em`);
                } else {
                    svgElement.setAttribute('width', '1em');
                    svgElement.setAttribute('height', `${1 / widthToHeight}em`);
                }
                svgElement.classList.add('svg');
            }
        },
        updateIds() {
            // Using inline SVGs can cause a problem with duplicate ids. Since the SVGs are generated as separate
            // files, editors (i.e. exporting via Figma) will reuse the same ids for <defs>. This can cause different
            // SVGs using the same ids to reference the incorrect defs which will mess up the SVG.
            // To fix this, we add a unique identifier to each id and id reference to make them unique.

            // TODO:
            // - Using the Vue internal _uid might not be the best idea so we can update this to be some manually generated uuid
            // - There is a good chance the current implementation will miss some cases. The only case we handle when replacing
            //   references  are urls since that is the case the current utweb SVGs are running into (i.e. fill="url(#paint1_linear)")
            // - Another possible solution is to use some plugin to update the SVGs at build time with webpack. Look into
            //   svgo and svgo-loader. However, this probably will not fix the case if you use the same SVG multiple times, which
            //   can also cause issues.
            if (
                this.setUniqueIds &&
                this.svg &&
                this.$refs.svgContainer.firstElementChild &&
                this.$refs.svgContainer.firstElementChild.nodeName === 'svg'
            ) {
                const svgElement = this.$refs.svgContainer.firstElementChild;
                var idMap = {};
                // Find each node with an id definition, add a unique id to end of the id
                svgElement.querySelectorAll('*[id]').forEach((element) => {
                    if (!idMap[element.id]) {
                        idMap[element.id] = `${element.id}-${this._uid}`;
                        element.id = `${element.id}-${this._uid}`;
                    }
                });
                this.recursivelyUpdateIds(svgElement, idMap);
            }
        },
        // Loop through all element nodes and then update all id references we find that had an id updated with a unique id
        recursivelyUpdateIds(element, idMap) {
            if (!element) {
                return;
            }

            //Recursively call all children elements
            element.children.forEach((child) => {
                this.recursivelyUpdateIds(child, idMap);
            });

            // Loop though attributes on element
            for (var i = 0; i < element.attributes.length; i++) {
                var attr = element.attributes[i];
                const [val, id] = attr.value.match(/^url\(#(.*)\)$/) || [null, null];
                // If found id is mapped to new unique id, update id in attribute
                if (id && idMap[id]) {
                    element.setAttribute(attr.name, val.replace(id, idMap[id]));
                }
            }
        },
    },
};
</script>

<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        ref="svgContainer"
        class="svg-container"
        :style="{
            pointerEvents: 'none',
        }"
        v-html="svg"
    />
</template>

<style lang="scss" scoped>
.svg-container {
    display: inline-flex;
}
.svg {
    vertical-align: middle;
}
</style>
