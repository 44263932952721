<script>
import { isMobile } from '@/utils/agent';
import { SearchTutorial, LifeStyle, SharingLifeStyle, HowItWorks, NewToTorrenting } from '@/components/home';
import { SearchControlNav, DragAndDropField } from '@/components/shared';

export default {
    name: 'Home',
    components: {
        SharingLifeStyle,
        LifeStyle,
        SearchControlNav,
        DragAndDropField,
        HowItWorks,
        SearchTutorial,
        NewToTorrenting,
    },
    data() {
        return {
            isMobile,
            searchTutorialInitState: -1,
            /*
                searchTutorialStates
                -1 -> don't show
                0  -> show all of the tutorial
                1  -> show tutorial in slide form
            */
            showSearchTutorial: false,
            inputmode: undefined,
            resourcePath: 'home.',
        };
    },
    mounted() {
        this.$Logger.debug('lifecycle', { event: 'mounted', component: 'Home' });
        if (this.isMobile && !localStorage.getItem('hasSeenSearchTutorial')) {
            //set inputmode to 'none' to prevent native keyboard from popping up
            //during first SearchControlNav tap
            this.inputmode = 'none';
        }
    },
    methods: {
        showSearchTutorialHandler(state, force = false) {
            if (this.inputmode === 'none' && this.searchTutorialInitState === 1) {
                //set native mobile keyboard to show following second SearchControlNav tap
                this.inputmode = 'text';
                return;
            }

            this.searchTutorialInitState = state;
            this.showSearchTutorial =
                (this.searchTutorialInitState !== -1 && !localStorage.getItem('hasSeenSearchTutorial')) || force;
        },
    },
};
</script>

<template>
    <div class="home">
        <div class="hero">
            <img class="lightning-bolt" src="../assets/home/lightning.svg" alt="lightning bolt" />
            <div class="hero-header">{{ $t(resourcePath + 'header') }}</div>
            <div class="hero-subheader">
                {{ $t(resourcePath + 'subheader-1') }} <br class="break-text" />
                {{ $t(resourcePath + 'subheader-2') }}
            </div>
        </div>
        <div class="search">
            <div class="lights-container">
                <video
                    v-if="!isMobile"
                    id="utlights"
                    src="../assets/home/utlights.mp4"
                    autoplay
                    muted
                    loop
                    class="utlights"
                />
                <img
                    src="../assets/home/utlights.png"
                    class="utlights utlights-img"
                    :class="{ 'lights-img-opaque': isMobile }"
                />
                <div v-if="!isMobile" class="dotted-border" />
            </div>
            <div class="description">{{ $t(resourcePath + 'search-box-description') }}</div>
            <SearchControlNav :inputmode="inputmode" @onInputClick="showSearchTutorialHandler(1)" />
            <SearchTutorial
                v-if="showSearchTutorial"
                :init-state="searchTutorialInitState"
                @close="showSearchTutorialHandler(-1)"
            />
            <div class="separator-container">
                <div class="separator-line" />
                <p class="separator-text">{{ $t('or') }}</p>
                <div class="separator-line" />
            </div>
            <div class="drag-drop-container">
                <DragAndDropField />
            </div>
            <button class="how-to-search-btn" @click="showSearchTutorialHandler(1, true)">
                {{ $t(resourcePath + 'how-to-use-lite') }}
            </button>
        </div>
        <HowItWorks />
        <LifeStyle />
        <NewToTorrenting />
        <SharingLifeStyle />
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.home {
    min-width: 375px;
    color: $neutral-white;
    width: 100%;
    min-height: 100vh;
    background-color: #242433;
    padding: 0 55px;
    margin: 30px 0 0 0;
    line-height: 20px;
    font-weight: 400;

    .lightning-bolt {
        position: absolute;
        left: -105px;
        top: -163px;

        @media (max-width: 818px) {
            left: -33px;
            top: -144px;
        }

        @media (max-width: 604px) {
            left: 1px;
            top: -88px;
            width: 255px;
        }
    }

    .icon-copy {
        padding-top: 3px;
        padding-right: 3px;
        cursor: pointer;
    }

    .hero {
        font-style: normal;
        text-align: center;
        max-width: 1200px;
        position: relative;
        margin: 0 auto;
        &-header {
            font-size: 45px;
            font-weight: 700;
            line-height: 63px;
            margin-bottom: 15px;
        }
        &-subheader {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
        }
        .break-text {
            display: none;
        }
    }

    .search {
        max-width: 1000px;
        border-radius: 26px;
        margin: 86px auto 0;
        text-align: center;
        padding: 108px 56px 167px 56px;
        position: relative;

        .search-control-nav-main {
            max-width: 626px;
            margin: 0 auto;
        }

        * {
            position: relative;
        }

        .lights-container {
            background: $neutral-dark;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 15px;
            border-radius: 26px;

            video,
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 26px;
            }

            .utlights-img {
                opacity: 0.2;
                transition: 2s;
                transition-timing-function: ease-in-out;
            }

            .lights-img-opaque {
                opacity: 0.95;
            }

            .dotted-border {
                width: 100%;
                height: 100%;
                position: relative;
                background: url('../assets/home/dotted-border-green.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }

        .description {
            margin: 0px auto 40px auto;
            font-size: 18px;
            line-height: 24px;
            max-width: 330px;
        }

        .how-to-search-btn {
            background: none;
            color: $neutral-medium;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            font-size: 14px;
            position: absolute;
            margin: 20px 0 0 auto;
            right: 50px;
            bottom: 42px;
            &:hover {
                color: $neutral-white;
            }
            @media screen and (max-width: 605px) {
                margin: 20px auto 0;
                right: 0;
            }
        }
    }

    .separator-container {
        display: flex;
        margin: 40px auto;
        gap: 16px;
        width: 100%;
        max-width: 400px;
        align-items: center;

        .separator-line {
            height: 1px;
            width: 100%;
            max-width: 180px;
            background: #474b4d;
            @media screen and (max-width: 605px) {
                visibility: hidden;
            }
        }
        .separator-text {
            text-align: center;
            text-transform: uppercase;
            color: $neutral-medium;
        }
    }

    .paragraph-header {
        font-weight: 600;
    }

    @media (min-width: 1175px) {
        .introduction {
            width: 100%;
        }
    }

    @media screen and (max-width: 800px) {
        .hero-header,
        .hero-subheader {
            max-width: 512px;
            margin-left: auto;
            margin-right: auto;
        }
        .hero {
            .break-text {
                display: block;
            }
        }
    }
    .read-more-icon {
        padding-top: 1px;
    }
}

@media screen and (max-width: 818px) {
    .home {
        padding: 0 40px;

        .header {
            font-style: normal;
            font-weight: bold;
            text-align: center;
            margin: 0 auto 0 auto;
        }

        .search {
            width: 100%;
            margin: 38px auto 0;
            .description {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

@media screen and (max-width: 605px) {
    .home {
        width: 100%;
        margin: 0;
        padding: 0;
        .utlights,
        .lights-container,
        .search {
            border-radius: 0px !important;
            transition: 0;
        }
        .drag-drop-container {
            padding: 0px 24px;
        }
        .hero {
            &-header {
                font-size: 36px;
                line-height: 37px;
                margin-bottom: 10px;
                max-width: 302px;
                margin: 10px auto;
            }
            &-subheader {
                font-size: 14px;
                line-height: 17px;
            }
        }
        .search {
            padding: 56px 24px 30px 24px;
            .dotted-border {
                display: none;
            }
            .lights-container {
                height: 260px;

                utlights-img {
                    opacity: 1;
                }
            }
            .separator-text {
                margin: 104px auto 21px auto;
            }

            .how-to-search-btn {
                top: 193px;
                bottom: initial;
                left: 0;
            }
        }
    }
}
</style>
