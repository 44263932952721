<script>
import ChevronDownIcon from '@/assets/status-dashboard/chevron-down.svg';
import CheckmarkSuccessIcon from '@/assets/status-dashboard/checkmark-success.svg';
import CheckmarkFailIcon from '@/assets/status-dashboard/checkmark-fail.svg';
import LoadingIcon from '@/assets/status-dashboard/loading.svg';

export const CONNECTION_STATUS = {
    CONNECTING: 'connecting',
    CONNECTED: 'connected',
    ERROR: 'error',
};

export default {
    name: 'StatusCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ChevronDownIcon: ChevronDownIcon,
        };
    },
    computed: {
        hasData() {
            return this.data.length !== 0;
        },
        successDataCount() {
            const successData = this.data.filter(
                (item) => this.getStatusID(item.status) === this.$options.STATUSES['SUCCESS']
            );
            return successData.length;
        },
        completedDataCount() {
            const completedData = this.data.filter(
                (item) => this.getStatusID(item.status) !== this.$options.STATUSES['PENDING']
            );
            return completedData.length;
        },
        cardStatus() {
            const statuses = this.$options.STATUSES;
            if (this.hasData && this.completedDataCount === this.data.length) {
                return this.successDataCount > 0 ? statuses['SUCCESS'] : statuses['ERROR'];
            } else {
                return statuses['PENDING'];
            }
        },
    },
    methods: {
        getStatusID(status) {
            const statuses = this.$options.STATUSES;
            switch (status) {
                case 'error':
                    return statuses['ERROR'];
                case 'connected':
                case 'loaded':
                    return statuses['SUCCESS'];
                case 'connecting':
                case 'loading':
                default:
                    return statuses['PENDING'];
            }
        },
    },
    STATUSES: {
        PENDING: 0,
        SUCCESS: 1,
        ERROR: 2,
    },
    STATUS_ICONS: {
        0: LoadingIcon,
        1: CheckmarkSuccessIcon,
        2: CheckmarkFailIcon,
    },
};
</script>

<template>
    <div class="status-card" :class="{ 'status-card-expanded': expanded, 'status-card-minimized': !expanded }">
        <div class="card-header">
            <div class="card-title">
                {{ title }}
                <!-- <div v-if="hasData">({{ successDataCount }}/{{ data.length }})</div> -->
                <img
                    :src="$options.STATUS_ICONS[cardStatus]"
                    class="card-status-icon"
                    :class="{ loading: cardStatus === $options.STATUSES['PENDING'] }"
                />
            </div>
            <div class="card-expand-chevron">
                <img :src="ChevronDownIcon" />
            </div>
        </div>
        <div class="card-body" @click.stop>
            <div class="card-items">
                <div v-for="item in data" :key="`item-${item.name}`" class="card-item">
                    <div class="card-item-name" :title="item.name">{{ item.name }}</div>
                    <img
                        :src="$options.STATUS_ICONS[getStatusID(item.status)]"
                        class="card-item-status-icon"
                        :class="{ loading: getStatusID(item.status) === $options.STATUSES['PENDING'] }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.status-card {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: #c7cdd2;
    background-color: rgba(28, 28, 28, 0.9);
    border-radius: 14px;
    overflow: hidden;
    transition: flex 0.2s ease-in-out;
    @media (max-width: 607px) {
        flex: none;
    }
    .card-header {
        display: flex;
        align-items: center;
        padding: 4px 10px;
        .card-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            .card-status-icon {
                width: 15px;
                height: 15px;
            }
        }
        .card-expand-chevron {
            margin-left: auto;
            width: 30px;
            height: 30px;
            transform: rotate(0deg);
            transition: transform 0.2s ease-in-out;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .card-body {
        padding: 10px 0px;
        font-size: 12px;
        height: 100%;
        display: flex;
        margin-bottom: 10px;
        margin-right: 5px;
        overflow-y: auto;
        transition: max-height 0.2s ease-in-out;
        @media (max-width: 607px) {
            max-height: 0;
        }
        @media (max-width: 880px) {
            font-size: 13px;
        }
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-track {
            background: $neutral-light-medium;
            border-radius: 20px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $neutral-medium-dark;
            border-radius: 20px;
        }
        .card-items {
            display: flex;
            padding: 0px 10px;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            column-gap: 5%;
            row-gap: 10px;
            width: 100%;
            .card-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                max-width: 28%;
                padding: 5px 10px;
                border-radius: 15px;
                background-color: rgba(25, 25, 46, 0.8);
                .card-item-name {
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .card-item-status-icon {
                    height: 15px;
                    width: 15px;
                }
            }
        }
    }
    &.status-card-expanded {
        flex: auto;
        border-radius: 0;
        .card-expand-chevron {
            transform: rotate(-180deg);
        }
        .card-body {
            max-height: 99em;
            transition: max-height 0.5s ease-in;
        }
    }
    &.status-card-minimized {
        flex: none;
        border-radius: 0;
        .card-body {
            display: none;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        }
    }
    .loading {
        animation: 2s spin infinite linear;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
