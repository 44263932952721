const INITIAL_STATE = {
    currentTorrent: null,
    currentFiles: [],
    subtitles: new Map(),
    currentSubtitle: null,
    fileIndex: null,

    // torrent details
    numPeers: null,
    name: null,
    isAudio: null,

    // player interface
    bufferCounter: 0,
    loading: true,
    loadingMetadata: true,
    duration: null,
    currentTime: 0,
    playing: false,
    fullscreen: false,
    volume: {
        value: 1,
        muted: false,
    },

    coverImage: null,
};

const state = { ...INITIAL_STATE };

const getters = {
    currentTorrent: ({ currentTorrent }) => currentTorrent,
    currentFiles: ({ currentFiles }) => currentFiles,
    currentFile: ({ currentFiles, fileIndex }) => (fileIndex === null ? null : currentFiles[fileIndex]),
    currentSubtitle: ({ currentSubtitle }) => currentSubtitle,
    fileIndex: ({ fileIndex }) => fileIndex,
    bufferCounter: ({ bufferCounter }) => bufferCounter,
    numPeers: ({ numPeers }) => numPeers,
    name: ({ name }) => name,
    isAudio: ({ isAudio }) => isAudio,
    volume: ({ volume }) => volume,
    loading: ({ loading }) => loading,
    loadingMetadata: ({ loadingMetadata }) => loadingMetadata,
    duration: ({ duration }) => duration,
    currentTime: ({ currentTime }) => currentTime,
    playing: ({ playing }) => playing,
    fullscreen: ({ fullscreen }) => fullscreen,
    coverImage: ({ coverImage }) => coverImage,
    subtitles: ({ subtitles }) => subtitles,
};

const mutations = {
    setCurrentTorrent(state, torrent) {
        state.currentTorrent = torrent;
    },
    setCurrentFiles(state, files) {
        state.currentFiles = files;
    },
    setSubtitles(state, subtitles) {
        state.subtitles = subtitles;
    },
    setCurrentSubtitle(state, fileIndex) {
        state.currentSubtitle = fileIndex;
    },
    setFileIndex(state, index) {
        if (state.fileIndex !== null) {
            state.loading = true;
            state.loadingMetadata = true;
            state.duration = null;
            state.bufferCounter = 0;
            state.currentTime = 0;
            state.playing = false;
            state.currentSubtitle = null;
        }
        state.fileIndex = index;
    },
    setIsAudio(state, isAudio) {
        state.isAudio = isAudio;
    },
    setBufferCounter(state, count) {
        state.bufferCounter = count;
    },
    setNumPeers(state, num) {
        state.numPeers = num;
    },
    setName(state, name) {
        state.name = name;
    },
    setVolume(state, volume) {
        state.volume.value = volume;
        volume === 0 ? (state.volume.muted = true) : (state.volume.muted = false);
    },
    setMuted(state, muted) {
        state.volume.muted = muted;
    },
    setCoverImageURL(state, url) {
        state.coverImageURL = url;
    },
    setDuration(state, duration) {
        state.duration = duration;
    },
    setCurrentTime(state, time) {
        state.currentTime = time;
    },
    setPlaying(state, playing) {
        state.playing = playing;
    },
    toggleMuted(state) {
        state.volume.muted = !state.volume.muted;
    },
    setFullscreen(state, fsState) {
        state.fullscreen = fsState;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setLoadingMetadata(state, loading) {
        state.loadingMetadata = loading;
    },
    setCoverImage(state, img) {
        state.coverImage = img;
    },
    resetState(state) {
        state.currentTorrent = null;
        state.currentFiles = [];
        state.fileIndex = null;

        state.numPeers = null;
        state.name = null;

        state.loading = true;
        state.loadingMetadata = true;
        state.duration = null;
        state.bufferCounter = 0;
        state.currentTime = 0;
        state.playing = false;
        state.fullscreen = false;
        state.volume.value = 1;
        state.volume.muted = false;
        state.coverImage = null;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
