<template>
    <div>
        <div
            v-if="feedBackState === 0"
            class="feedback"
            :data-gtm-event="'engagement_session_feedback_form_open'"
            @click="feedBackState = 1"
        >
            <img alt="feedback-star" src="@/assets/star.svg" />
            <span v-if="!isMobile">{{ $t(RP + 'feedback') }}</span>
        </div>

        <transition>
            <div v-if="feedBackState === 2" class="feed-back-thank-you">
                <img
                    alt="feedback-thank-you-star"
                    class="feedback-thank-you-star"
                    src="@/assets/feedback/check-circle-outline.svg"
                />

                <div>{{ $t(RP + 'thank-you') }}</div>
            </div>
        </transition>

        <div v-if="feedBackState === 1" id="feedback-overlay">
            <div class="feed-back-popup">
                <div class="feed-back-inner-popup">
                    <img
                        class="feed-back-close"
                        :data-gtm-event="'engagement_session_feedback_form_close'"
                        alt="close"
                        src="@/assets/close.png"
                        @click="feedBackState = 0"
                    />
                    <div class="feed-back-image-container">
                        <img class="feed-back-image" alt="feedback" src="@/assets/feedback/feedback-header.png" />
                    </div>
                    <div class="feed-back-popup-body">
                        <h3 class="feed-back-header">{{ $t(RP + 'experience-matters') }}</h3>

                        <p>{{ $t(RP + 'streaming-experience') }}</p>
                        <div class="feed-back-star-rating-container">
                            <StarSelector v-model="streamingStarRating" />
                        </div>
                        <p>{{ $t(RP + 'file-quality') }}</p>
                        <div class="feed-back-star-rating-container">
                            <StarSelector v-model="mediaStartRating" />
                        </div>
                        <p>{{ $t(RP + 'recommend') }}</p>

                        <div class="feed-back-recommend">
                            <div class="feed-back-recommend-selection">
                                <svg
                                    class="feed-back-recommend-svg"
                                    :class="{ 'feed-back-recommend-svg-selected': recommend === 1 }"
                                    @click="recommend = 1"
                                >
                                    <use xlink:href="@/assets/feedback/frown.svg#frown-svg"></use>
                                </svg>
                                <div>{{ $t(RP + 'no') }}</div>
                            </div>
                            <div class="feed-back-recommend-selection">
                                <svg
                                    class="feed-back-recommend-svg"
                                    :class="{ 'feed-back-recommend-svg-selected': recommend === 2 }"
                                    @click="recommend = 2"
                                >
                                    <use xlink:href="@/assets/feedback/neutral.svg#neutral-svg"></use>
                                </svg>
                                <div>{{ $t(RP + 'maybe') }}</div>
                            </div>
                            <div class="feed-back-recommend-selection">
                                <svg
                                    class="feed-back-recommend-svg"
                                    :class="{ 'feed-back-recommend-svg-selected': recommend === 3 }"
                                    @click="recommend = 3"
                                >
                                    <use xlink:href="@/assets/feedback/smiley.svg#smiley-svg"></use>
                                </svg>
                                <div>{{ $t(RP + 'yes') }}</div>
                            </div>
                        </div>

                        <textarea v-model="comments" class="feed-back-text" :placeholder="$t(RP + 'text-area')" />

                        <BtButton class="feed-back-submit" :class="{ disabled: !submitActive }" @click="submitFeedback"
                            >{{ $t(RP + 'submit') }}
                        </BtButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GoogleAnalytics } from '@/services';
import { isMobile } from '@/utils/agent';
import StarSelector from './StarSelector';

export default {
    name: 'FeedbackForm',
    components: {
        StarSelector,
    },
    data() {
        return {
            RP: 'feedback-form.',
            feedBackState: 0,
            streamingStarRating: 0,
            mediaStartRating: 0,
            recommend: 0,
            comments: '',
            feedbackTimeout: undefined,
            isMobile,
        };
    },
    computed: {
        submitActive() {
            return (
                this.streamingStarRating !== 0 ||
                this.mediaStartRating !== 0 ||
                this.recommend !== 0 ||
                this.comments !== ''
            );
        },
    },

    methods: {
        refresh() {
            this.feedBackState = 0;
            this.streamingStarRating = 0;
            this.mediaStartRating = 0;
            this.recommend = 0;
            this.comments = '';
            clearTimeout(this.feedbackTimeout);
        },
        submitFeedback() {
            GoogleAnalytics.sendEvent('feedback_submission', {
                feedback_streaming_star_rating: this.streamingStarRating,
                feedback_media_star_rating: this.mediaStartRating,
                feedback_recommend: this.recommend,
                feedback_comment: this.comments,
                feedback_compiled: `${this.streamingStarRating}, ${this.mediaStartRating}, ${this.recommend}, ${this.comments}`,
            });
            this.feedBackState = 2;
            this.feedbackTimeout = setTimeout(() => {
                this.feedBackState = 3;
            }, 5000);
        },
    },
};
</script>

<style lang="scss" scoped>
/*Transition animations*/
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.feedback {
    background-color: #01967a;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    position: fixed;
    top: 80vh;
    right: 0px;
    transform-origin: 100% 100%;
    transform: rotate(-90deg);
    cursor: pointer;
    z-index: 500;
    border-radius: 2px;

    span {
        margin: 0 0 0 8px;
    }
}

.feed-back-thank-you {
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: inline-flex;
    border-radius: 4px;
    align-items: center;

    padding: 16px;
    width: 336px;
    background-color: #f4fcee;
    color: #303233;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;

    border: 1px solid #52a911;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}

@media (max-width: 400px) {
    .feed-back-thank-you {
        right: 20px;
    }
}

.feedback-thank-you-star {
    margin-right: 10px;
}

#feedback-overlay {
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;

    .feed-back-close {
        cursor: pointer;
        float: right;
    }

    .feed-back-image-container {
        text-align: center;
    }

    .feed-back-image {
        height: 50%;
        width: 50%;
    }

    .feed-back-popup {
        background-color: #fff;
        color: #000;
        max-width: 516px;
        min-width: 322px;
        overflow: auto;

        position: absolute;
        text-align: start;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);

        padding: 20px;
        border-radius: 2px;
    }

    @media (max-height: 795px) {
        .feed-back-popup {
            height: 100%;
        }
    }

    .feed-back-inner-popup {
        overflow-y: auto;
        height: 100%;
    }

    .feed-back-popup-body {
        padding: 20px;
    }

    .feed-back-header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 24px;
    }

    .feed-back-star-rating-container {
        margin-bottom: 40px;
    }

    .feed-back-recommend {
        display: inline-flex;
        margin-bottom: 20px;
    }

    .feed-back-recommend-selection {
        margin-right: 30px;
        text-align: center;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }

    .feed-back-recommend-svg {
        cursor: pointer;
        width: 32px;
        height: 32px;
        fill: rgba(0, 0, 0, 0);
    }

    .feed-back-recommend-svg:hover {
        fill: #f3d06f;
    }

    .feed-back-recommend-svg-selected {
        fill: #ffd300 !important;
    }

    .feed-back-text {
        min-height: 132px;
        width: 100%;
        resize: none;
        padding: 12px;

        box-sizing: border-box;
        border: 0.5px solid #474b4d;
        border-radius: 2px;
        margin-bottom: 32px;
    }

    .feed-back-submit {
        float: right;
        margin-bottom: 20px;
    }
}
</style>
